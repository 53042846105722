import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useCreateExtreamUserMutation } from "features/userPackets/userPacketsApiSlice";

const CreateExtreamForm = ({
  onClose,
  formData,
  currentUser,
  refetchList,
  updateErrorSate,
}) => {
  const { showNotification } = useServerResponseNotifier();
  const [createExtreamUser] = useCreateExtreamUserMutation();
  const [errors, setErrors] = useState({});

  const [extreamData, setExtreamData] = useState({
    username: "",
    password: "1234",
    description: "",
    macAddress: "",
  });

  const [dataForUpdate, setDataUpdate] = useState(null);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "macAddress") {
      let newValue = value;
      newValue = newValue.toUpperCase();
      newValue = newValue.replace(/:/g, "");
      newValue = newValue.slice(0, 12);
      newValue = newValue.match(/.{1,2}/g)?.join(":") || "";
      setExtreamData((prevState) => {
        if (prevState[name] !== newValue) {
          return {
            ...prevState,
            [name]: newValue,
          };
        }
        return prevState;
      });

      setDataUpdate((prevState) => {
        if (prevState[name] !== newValue) {
          return {
            ...prevState,
            [name]: newValue,
          };
        }
        return prevState;
      });

      return;
    }

    setExtreamData({
      ...extreamData,
      [name]: value,
    });

    setDataUpdate({
      ...dataForUpdate,
      [name]: value,
    });
  };

  useEffect(() => {
    setDataUpdate({
      ...formData,
      ...extreamData,
      ["username"]: generateData(),
    });
  }, [formData, currentUser]);


  
  const generateData = () => {
    if (!currentUser) return;
    let generatedUsername = currentUser.name.replaceAll(" ", "");

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);

    generatedUsername = generatedUsername.concat(weekNumber,currentDate.getHours());
    return generatedUsername;
  };



  const validateFields = () => {
    const newErrors = {};
    if (!validateParentFields()) newErrors.parent = "From ParentComp";
    if (!dataForUpdate.username) newErrors.username = "Username is required";
    if (!dataForUpdate.macAddress) {
      newErrors.macAddress = "MAC Address is required";
    } else {
      const macAddressRegex = /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/;
      if (!macAddressRegex.test(dataForUpdate.macAddress)) {
        newErrors.macAddress = "Invalid MAC Address format. Use format XX:XX:XX:XX:XX:XX";
      }
    }
    return newErrors;
  };

  const validateParentFields = () => {
    const newErrors = {};
    if (!dataForUpdate.serviceId) newErrors.serviceId = "Service is required";
    if (!dataForUpdate.packetId) newErrors.packetId = "Packet is required";
    if (!dataForUpdate.userId) newErrors.userId = "User is required";

    if (Object.keys(newErrors).length > 0) {
      updateErrorSate(newErrors);
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    await createExtreamUser(dataForUpdate)
      .unwrap()
      .then(showNotification("Service for user created succesfully!", 200))
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetchList();
  };

  return (
    <Box paddingTop="20px">
      <TextField
        fullWidth
        defaultValue={generateData()}
        label="Username"
        margin="normal"
        name="username"
        onChange={handleChange}
        error={!!errors.username}
        helperText={errors.username}
      />

      <TextField
        fullWidth
        defaultValue="1234" // krijo logjiken qe ta marre nga default config passwordin
        label="Password"
        margin="normal"
        name="password"
        variant="outlined"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="MAC"
        margin="normal"
        placeholder="CC:46:D6:12:00:69"
        name="macAddress"
        value={extreamData.macAddress}
        onChange={handleChange}
        error={!!errors.macAddress}
        helperText={errors.macAddress}
      />
      <TextField
        fullWidth
        label="Description"
        margin="normal"
        name="Description"
        onChange={handleChange}
      />

      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default CreateExtreamForm;
