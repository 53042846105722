import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { useCreateUserMutation } from "state/api";

const OverlayForm = ({ onClose, formComponent, onSubmit,formTitle,maxWidth }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.alt,
          padding: "2em",
          borderRadius: "8px",
          width: "80%",
          maxWidth: maxWidth?maxWidth:"550px",
        }}
      >
{formTitle && 
(
   <Typography variant="h4" color="secondary" gutterBottom sx={{display:"flex" ,justifyContent:"center"}}>
   {formTitle}
 </Typography>
 )
}
      {formComponent}
      </Box>
    </Box>
  );
};

export default OverlayForm;
