import React from 'react'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton,Box } from '@mui/material';


const OperatorActions = ({editClick,deleteClick}) => {
        return (
          <Box>
            <IconButton
              onClick={editClick}
              sx={{ color: "#EAD623" }}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>

            <IconButton
              onClick={deleteClick}
              sx={{ color: "#ff0000" }}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
}

export default OperatorActions