import React from 'react'
import { Box,Button,TextField,Typography,InputAdornment,IconButton } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import Header from 'components/Header';
import FlexBetween from 'components/FlexBetween';

const AddNewHeader = ({openForm,title,subtitle}) => {
  return (
    <FlexBetween sx={{pb:"15px"}}> 
    <Header
      title={title}
      subtitle={subtitle}
    />

    {/* <TextField
      variant="outlined"
      size="small"
      placeholder="Search"
      sx={{ mb: "5px", width: "60%" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    /> */}
    <Button
      variant="contained"
      color="primary"
      sx={{ mb: "5px" }}
      startIcon={<Typography variant="h6">+</Typography>}
      onClick={openForm}
    >
      Add New
    </Button>
  </FlexBetween>
  )
}

export default AddNewHeader