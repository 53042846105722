import React, { useEffect } from "react";
import "./print.css"; 
import profileImage from "assets/init100.png";

const PurchaseSaleInvoicePrint = React.forwardRef((props, ref) => {
  const { invoiceData } = props;

  const formatDate = (dates) => {
    let date=new Date(dates);
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    return dd + "/" + mm + "/" + yyyy;
  };
  return (
    <div ref={ref} className="invoice-container">
      {/* Invoice Title */}
      <div className="invoice-title">
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
         {invoiceData.invoiceType === "Purchase" ? "FATURE BLERJE" : "FATURE SHITJE"}
        </h2>
      </div>

      {/* Invoice Header */}
      <div className="invoice-header">
        <div className="invoice-top">
          <img src={profileImage} alt="Logo" />
        </div>
        <div className="invoice-left">
          <p>
            <strong>{invoiceData.invoiceType === "Sale" ? "Klienti:" : "Furnitori:"}</strong>
            <span>{invoiceData.invoiceType === "Sale" ? invoiceData.customerName : invoiceData.supplier}</span>
         </p>
          <p>
            <strong>Kodi Fatures:</strong>{" "}
            <span>{invoiceData.invoiceCode || "___________"}</span>
          </p>
          <p>
            <strong>Pershkrimi:</strong>{" "}
            <span>{invoiceData.description || "___________"}</span>
          </p>
        </div>
        <div className="invoice-right">
          <p>
            <strong>Data Fatures:</strong>{" "}
            <span>{formatDate(invoiceData.registrationDate)}</span>
          </p>
          <p>
            <strong>Data Pageses:</strong>{" "}
            <span>{formatDate(invoiceData.dateCreated)}</span>
          </p>
        </div>
      </div>

      {/* Invoice Table */}
      <table className="invoice-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Artikulli</th>
            <th>Sasia</th>
            <th>{invoiceData.invoiceType === "Sale" ? "Cmimi Shitjes" : "Cmimi Blerjes"}</th>            <th>Skonto (%)</th>
            <th>Cmimi Final</th>
          </tr>
        </thead>
        <tbody>
          {invoiceData.items &&
            invoiceData.items.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{invoiceData.invoiceType === "Sale" ? item.sellPrice?.toFixed(2): item.buyPrice?.toFixed(2)} </td>
                <td>{item.discount}</td>
                <td>{item.finalPrice?.toFixed(2)}</td>
              </tr>
            ))}
          <tr>
            <td colSpan="5" style={{ textAlign: "right", fontWeight: "bold" }}>
              Totali:
            </td>
            <td style={{ fontWeight: "bold" }}>
              {invoiceData.items
                ?.reduce((acc, item) => acc + item.finalPrice, 0)
                ?.toFixed(2)}
            </td>
          </tr>
        </tbody>
      </table>

      {/* Invoice Footer */}
      <div className="invoice-footer">
        <p>
          <strong>Procesuar nga:</strong>{" "}
          {invoiceData.operatorName || "________"}
        </p>
        <p>
          <strong>Shenime:</strong> {invoiceData.note || "___________"}
        </p>
      </div>

      <div className="svn-footer">
        <p>
          <strong>SOLVENDA | solvenda.org</strong>
        </p>
      </div>
    </div>
  );
});

export default PurchaseSaleInvoicePrint;
