import { Typography, Box, useTheme } from "@mui/material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import FlexBetween from "./FlexBetween";
import { useState } from "react";
import OverlayForm from "./OrverlayForm";
import UsersForm from "./forms/UserForm";

const Header = ({ title, subtitle, refetch }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box sx={{ pb: "10px",display: "flex", justifyContent: "center" }} >
        <Typography
          variant="h2"
          color={theme.palette.secondary[100]}
          fontWeight="bold"
          sx={{ mb: "5px" }}
        >
          {title}
        </Typography>
      </Box>
      <Typography variant="h5" color={theme.palette.secondary[300]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
