import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { useUpdateUPRadiusMutation } from "features/userPackets/userPacketsApiSlice";

const EditRadiusForm = ({
  onClose,
  formData,
  refetchList,
  radiusData,
  userPacketId,
  refetchDetails,
}) => {
  const [updateRadiusUser] = useUpdateUPRadiusMutation();
  const { showNotification } = useServerResponseNotifier();
  const [errors, setErrors] = useState({});

  const [radius, setRadius] = useState({
    username: radiusData?.username || "",
    ipAddress: radiusData?.ipAddress || "",
    password: radiusData?.password || "",
    description:formData?.description || "",
    });


    useEffect(() => {
      setRadius({
        username: radiusData?.username || "",
        ipAddress: radiusData?.ipAddress || "",
        password: radiusData?.password || "",
        description:formData?.description || "",
      });
    }, [radiusData]);


    const validateFields = () => {
      const newErrors = {};
      if (!radius.username) newErrors.username = "Username is required";
      if (!radius.password) newErrors.password = "Password is required";
  
      return newErrors;
    };


    const handleChange = (e) => {
      const { name, value } = e.target;
      setRadius((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

  const handleSubmit = async (e) => {
    e.preventDefault();
   const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const updatedData={
      ...formData,
      ...radius,
    };

    await updateRadiusUser({
      id:userPacketId,
      request:updatedData})
      .unwrap()
      .then(() => {
        showNotification("Service for user updated successfully!", 200);
        refetchDetails(); 
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetchList();
  };

  return (
    <Box paddingTop="20px">
      <TextField
        value={radius.username}
        fullWidth
        label="Username"
        margin="normal"
        name="username"
        onChange={handleChange}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        error={!!errors.username}
        helperText={errors.username} 
      />
      <TextField
        fullWidth
        defaultValue={radius.password||"1234"} // krijo logjiken qe ta marre nga default config passwordin
        label="Password"
        margin="normal"
        name="password"
        variant="outlined"
        onChange={handleChange}
        error={!!errors.password}
        helperText={errors.password} 
      />
      <TextField
        fullWidth
        value={radius.ipAddress}
        label="IP"
        margin="normal"
        name="ipAddress"
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />

      <TextField
        fullWidth
        value={radius.description}
        label="Description"
        margin="normal"
        name="description"
        onChange={handleChange}
        variant="outlined"
        InputLabelProps={{ shrink: true }}

      />

      <CloseAddFooter
        footerLabel={"Edit"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default EditRadiusForm;
