import React, { useState } from "react";
import {
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardContent,
  Typography,
  Button,
  Box,
  TextField,
  Grid,
} from "@mui/material";
import { useGetLastWithdrawalsQuery, useGetOperatorsQuery } from "features/withdrawals/withdrawalsApiSlice";
import OperatorCard from "components/withdrawals/OperatorCard";
import FlexBetween from "components/FlexBetween";
import Autocomplete from "@mui/material/Autocomplete";
import OverlayForm from "components/OrverlayForm";
import ConfirmationWithdrawal from "components/withdrawals/ConfirmationWithdrawal";
import { DataGrid } from "@mui/x-data-grid";

const Withdrawals = () => {
  const [balance, setBalance] = useState(0);
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const withdrawOptions = [5000, 10000, 20000, 50000];
  const [selectedOperator, setSelectedOperator] = useState("");
  const [filter, setFilter] = useState("");



  const [showForm, setShowForm] = useState(false);
  const handleOpenForm = () =>setShowForm(!showForm);

  const { data: operators, isLoading: isOperatorLoading ,refetch:refetchList } =
    useGetOperatorsQuery();

    const { data: lastWithdrawals, refetch:refetchWithdrawals} =
    useGetLastWithdrawalsQuery();

    const refetchData=async ()=>{
      refetchList();
      refetchWithdrawals();
    }

  const handleWithdrawAmountChange = (event) => {
    const value = event.target.value;
    // Allow only numeric values and set to balance if value exceeds balance
    if (!isNaN(value) && !value.includes(".")) {
      if (parseInt(value) <= balance) {
        setWithdrawAmount(value);
      } else if (value === "") {
        setWithdrawAmount("");
      } else {
        // If value is greater than balance, set withdrawAmount to balance
        setWithdrawAmount(balance);
      }
    }
  };

  const filteredOperators = operators?.filter((operator) =>
    operator.name.toLowerCase().includes(filter.toLowerCase())
  );

  const handleWithdraw = (amount, isAll = false) => {
    if (isAll) {
      setWithdrawAmount(balance);
    } else {
      // Compute the new value, ensuring it does not exceed the balance
      const newVal = Math.min(balance, parseInt(withdrawAmount || 0) + amount);
      setWithdrawAmount(newVal.toString());
    }
  };

  const hasError = () => parseInt(withdrawAmount) > balance;

  const selectOperator = (operator) => {
    setSelectedOperator(operator);
    setBalance(operator.balance);
    setWithdrawAmount("");
  };

  const colorPalette = [
    "#2E8B57", // SeaGreen
    "#3CB371", // MediumSeaGreen
    "#66CDAA", // MediumAquaMarine
    "#8FBC8F", // DarkSeaGreen
    "#20B2AA", // LightSeaGreen
  ];

  
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.1 },
    { field: 'operatorName', headerName: 'Operator', flex: 0.5},
    { field: 'withdrawalAmount', headerName: 'Amount', type: 'number', flex: 0.5 },
    { field: 'dateCreated', headerName: 'Date', flex:0.5 },
    { field: 'newBalance', headerName: 'New Balance', type: 'number', flex: 0.5 },
  ];



const clearOperator=()=>{
  if(selectOperator)
  {
    setSelectedOperator(null);
  }
}

  return (
    <Grid container sx={{ height: "100vh", pt: "50px" }}>
      {/* Left side (30%) */}
      <Grid item xs={3} sx={{ borderRight: 1, height: "70%", pr: "20px" }}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", mb: 2, fontWeight: "bold" }}
        >
          OPERATORS
        </Typography>

        <Autocomplete
          options={operators?.map((operator) => operator.name) || []}
          onInputChange={(event, newInputValue) => {
            setFilter(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Operator"
              variant="outlined"
              sx={{ width: "80%", mb: 2, ml: 3 }}
            />
          )}
          freeSolo
        />

        <Box sx={{ overflowY: "auto", pr: 1, height: "calc(100% - 100px)" }}>
          {" "}
          {/* Adjust the height based on the height of the "Operators" heading and Autocomplete */}
          {filteredOperators &&
            filteredOperators.map((operator) => (
              <OperatorCard
                key={operator.id}
                data={operator}
                onCardClick={() => selectOperator(operator)}
                isSelected={
                  selectedOperator && operator.id === selectedOperator.id
                }
              />
            ))}
        </Box>
      </Grid>

      {selectedOperator && (
        <Grid item xs={12} sm={10} md={9} lg={7} sx={{ pl: "40px" }}>
          <Card sx={{ minWidth: 450 }}>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginBottom: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4">OPERATOR:</Typography>
                  <Typography variant="h4" sx={{ pl: "50px" }}>
                    {selectedOperator.name}
                  </Typography>
                </Box>

                <FlexBetween>
                  <Typography
                    sx={{ fontSize: 18 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Balance:
                  </Typography>
                  <Typography variant="h5" sx={{ pl: "50px" }}>
                    {balance} ALL
                  </Typography>
                </FlexBetween>

                <FlexBetween sx={{ pt: "50px" }}>
                  <Typography sx={{ fontSize: 18 }} color="text.secondary">
                    Withdrawal Amount:
                  </Typography>
                  <TextField
                    variant="outlined"
                    value={withdrawAmount}
                    onChange={handleWithdrawAmountChange}
                    type="number"
                    error={hasError()}
                    placeholder="500 ALL"
                    helperText={
                      hasError() ? "The amount exceeds your balance." : ""
                    }
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    sx={{
                      marginTop: 2,
                      marginLeft: 2,
                      marginBottom: 2,
                    }}
                  />
                </FlexBetween>
              </Box>

              {/* CHOOSE SUM Buttons */}
              <Typography
                sx={{ fontSize: 16, marginBottom: 2 }}
                color="text.secondary"
              >
                CHOOSE SUM
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 2,
                  flexWrap: "wrap",
                  marginBottom: 2,
                }}
              >
                {withdrawOptions.map((amount, index) => (
                  <Button
                    key={amount}
                    variant="outlined"
                    sx={{
                      color: "#fff",
                      borderColor: colorPalette[index % colorPalette.length], // Cycle through colors
                      bgcolor: colorPalette[index % colorPalette.length],
                      "&:hover": {
                        bgcolor:
                          colorPalette[(index + 1) % colorPalette.length], // Darken on hover
                        borderColor:
                          colorPalette[(index + 1) % colorPalette.length],
                        opacity: 0.9,
                      },
                      padding: "10px 20px",
                      fontWeight: "500",
                    }}
                    onClick={() => handleWithdraw(amount)}
                  >
                    {amount} ALL
                  </Button>
                ))}
              </Box>

              {/* SET MAX Button */}
              <Box sx={{ textAlign: "center", marginBottom: 2 }}>
                <Button
                  variant="outlined"
                  sx={{ color: "#fff", borderColor: "#fff" }}
                  onClick={() => handleWithdraw(balance, true)}
                >
                  SET MAX
                </Button>
              </Box>

              {/* PROCEEDE WITHDRAWAL Button */}
              <Box
                sx={{
                  display: hasError() || withdrawAmount == 0 ? "none" : "flex",
                  justifyContent: "center",
                  marginTop: 2,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    padding: "10px 20px", // Larger padding for a bigger button
                    fontSize: "1.1rem", // Larger font size for the button text
                    color: "black",
                    backgroundColor: "green",
                    fontWeight: "600",
                  }}
                  onClick={handleOpenForm}
                >
                  PROCEED WITHDRAWAL
                </Button>
              </Box>
            </CardContent>
          </Card>


            <Grid item sx={{ height: 400, width: '100%', mt: 5 }}>
            {lastWithdrawals &&( <DataGrid
                rows={lastWithdrawals}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                pagination
              />) }
             
            </Grid>
          </Grid>
      )}

{showForm && (
        <OverlayForm
          formTitle={"WITHDRAWAL CONFIRMATION"}
          formComponent={
            <ConfirmationWithdrawal
              onClose={handleOpenForm}
              operator={selectedOperator}
              withdrawalAmount={withdrawAmount}
              refetchList={refetchData}
              clearSeletected={clearOperator}
            />
          }
        />
      )}
    </Grid>
  );
};
export default Withdrawals;
