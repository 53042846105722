import React, { useEffect, useState } from "react";
import CloseAddFooter from "../CloseAddFooter";
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from "features/customers/customersApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { TextField, Box } from "@mui/material";
import AddNewHeader from "../headers/AddNewHeader";

const EditCustomerForm = ({ onClose, refetch, customerId }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    phoneNumber: "",
  });

  const {
    data: customerDetails,
    isLoading: isCustLoading,
    refetch: refetchCurrentCustomer,
  } = useGetCustomerQuery(customerId);

  const [errors, setErrors] = useState({});
  const [currentCustomer, setCurrentCustomer] = useState("");
  const [updateCustomer] = useUpdateCustomerMutation();
  const { showNotification } = useServerResponseNotifier();

  useEffect(() => {
    if (!isCustLoading) {
      setCurrentCustomer(customerDetails);
      setFormData({
        ...formData,
        firstName: customerDetails.firstName || "",
        lastName: customerDetails.lastName || "",
        email: customerDetails.email || "",
        address: customerDetails.address || "",
        city: customerDetails.city || "",
        phoneNumber: customerDetails.phoneNumber || "",
      });
    }
  }, [customerDetails, isCustLoading]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.address) newErrors.address = "Address is required";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      await updateCustomer({
        id: currentCustomer.id,
        request: formData,
      }).unwrap();
      showNotification("Customer updated successfully!", 200);
      await onClose();
      await refetch();
    } catch (error) {
      showNotification(error.data.Message, error.data.StatusCode);
    }
  };

  useEffect(() => {
    refetchCurrentCustomer();
  }, [customerId]);

  return (
    <Box>
      <TextField
        fullWidth
        label="First Name"
        margin="normal"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        error={!!errors.firstName}
        helperText={errors.firstName}
      />
      <TextField
        fullWidth
        label="Last Name"
        margin="normal"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        error={!!errors.lastName}
        helperText={errors.lastName}
      />
      <TextField
        fullWidth
        label="Address"
        margin="normal"
        name="address"
        value={formData.address}
        onChange={handleChange}
        error={!!errors.address}
        helperText={errors.address}
      />
      <TextField
        fullWidth
        label="phoneNumber"
        margin="normal"
        name="Phone"
        value={formData.phoneNumber}
        onChange={handleChange}
      />

      <TextField
        fullWidth
        label="Email"
        margin="normal"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />

      <TextField
        fullWidth
        label="City"
        margin="normal"
        name="city"
        value={formData.city}
        onChange={handleChange}
      />
      <CloseAddFooter
        footerLabel={"Edit"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default EditCustomerForm;
