import React, { useEffect, useState } from "react";
import CloseAddFooter from "../CloseAddFooter";
import { useGetPurchasesPaginationQuery } from "features/invoices/invoicesApiSlice";
import PaginationTable from "components/PaginationTable";
import { Box,IconButton, TextField, Typography, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const SearchPurchaceInvoice = ({ onClose, refetch, handleSubmit,setSelectedPurchase }) => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const theme = useTheme();
 // const [selectedPurchase,setSelectedPurchase]=useState(null);

  const {
    data,
    isLoading,
    refetch: refetchList,
  } = useGetPurchasesPaginationQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    refetchList({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize]);

  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  const handleFilter = async (e) => {
    const { name, value } = e.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        let newFilters = [];
        newFilters.push({
          columnName: "InvoiceCode",
          value: `${searchTerm}`,
          operation: 1,
        });
        setFilters(newFilters);
      } else {
        setFilters([]);
      }
    }, 700);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
    },
    {
      field: "invoiceCode",
      headerName: "Invoice Code",
      flex: 0.3,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      flex: 0.5,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      flex: 0.5,
    },
    {
      field: "totalQuantity",
      headerName: "Total Quantity",
      flex: 0.3,
    },
    {
      field: "registrationDate",
      headerName: "Invoice Date",
      flex: 0.5,
    },
    {
        field: "actions",
        headerName: "",
        sortable: false,
        width: 50,
        align: "right",
        headerAlign: "left",
        renderCell: (params) => {
          const editClick = () => {
            setSelectedPurchase(params.row.id);
            onClose();
          };
          return (
            <IconButton
            onClick={editClick}
            sx={{ color: "#EAD623" }}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
          );
        },
      },
  ];

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
      <TextField
        onChange={handleFilter}
        label="Search Invoice Code"
        variant="outlined"
        size="small"
        name="name"
      />
      </Box>
      

      <PaginationTable
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
        fixedHeight={"615px"}
      />
      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default SearchPurchaceInvoice;
