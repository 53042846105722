import React, { useEffect } from "react";
import { useGetPacketsPaginationQuery } from "features/packets/packetsApiSlice";
import Header from "components/Header";
import { Box, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableComp from "components/TableComp";
import { useState } from "react";
import OverlayForm from "components/OrverlayForm";
import PacketForm from "components/forms/PacketForm";
import PacketActions from "components/forms/actions/PacketActions";
import EditPacketForm from "components/forms/editForms/EditPacketForm";
import PaginationTable from "components/PaginationTable";
import { TextFields } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import AddNewHeader from "components/forms/headers/AddNewHeader";
import DeletePacketForm from "components/forms/deleteForms/DeletePacketForm";

const Packet = () => {
  const [currentPacket, setCurrentPacket] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [deleteForm, setDeleteForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const toggleDeleteForm = () => setDeleteForm(!deleteForm);
  const openEditForm = () => setEditForm(true);
  const closeEditForm = () => setEditForm(false);
  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  const {
    data,
    isLoading,
    refetch: refetchList,
  } = useGetPacketsPaginationQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    refetchList({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize]);

  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  const handleFilter = async (e) => {
    const { name, value } = e.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        let newFilters = [];
        newFilters.push({
          columnName: "Name",
          value: `${searchTerm}`,
          operation: 0,
        });
        setFilters(newFilters);
      } else {
        setFilters([]);
      }
    }, 700);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.5,
    },
    {
      field: "price",
      headerName: "PRICE",
      flex: 0.2,
    },
    {
      field: "numberOfUsers",
      headerName: "Number Of Users",
      flex: 0.2,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const editClick = () => {
          setCurrentPacket(params);
          openEditForm();
        };

        const deleteClick = () => {
          toggleDeleteForm();
          setCurrentPacket(params);
        };

        return (
          <PacketActions editClick={editClick} deleteClick={deleteClick} />
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <AddNewHeader openForm={handleOpenForm} title={"Packets"} />

      <TextField
        sx={{
          height: "32px",
          margin: "0",
          ".MuiOutlinedInput-input": { padding: "8px" },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleFilter}
        label="Search Packet"
        margin="normal"
        name="name"
      />

      <PaginationTable
        handleOpenForm={handleOpenForm}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
        fixedMinWith={"700px"}
      />

      {showForm && (
        <OverlayForm
          onClose={handleCloseForm}
          formComponent={
            <PacketForm onClose={handleCloseForm} refetch={refetchList} />
          }
        />
      )}

      {editForm && (
        <OverlayForm
          formComponent={
            <EditPacketForm
              packetId={currentPacket.id}
              onClose={closeEditForm}
              refetch={refetchList}
            />
          }
          formTitle={"Edit packet"}
        />
      )}

      {deleteForm && (
        <OverlayForm
          formComponent={
            <DeletePacketForm
              packet={currentPacket.row}
              onClose={toggleDeleteForm}
              refetch={refetchList}
            />
          }
          formTitle={"DELETE PACKET"}
        />
      )}
    </Box>
  );
};

export default Packet;
