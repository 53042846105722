import React, { useEffect, useState } from "react";
import { Box,IconButton } from "@mui/material";
import Header from "components/Header";
import OverlayForm from "components/OrverlayForm";
import TableComp from "components/TableComp";
import RegionForm from "components/forms/RegionForm";
import EditRegionForm from "components/forms/editForms/EditRegionForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OfferForm from "components/forms/OfferForm";
import {useGetOfferListQuery } from "features/offers/offersApiSlice";
import EditOfferForm from "components/forms/editForms/EditOfferForm";
import DeleteOfferForm from "components/forms/deleteForms/DeleteOfferForm";
const Offers = () => {
  const [editForm, setEditForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [deleteForm, setDeleteForm] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [offer,setOffer]=useState("")

  const toggleDeleteForm = () => setDeleteForm(!deleteForm);
  const openEditForm = () => setEditForm(true);
  const closeEditForm = () => setEditForm(false);
  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);


  const {
    data,
    isLoading,
    refetch: refetchList,
  } = useGetOfferListQuery();

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);


  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "packetName",
      headerName: "Packet Name",
      flex: 0.5,
    },
    {
      field: "months",
      headerName: "Months",
      flex: 0.5,
    },
    {
      field: "offerType",
      headerName: "Offer Type",
      flex: 0.5,
    },
    {
      field: "offerValue",
      headerName: "Offer Value",
      flex: 0.5,
    },

    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      align: "right",
      // headerAlign: "right",
      renderCell: (params) => {
        const editClick = () => {
          openEditForm();
          setOffer(params);
        };

        const viewClick = () => {
          // Handle view click
          console.log(`Viewing ${params.id}`);
        };

        const deleteClick = () => {
          toggleDeleteForm();
          setOffer(params);
        };
        return (
          <>
            <IconButton
              onClick={editClick}
              sx={{ color: "#EAD623" }}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>

            <IconButton
              onClick={deleteClick}
              sx={{ color: "#ff0000" }}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
        <TableComp
        columns={columns}
        data={tableData}
        handleOpenForm={handleOpenForm}
        title={"Offers"} 
        />


      {showForm && (
        <OverlayForm
          onClose={handleCloseForm}
          formComponent={<OfferForm onClose={handleCloseForm} refetchList={refetchList} />}
        />
      )}

      {editForm && (
        <OverlayForm
          formComponent={<EditOfferForm onClose={closeEditForm} offerId={offer.id} refetchList={refetchList} />}
          formTitle={"Edit offer"}
        />
      )}



{deleteForm && (
        <OverlayForm
          formComponent={
            <DeleteOfferForm
              offer={offer.row}
              onClose={toggleDeleteForm}
              refetch={refetchList}
            />
          }
          formTitle={"DELETE OFFER"}
        />
      )}
    </Box>
  );
};

export default Offers;
