import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "antd";
import {
  useGetAutocompleteOperatorsListQuery,
  useGetAutocompletePacketsListQuery,
  useGetAutocompleteRegionsListQuery,
  useGetAutocompleteServiceListQuery,
} from "features/autocomplete/autocompleteApiSlice";
import Header from "components/Header";
import { useGetAutoCompleteUsersMutation } from "features/users/usersApiSlice";
import AddNewHeader from "components/forms/headers/AddNewHeader";
const { RangePicker } = DatePicker;

const UsersHeader = ({
  refetch,
  pageNumber,
  pageSize,
  setFilters,
  handleOpenForm,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const [dates, setDates] = useState([]);

  const { data: regionListData, isLoading: isRegionLoading } =
    useGetAutocompleteRegionsListQuery();

    const [getAutoCompleteUsers, { isLoading: isAutoCompleteLoading }] =
    useGetAutoCompleteUsersMutation();
    const [selectedUser, setSelectedUser] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
  const [dateCreated, setDateCreated] = useState(""); // Assuming you want it to be a string, adjust if necessary
  const [regionName, setRegionName] = useState("");

  const [formData, setFormData] = useState({
    clientId: "",
    regionid: "",
  });

  const [results, setResults] = useState([]);

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        const { data: newResults } = await getAutoCompleteUsers({
          searchString: searchTerm,
          topRecords: 5,
        });
        setResults(newResults || []);
      } else {
        setResults([]);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  function isNullOrWhitespace(input) {
    if (typeof input === "undefined" || input == null) return true;
    return input.replace(/\s/g, "").length < 1;
  }

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "regionId") {
      setRegionName(value);
    }

    if (name === "dateCreated") {
      setDateCreated(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = async () => {
    const newFilters = [];

    if (selectedUser && !isNullOrWhitespace(selectedUser?.name) ) {
      newFilters.push({
        columnName: "Name",
        value: `${selectedUser.name}`,
        operation: 0,
      });
    }
    if (!isNullOrWhitespace(dateCreated)) {
      newFilters.push({
        columnName: "DateCreated",
        value: `${dateCreated}`,
        operation: 8,
      });
    }

    if (!isNullOrWhitespace(regionName)) {
      newFilters.push({
        columnName: "RegionName",
        value: `${regionName}`,
        operation: 0,
      });
    }

    await setFilters(newFilters);
    refetch({ pageNumber, pageSize, filters: newFilters });
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const start = dates[0].format("DD-MM-YYYY 00:01");
      const end = dates[1].format("DD-MM-YYYY 23:59");
      const fullDate = start + "|" + end;
      setDateCreated(fullDate);
    }
  };

  const uniqueId = "picker12345";

  return (
    <Box>
      <AddNewHeader
        openForm={handleOpenForm}
        title={"Users"}
      />
      <Box
        flexDirection={isNonMobile ? "row" : "column"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: isNonMobile ? "20px" : "10px",
          padding: "20px",
          height: isNonMobile ? "32px" : "auto",
          overflowY: isNonMobile ? "visible" : "auto",
        }}
      >
         <Autocomplete
          id="users"
          fullWidth
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          sx={{ height: "32px", padding: "0px", margin: "0"
          ,
          ".MuiAutocomplete-input": {
            padding: "0px !important" , // Adjusts the padding of the input field
          }, 
        
        }}
          freeSolo
          clearOnEscape={true}
          value={selectedUser}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          noOptionsText="No users found"
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
            {...params}
            label="Search for user"
            variant="outlined"
            sx={{
              height: "100%",
              padding: "0px",
              margin: "0",
              ".MuiOutlinedInput-root": {
                height: "100%",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          )}
        />

        <style>
          {`
          .${uniqueId} .ant-picker-input > input::placeholder {
            color: ${theme.palette.primary[200]};
          }

          .${uniqueId} .ant-picker-input > input {
            color: ${theme.palette.primary[200]};
          }
        `}
        </style>
        <RangePicker
          className={uniqueId}
          placeholder={["Start", "End"]}
          style={{
            width: "100%",
            background: theme.palette.background.default,
          }}
          onChange={handleDateChange}
          disabledDate={(current) => current && current.isAfter(moment())}
        />

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel id="region-label" shrink>
            Region
          </InputLabel>
          <Select
            labelId="region-label"
            id="region"
            sx={{ height: "100%" }}
            value={formData.regionId}
            onChange={handleChange}
            name="regionId"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            <MenuItem value={null}>None</MenuItem>
            {regionListData &&
              regionListData.map((region, index) => (
                <MenuItem key={index} value={region.value}>
                  {region.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          sx={{ mb: "5px", flexGrow: 1 }}
          onClick={handleClick}
        >
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default UsersHeader;
