import React, { useEffect, useState } from 'react'
import {
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
  } from "@mui/material";
  import Autocomplete from "@mui/material/Autocomplete";
  import { DatePicker } from "@mui/x-date-pickers";
  import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
  import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useGetAutoCompleteCustomersMutation } from 'features/customers/customersApiSlice';
  
const SaleInvoiceHeader  = ({ data,setData }) => {
    const [results, setResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [getAutoCompleteCustomers, { isLoading }] =
    useGetAutoCompleteCustomersMutation();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
          });
      };

      useEffect(() => {
        const timerId = setTimeout(async () => {
          if (searchTerm) {
            const { data: newResults } = await getAutoCompleteCustomers({
              searchString: searchTerm,
              topRecords: 10,
            });
            setResults(newResults || []);
          } else {
            setResults([]);
          }
        }, 700);
    
        return () => {
          clearTimeout(timerId);
        };
      }, [searchTerm]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <TextField
        label="Invoice Code"
        variant="outlined"
        size="small"
        name="invoiceCode"
        disabled={true}
        InputLabelProps={{ shrink: true }}
        value={data.invoiceCode}
        sx={{ width: "130px" }}
      />

        <Autocomplete
          name="customerAuto"
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.value
          }
          style={{ width: "200px" }}
          freeSolo
          clearOnEscape={true}
          value={data.customerName}
          onChange={(event, newValue) => {
            if(newValue && newValue.id)
            {
                setData({
                    ...data,
                    ["customerId"]: newValue.id,
                  });
            }
          }}
          noOptionsText="No customer found"
          inputValue={searchTerm}
          size='small'
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Customer" variant="outlined" />
          )}
        />
        
      <Box
        sx={{
          width: "180px",
          display: "flex",
          alignItems: "center",
          "& .MuiInputBase-root": { height: "37px" },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Invoice Date"
            name="registrationDate"
            inputFormat="dd-MM-yyyy"
            value={data.registrationDate}
            onChange={(e)=>{
                handleChange({target:{name:"registrationDate",value:e}});
            }}
          />
        </LocalizationProvider>
      </Box>

      <TextField
        label="Description"
        variant="outlined"
        size="small"
        name="description"
        value={data.description}
        onChange={handleChange}
      />
    </Box>
  );
};


export default SaleInvoiceHeader