import React, { useState } from 'react';
import { Modal, Box, Radio, RadioGroup, FormControlLabel, FormControl, Button, Typography } from '@mui/material';

const PrintSelectionModal = ({ open, handleClose, handlePrint }) => {
  const [selectedFormat, setSelectedFormat] = useState('A4');

  const handleChange = (event) => {
    setSelectedFormat(event.target.value);
  };

  const handleConfirm = () => {
    handlePrint(selectedFormat);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose} // This triggers the cancel action
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 300,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Select Print Format
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="print format"
            name="printFormat"
            value={selectedFormat}
            onChange={handleChange}
          >
            <FormControlLabel value="A4" control={<Radio />} label="A4 Version" />
            <FormControlLabel value="Thermal" control={<Radio />} label="Skontrino" />
          </RadioGroup>
        </FormControl>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Print
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PrintSelectionModal;
