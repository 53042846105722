import React from "react";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PlaceOutlined,
  MiscellaneousServicesOutlined,
  PieChartOutline,
  SupervisedUserCircleOutlined,
  AddCardOutlined,
  AccountBalanceOutlined,
  SettingsApplicationsOutlined,
} from "@mui/icons-material";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from "./FlexBetween";
import profileImage from "assets/cat.jpg";
import { getClaimByKey } from "utils/jwtUtils";


const OperatorSidebar =({ drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
var rights=getClaimByKey("Rights");

  const navItems = [
    {
      text: "Dashboard",
      icon: <HomeOutlined />,
      isEnabled:true
    },
    {
      text: "Users",
      icon: <Groups2Outlined />,
      isEnabled:rights?.includes("ManageUsers")
    },
    {
      text: "Functionalities",
      icon: null,
      isEnabled:true
    },
    {
      text: "ADD SERVICE",
      icon: <SettingsApplicationsOutlined />,
      isEnabled:rights?.includes("ManageServices")
    },
    {
      text: "ADD PAYMENT",
      icon: <AddCardOutlined />,
      isEnabled:true
    },
    {
      text: "PAYMENTS",
      icon: <AccountBalanceOutlined />,
      isEnabled:true
    },
  ];
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
      setActive(pathname.substring(1));
    }, [pathname]);

    return (
      <Box component="nav">
        {isSidebarOpen && (
          <Drawer
            open={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            variant="persistent"
            anchor="left"
            sx={{
              width: drawerWidth,
              "& .MuiDrawer-paper": {
                color: theme.palette.secondary[200],
                backgroundColor: theme.palette.background.alt,
                boxSixing: "border-box",
                borderWidth: isNonMobile ? 0 : "2px",
                width: drawerWidth,
              },
            }}
          >
            <Box width="100%">
              <Box m="1.5rem 2rem 2rem 3rem">
                <FlexBetween color={theme.palette.secondary.main}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap="0.5rem"
                >
                  <Typography variant="h4" fontWeight="bold">
                    SOLVENDA
                  </Typography>
                  <Typography variant="h5" fontWeight="bold" pl={4}>
                    {process.env.REACT_APP_BASE_OWNER}
                  </Typography>
                </Box>
                  {!isNonMobile && (
                    <IconButton
                      onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                    >
                      <ChevronLeft />
                    </IconButton>
                  )}
                </FlexBetween>
              </Box>
              <List>
                {navItems.filter(e=>e.isEnabled).map(({ text, icon,isEnabled }) => {
                  if (!icon) {
                    return (
                      <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                        {text}
                      </Typography>
                    );
                  }
                  const lcText = text.toLowerCase();

                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => {
                          if (`${lcText}` == "add service") {
                            navigate(`services/add-service`);
                          } else if (`${lcText}` == "add payment") {
                            navigate(`payments/add-payment`);
                          } else {
                            navigate(`/${lcText}`);
                            setActive(lcText);
                          }
                        }}
                        sx={{
                          backgroundColor:
                            active === lcText
                              ? theme.palette.secondary[300]
                              : "transparent",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "2rem",
                            color:
                              active === lcText
                                ? theme.palette.primary[600]
                                : theme.palette.secondary[200],
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} />
                        {active === lcText && (
                          <ChevronRightOutlined sx={{ ml: "auto" }} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Drawer>
        )}
      </Box>
    );
  };

export default OperatorSidebar;
