import { apiSlice } from "app/api/apiSlice";

export const autocompleteApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAutocompleteOperatorsList: build.query({
      query: () => "autocomplete/operators",
    }),
    getAutocompletePacketsList: build.query({
      query: () => "autocomplete/packets",
    }),
    getAutocompletePacketsListForOperator: build.query({
      query: () => "autocomplete/operator/packets",
    }),
    getAutocompleteServiceList: build.query({
      query: () => "autocomplete/services",
    }),
    getAutocompleteRegionsList: build.query({
      query: () => "autocomplete/regions",
    }),
    getAutocompleteRegionsListForOperator: build.query({
      query: () => "autocomplete/operator/regions",
    }),
    getAutocompleteUsersList: build.query({
      query: () => "autocomplete/users",
    }),

    getAutoCompleteUsersForOperator: build.mutation({
      query: (request) => ({
        url: "user/autocomplete",
        method: "POST",
        body: request,
      }),
    }),
  }),
});

export const {
  useGetAutocompleteOperatorsListQuery,
  useGetAutocompletePacketsListQuery,
  useGetAutocompleteServiceListQuery,
  useGetAutocompleteRegionsListQuery,
  useGetAutocompleteUsersListQuery,
  useGetAutocompleteRegionsListForOperatorQuery,
  useGetAutocompletePacketsListForOperatorQuery,
  useGetAutoCompleteUsersForOperatorMutation,
} = autocompleteApiSlice;
