import jwt_decode from "jwt-decode";

export const getClaimByKey = (key) => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No token found");
      return null;
    }
    const decoded = jwt_decode(token);
    return decoded[key];
  } catch (e) {
    console.error("Invalid token or key", e);
    return null;
  }
};


export const getUserFromClaims = () => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.error("No token found");
      return null;
    }
    const decoded = jwt_decode(token);
    return decoded;
  } catch (e) {
    console.error("Invalid token or key", e);
    return null;
  }
};
