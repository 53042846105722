import { apiSlice } from "app/api/apiSlice";

export const operatorsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOperatorList: build.query({
      query: () => "operator/list",
    }),
    getOperatorFilterList: build.query({
      query: () => "operator/filter-list",
    }),
    getOperator: build.query({
      query: (id) => `operator/${id}`,
    }),
    createOperator: build.mutation({
      query: (request) => ({
        url: "authentication/register",
        method: "POST",
        body: request,
      }),
    }),
    getOperatorDetails: build.query({
      query: (id) => `operator/details/${id}`,
    }),
    updateOperator: build.mutation({
      query: ({ id, request }) => ({
        url: `operator/${id}`,
        method: "PUT",
        body: request,
      }),
    }),
    updateOperatorCredentials: build.mutation({
      query: ({ id, request }) => ({
        url: `operator/auth/${id}`,
        method: "PUT",
        body: request,
      }),
    }),

    getOperatorsPagination: build.query({
      query: (args) => {
        let requestBody = {
          fromSearchAll: false,
          pageNumber: args.pageNumber,
          pageSize: args.pageSize,
          filters: [],
        };

        if (args.filters && args.filters.length > 0) {
          requestBody.filters = args.filters;
        }

        return {
          url: "operator/get-all/list",
          method: "POST",
          body: requestBody,
        };
      },
    }),

    deleteOperator: build.mutation({
      query: (id ) => ({
        url: `operator/${id}`,
        method: "DELETE",
      }),
    }),

    getDashboard: build.query({
      query: () => `operator/dashboard`,
    }),

    getOperatorDashboard: build.mutation({
      query: (id) => ({
        url: `operator/dashboard/${id}`,
        method: "GET",
      }),
    }),
    
  }),
});

export const {
  useGetOperatorListQuery,
  useGetOperatorFilterListQuery,
  useGetOperatorQuery,
  useCreateOperatorMutation,
  useGetOperatorDetailsQuery,
  useUpdateOperatorMutation,
  useUpdateOperatorCredentialsMutation,
  useGetOperatorsPaginationQuery,
  useDeleteOperatorMutation,
  useGetDashboardQuery,
  useGetOperatorDashboardMutation,
} = operatorsApiSlice;
