import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "antd";
import {
  useGetAutocompleteOperatorsListQuery,
  useGetAutocompletePacketsListQuery,
  useGetAutocompleteRegionsListQuery,
  useGetAutocompleteServiceListQuery,
} from "features/autocomplete/autocompleteApiSlice";
import Header from "components/Header";
import { useGetAutoCompleteUsersMutation } from "features/users/usersApiSlice";
const { RangePicker } = DatePicker;

const PaymentHeader = ({
  refetch,
  pageNumber,
  pageSize,
  setFilters,
  selectedUserFromState,
  totalAmount,
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const { data: regionListData, isLoading: isRegionLoading } =
    useGetAutocompleteRegionsListQuery();

  const { data: packetListData, isLoading: isPacketLoading } =
    useGetAutocompletePacketsListQuery();

  const { data: serviceListData, isLoading: isServiceLoading } =
    useGetAutocompleteServiceListQuery();

  const { data: operatorList, isLoading: isOperatorLoading } =
    useGetAutocompleteOperatorsListQuery();

  const [getAutoCompleteUsers, { isLoading: isAutoCompleteLoading }] =
    useGetAutoCompleteUsersMutation();
  const [selectedUser, setSelectedUser] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [clientName, setClientName] = useState("");
  const [datePaid, setDatePaid] = useState(""); // Assuming you want it to be a string, adjust if necessary
  const [regionName, setRegionName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [packetName, setPacketName] = useState("");
  const [operator, setOperator] = useState("");
  const [isPaid, setIsPaid] = useState("");
  
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [formData, setFormData] = useState({
    clientId: "",
    regionId: "",
    serviceId: "",
    packetId: "",
    operatorId: "",
    isPaid: null,
  });

  const [results, setResults] = useState([]);

  useEffect(() => {
    if (selectedUserFromState) {
      setSearchTerm(selectedUserFromState);
    }
  }, [selectedUserFromState]);

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        const { data: newResults } = await getAutoCompleteUsers({
          searchString: searchTerm,
          topRecords: 5,
        });
        setResults(newResults || []);
      } else {
        setResults([]);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  function isNullOrWhitespace(input) {
    if (typeof input === "undefined" || input == null) return true;
    return input.replace(/\s/g, "").length < 1;
  }

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "regionId") {
      setRegionName(value);
    }
    if (name === "serviceId") {
      setServiceName(value);
    }

    if (name === "packetId") {
      setPacketName(value);
    }

    if (name === "operatorId") {
      setOperator(value);
    }

    if (name === "isPaid") {
      setIsPaid(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = async () => {
    const newFilters = [];

    if (selectedUser && !isNullOrWhitespace(selectedUser?.name)) {
      newFilters.push({
        columnName: "ClientName",
        value: `${selectedUser.name}`,
        operation: 0,
      });
    }
    if (!isNullOrWhitespace(datePaid)) {
      newFilters.push({
        columnName: "DateCreated",
        value: `${datePaid}`,
        operation: 8,
      });
    }

    if (!isNullOrWhitespace(regionName)) {
      newFilters.push({
        columnName: "RegionName",
        value: `${regionName}`,
        operation: 0,
      });
    }

    if (!isNullOrWhitespace(packetName)) {
      newFilters.push({
        columnName: "PacketName",
        value: `${packetName}`,
        operation: 0,
      });
    }

    if (!isNullOrWhitespace(serviceName)) {
      newFilters.push({
        columnName: "ServiceName",
        value: `${serviceName}`,
        operation: 0,
      });
    }

    if (!isNullOrWhitespace(operator)) {
      newFilters.push({
        columnName: "Operator",
        value: `${operator}`,
        operation: 0,
      });
    }

    if (isPaid !== null && isPaid !== "") {
      newFilters.push({
        columnName: "IsPaid",
        value: `${isPaid}`,
        operation: 0,
      });
    }

    await setFilters(newFilters);
    refetch({ pageNumber, pageSize, filters: newFilters });
  };

  const handleDateChange = (dates, dateStrings) => {
    if (dates) {
      const start = dates[0].format("YYYY-MM-DD 00:01");
      const end = dates[1].format("YYYY-MM-DD 23:59");
      const fullDate = start + "|" + end;
      setDatePaid(fullDate);
    }
  };

  const handleStartDateChange = (date, dateString) => {
    setStartDate(date);
    if (date && endDate) {
      setDatePaid(
        `${date.format("YYYY-MM-DD 00:01")}|${endDate.format("YYYY-MM-DD 23:59")}`
      );
    }
  };

  const handleEndDateChange = (date, dateString) => {
    setEndDate(date);
    if (startDate && date) {
      setDatePaid(
        `${startDate.format("YYYY-MM-DD 00:01")}|${date.format("YYYY-MM-DD 23:59")}`
      );
    }
  };

  const uniqueId = "picker12345";

  return (
    <Box>
      <Box>
        <Box sx={{ pb: "10px", display: "flex", alignItems: "center" }}>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            variant="h2"
            color={theme.palette.secondary[100]}
            fontWeight="bold"
            sx={{ mb: "5px", flexGrow: 0 }}
          >
            Payments
          </Typography>

          <Box sx={{ flexGrow: 1, visibility: "hidden" }} />
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ mb: "5px", flexGrow: 0 }}
            boxShadow={2}
          >
            Total:{" "}
            {new Intl.NumberFormat("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
            })
              .format(totalAmount ?? 0)
              .toString() + " ALL"}
          </Typography>
        </Box>
      </Box>
      <Box
        flexDirection={isNonMobile ? "row" : "column"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: isNonMobile ? "20px" : "10px",
          padding: "20px",
          height: isNonMobile ? "32px" : "auto",
          overflowY: isNonMobile ? "visible" : "auto",
        }}
      >
        <Autocomplete
          id="users"
          fullWidth
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          sx={{
            height: "32px",
            padding: "0px",
            margin: "0",
            ".MuiAutocomplete-input": {
              padding: "0px !important", // Adjusts the padding of the input field
            },
          }}
          freeSolo
          clearOnEscape={true}
          value={selectedUser}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          noOptionsText="No users found"
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for user"
              variant="outlined"
              sx={{
                height: "100%",
                padding: "0px",
                margin: "0",
                ".MuiOutlinedInput-root": {
                  height: "100%",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />

        <style>
          {`
          .${uniqueId} .ant-picker-input > input::placeholder {
            color: ${theme.palette.primary[200]};
          }

          .${uniqueId} .ant-picker-input > input {
            color: ${theme.palette.primary[200]};
          }
        `}
        </style>
        {
        isNonMobile ? (
          <RangePicker
          className={uniqueId}
          placeholder={["Start", "End"]}
          style={{
            width: isNonMobile ? "100%" : "auto", 
            background: theme.palette.background.default,
          }}
          onChange={handleDateChange}
          disabledDate={(current) => current && current.isAfter(moment())}
        />
        ):(
          <>
            <DatePicker
              placeholder="Start"
              value={startDate}
              onChange={handleStartDateChange}
              style={{
                width:  "100%", 
                background: theme.palette.primary,
              }}
            />
            <DatePicker
              placeholder="End"
              value={endDate}
              onChange={handleEndDateChange}
              style={{
                width: "100%", 
                background: theme.palette.primary,
              }}
            />
          </>
        ) 
      }

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel id="region-label" shrink>
            Region
          </InputLabel>
          <Select
            labelId="region-label"
            id="region"
            sx={{ height: "100%" }}
            value={formData.regionId}
            onChange={handleChange}
            name="regionId"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            <MenuItem value={null}>None</MenuItem>
            {regionListData &&
              regionListData.map((region, index) => (
                <MenuItem key={index} value={region.value}>
                  {region.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel shrink>Service</InputLabel>
          <Select
            name="serviceId"
            sx={{ height: "100%" }}
            value={formData.serviceId}
            onChange={handleChange}
            required
          >
            <MenuItem value={null}>None</MenuItem>
            {serviceListData &&
              serviceListData.map((service, index) => (
                <MenuItem key={index} value={service.value}>
                  {service.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel shrink>Packets</InputLabel>
          <Select
            required
            name="packetId"
            sx={{ height: "100%" }}
            onChange={handleChange}
            value={formData.packetId}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            <MenuItem value={null}>None</MenuItem>
            {packetListData &&
              packetListData.map((packet, index) => (
                <MenuItem key={index} value={packet.value}>
                  {packet.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel shrink>Operator</InputLabel>
          <Select
            name="operatorId"
            sx={{ height: "100%" }}
            value={formData.operatorId}
            onChange={handleChange}
            required
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            <MenuItem value={null}>None</MenuItem>
            {operatorList &&
              operatorList.map((operator, index) => (
                <MenuItem key={index} value={operator.value}>
                  {operator.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel shrink>Paid</InputLabel>
          <Select
            name="isPaid"
            sx={{ height: "100%" }}
            value={formData.isPaid}
            onChange={handleChange}
            required
          >
            <MenuItem value={null}>None</MenuItem>
            <MenuItem value={0}>Not Paid</MenuItem>
            <MenuItem value={1}>Paid</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          sx={{ mb: "5px", flexGrow: 1 }}
          onClick={handleClick}
        >
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default PaymentHeader;
