import { apiSlice } from "app/api/apiSlice";

export const paumentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createPayment: build.mutation({
      query: (request) => ({
        url: "payment",
        method: "POST",
        body: request,
      }),
    }),
    getPaymentsForAdmin: build.query({
      query: (args) => {
        let requestBody = {
          fromSearchAll: false,
          pageNumber: args.pageNumber,
          pageSize: args.pageSize,
          filters: [],
        };

        if (args.filters && args.filters.length > 0) {
          requestBody.filters = args.filters;
        }

        return {
          url: "payment/get-all/list",
          method: "POST",
          body: requestBody,
        };
      },
    }),

    getPaymentsForOperator: build.query({
      query: (args) => {
        let requestBody = {
          fromSearchAll: false,
          pageNumber: args.pageNumber,
          pageSize: args.pageSize,
          filters: [],
        };

        if (args.filters && args.filters.length > 0) {
          requestBody.filters = args.filters;
        }

        return {
          url: "payment/operator/get-all/list",
          method: "POST",
          body: requestBody,
        };
      },
    }),

    getUnpaidPayments: build.query({
      query: (id) => `payment/unpaid/${id}`,
    }),

    getUnpaidPaymentsCount: build.mutation({
      query: (id) => `payment/unpaid-count/${id}`,
    }),

    liquidatePayment: build.mutation({
      query: (id) => ({
        url: `payment/liquidate/${id}`,
        method: "POST"
       }),
    }),

    deletePayment: build.mutation({
      query: (id) => ({
        url: `payment/delete/${id}`,
        method: "POST"
      }),
    }),

    getPrintDetails: build.query({
      query: (id) => `payment/print/${id}`,
    }),
  }),
});

export const {
  useCreatePaymentMutation,
  useGetPaymentsForAdminQuery,
  useGetPaymentsForOperatorQuery,
  useGetUnpaidPaymentsQuery,
  useGetUnpaidPaymentsCountMutation,
  useLiquidatePaymentMutation,
  useDeletePaymentMutation,
  useGetPrintDetailsQuery,
} = paumentsApiSlice;
