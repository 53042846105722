import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useCreateRadiusUserMutation } from "features/userPackets/userPacketsApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";

const CreateRadiusForm = ({onClose,formData, currentUser,refetchList,updateErrorSate}) => {

  const [createRadiusUser]=useCreateRadiusUserMutation();
  const {showNotification}=useServerResponseNotifier();

  const [internetData, setInternetData] = useState({
    username: "",
    password: "1234",
    ipAddress: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const [dataForUpdate,setDataUpdate]=useState(null)

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setInternetData({
      ...internetData,
      [name]: value,
    });

    setDataUpdate({
      ...dataForUpdate,
      [name]:value,
    });
  };

  useEffect(() => {
    setDataUpdate({
      ...formData,
      ...internetData,
      ["username"]:generateData(),
      ["password"]:"1234"
    });
  }, [formData,currentUser]);


  const validateFields = () => {
    const newErrors = {};
    if(!validateParentFields()) newErrors.parent = "From ParentComp"
    if (!dataForUpdate.username) newErrors.username = "Username is required";
    if (!dataForUpdate.password) newErrors.password = "Password is required";

    return newErrors;
  };

  const validateParentFields=()=>{
    const newErrors = {};
    if (!dataForUpdate.serviceId) newErrors.serviceId = "Service is required";
    if (!dataForUpdate.packetId) newErrors.packetId = "Packet is required";
    if (!dataForUpdate.userId) newErrors.userId = "User is required";
    
    if (Object.keys(newErrors).length > 0) {
      updateErrorSate(newErrors);
      return false;
    }
    return true;
  }


  const generateData = () => {
    if(!currentUser) return;
    let generatedUsername = currentUser.name.replaceAll(" ", "");

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);

    generatedUsername = generatedUsername.concat(weekNumber,currentDate.getHours());
    return generatedUsername;
  };



  const handleSubmit=async (e)=>{
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    await createRadiusUser(dataForUpdate).unwrap()
    .then(showNotification("Service for user created succesfully!",200))
    .catch((error) => {
      showNotification(error.data.Message,error.data.StatusCode)
    });

   await onClose();
   await refetchList();
  }
  return (
    <Box paddingTop="20px">
      <TextField
        fullWidth
        defaultValue={generateData()}
        label="Username"
        margin="normal"
        name="username"
        onChange={handleChange}
        error={!!errors.username}
        helperText={errors.username} 
      />
      <TextField
        fullWidth
        defaultValue={internetData.password}  // krijo logjiken qe ta marre nga default config passwordin
        label="Password"
        margin="normal"
        name="password"
        variant="outlined" 
        onChange={handleChange}
        error={!!errors.password}
        helperText={errors.password} 
      />
      <TextField
        fullWidth
        label="IP"
        margin="normal"
        name="ipAddress"
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Description"
        margin="normal"
        name="description"
        onChange={handleChange}
      />

<CloseAddFooter onClose={onClose} onClick={handleSubmit} />

    </Box>
  );
};

export default CreateRadiusForm;
