import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useGetUnpaidPaymentsCountMutation } from "features/payments/paymentsApiSlice";
import { useNavigate } from "react-router-dom";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { useDeleteUserMutation } from "features/users/usersApiSlice";

const DeleteUserForm = ({ onClose, user, refetch }) => {
  const [getUnpaidPaymentsCount] = useGetUnpaidPaymentsCountMutation();
  const [deleteUser]=useDeleteUserMutation();
  const [unpaidCount, setUnpaidCount] = useState("");
  const { showNotification } = useServerResponseNotifier();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const { data: unPaidNr } = await getUnpaidPaymentsCount(user.id);
      setUnpaidCount(unPaidNr.count);
    })();
  }, [user]);


  const handleSubmit=async ()=>{
    
    if(unpaidCount && unpaidCount>0)
    {
      showNotification("CANNOT DELETE USER! \nProceede the payments first.",500);
      return;
    }
    else
    {
      await deleteUser(user.id).unwrap()
      .then(() => {
        showNotification("User deleted successfully", 200);
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetch();
    }
  }

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack alignItems="center">
          <Typography fontSize="20px">
            Are you sure you want to delete this user?
          </Typography>
          <Typography fontSize="20px" fontWeight="bold" pt={"20px"}>
            {user.name}
          </Typography>

          <Box display={unpaidCount > 0 ? "block" : "none"} pt={"15px"}>
            <Typography
              onClick={(e) => {
                navigate(`/payments/add-payment/${user.id}`);
              }}
              fontSize={"20px"}
              color={"red"}
              fontWeight={"bold"}
              sx={{ cursor: "pointer" }}
            >
              This user has {unpaidCount} unpaid invoices!
            </Typography>
          </Box>
        </Stack>
      </Box>

      <CloseAddFooter
        footerLabel={"DELETE"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default DeleteUserForm;
