import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const DashboardLayout = ({ title,value,iconComponent}) => {
    return (
      <Card sx={{ borderRadius: '15px', m: 2, boxShadow:5 }}> {/* Increased margin */}
      <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}> 
          <Box >
          <Typography color="textSecondary" gutterBottom sx={{ fontSize: '1.1rem' }}>
              {title}
            </Typography>
            <Typography variant="h5" sx={{ fontSize: '1.5rem' , fontWeight:"bold" }}> 
                   {value}
            </Typography>
          </Box>
           {iconComponent} 
        </CardContent>
      </Card>
    );
  };

export default DashboardLayout;
