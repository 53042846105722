import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import OverlayForm from "components/OrverlayForm";
import SingleServicePacket from "components/addService/SingleServicePacket";
import { useGetUserServicesForUserIdMutation } from "features/userPackets/userPacketsApiSlice";
import {
  useGetAutoCompleteUsersMutation,
  useGetUserPartialQuery,
} from "features/users/usersApiSlice";
import React, { useEffect, useState } from "react";
import PaymentForm from "./PaymentForm";
import DualOverlay from "components/overlays/DualOverlay";
import UnpaidPayments from "./UnpaidPayments";
import { useGetUnpaidPaymentsCountMutation } from "features/payments/paymentsApiSlice";
import { keyframes } from "@mui/system";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const AddPayment = () => {
  const { id } = useParams();
  const userId = parseInt(id);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState("");
  const [list, setList] = useState([]);
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showForm, setShowForm] = useState(false);
  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);
  const [unpaidCount, setUnpaidCount] = useState("");

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [userPackets, setUserPackets] = useState(new Map());
  const [paymentIds, setPaymentIds] = useState(new Map());
  const [packetsRendered, setPacketsRendered] = useState(false);
  const [showUnpaidForm, setShowUnpaidForm] = useState(false);
  const showUnapidFormToggle = () => setShowUnpaidForm(!showUnpaidForm);

  const addItemToUsePackets = (item) => {
    setUserPackets((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(item.userPacketId, item);
      return newMap;
    });
  };

  const removeItemToUsePackets = (userPacketId) => {
    setUserPackets((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.delete(userPacketId);
      return newMap;
    });
  };

  const addItemToPayment = (item) => {
    setPaymentIds((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.set(item.id, item);
      return newMap;
    });
  };

  const removeItemToPayment = (paymentId) => {
    setPaymentIds((prevMap) => {
      const newMap = new Map(prevMap);
      newMap.delete(paymentId);
      return newMap;
    });
  };

  const [getAutoCompleteUsers] = useGetAutoCompleteUsersMutation();

  const [getUnpaidPaymentsCount] = useGetUnpaidPaymentsCountMutation();

  const [getUserServerForUserId] = useGetUserServicesForUserIdMutation();

  const { data, isLoading } = useGetUserPartialQuery(userId);

//clear all the states when switching user
  useEffect(() => {
    setList([]);
    setUserPackets(new Map());
    setPaymentIds(new Map());
    setPacketsRendered(false);
    setUnpaidCount("");
  }, [selectedUser]);

  
  useEffect(() => {
    if (!isLoading) {
      setSelectedUser(data);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      if (selectedUser) {
        const { data: userList } = await getUserServerForUserId(
          selectedUser.id
        );
        setList(userList);

        const { data: unPaidNr } = await getUnpaidPaymentsCount(
          selectedUser.id
        );
        setUnpaidCount(unPaidNr.count);
      }
    })();
  }, [selectedUser]);

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        const { data: newResults } = await getAutoCompleteUsers({
          searchString: searchTerm,
          topRecords: 10,
        });
        setResults(newResults || []);
      } else {
        setResults([]);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  useEffect(() => {
    if (list && list.length > 0) {
      setPacketsRendered(true);
    }
  }, [list]);

  const handleChange = (e, newValue) => {
    const { name, value } = e.target;
    // 

    if (newValue) {
      navigate(`/payments/add-payment/${newValue.id}`, {
        state: { ...newValue },
      });
    }

    //   if (newValue === null) {
    //     setSelectedUser("");
    //     setList([]);
    //     setUserPackets([]);
    //   } else {
    //     setSelectedUser(newValue);
    //     setUserPackets(null);
    //   }
  };

  const pulsate = keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.1);
      opacity: 0.8;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  `;

  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"} paddingTop={3}>
        <Autocomplete
          id="user-search"
          name="users"
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{ width: "50%" }}
          clearOnEscape={true}
          value={selectedUser}
          onChange={handleChange}
          noOptionsText="No user was found"
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search for user" variant="outlined" />
          )}
        />
      </Box>

      <Box
        display={unpaidCount && unpaidCount > 0 ? "flex" : "none"}
        justifyContent={"right"}
      >
        <Typography
          mb={-5}
          sx={{
            mr: "30px",
            fontSize: "19px",
            color: "red",
            fontWeight: "bold",
            animation: `${pulsate} 1s infinite`,
            cursor: "pointer",
          }}
          onClick={(e) => {
            setShowForm(true);
          }}
        >
          UNPAID PAYMENTS: {unpaidCount}
        </Typography>
      </Box>

      {list &&
        list.map((detail) => {
          return (
            <SingleServicePacket
              details={detail}
              user={selectedUser}
              addToParent={addItemToUsePackets}
              removeFromParent={removeItemToUsePackets}
            />
          );
        })}

      {packetsRendered && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={handleOpenForm}>
            PROCEEDE PAYMENT
          </Button>
        </Box>
      )}

      {showForm && (
        <DualOverlay
          onClose={handleCloseForm}
          formComponent={
            <PaymentForm
              onClose={handleCloseForm}
              services={Array.from(userPackets.values())}
              unpaidServices={Array.from(paymentIds.values())}
              user={selectedUser}
              hasUnpaidPayments={unpaidCount ? true : false}
              setShowUnpaidForm={showUnapidFormToggle}
            />
          }
          secondComponent={
            unpaidCount && showUnpaidForm ? (
              <UnpaidPayments
                onClose={handleCloseForm}
                services={Array.from(userPackets.values())}
                userId={selectedUser.id}
                addToBasket={addItemToUsePackets}
                removeFromBasket={removeItemToUsePackets}
                addToParent={addItemToPayment}
                removeFromParent={removeItemToPayment}
              />
            ) : null
          }
        />
      )}
    </Box>
  );
};

export default AddPayment;
