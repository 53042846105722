import React from "react";
import { useGetServiceListQuery } from "features/services/servicesApiSlice";
import Header from "components/Header";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";


const Service = () => {
  const theme = useTheme();
  const { data, isLoading } = useGetServiceListQuery();

  const state = useSelector((state) => state);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.5,
    }
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Services" subtitle="List of services" />
      <Box
      mt="40px"
      height="75vh"
      sx={{
        "& .MuiDataGrid-root":{
            border:"none"
        },
        "& .MuiDataGrid-cell":{
            borderBottom:"none"
        },
        "& .MuiDataGrid-columnHeaders":{
            backgroundColor:theme.palette.background.alt,
            color:theme.palette.secondary[100],
            borderBottom:"none"
        },
        "& .MuiDataGrid-virtualScroller":{
            backgroundColor:theme.palette.primary.light,
        },
        "& .MuiDataGrid-footerConainer":{
            backgroundColor:theme.palette.background.alt,
            color:theme.palette.secondary[100],
            borderTop:"none"
        },
        "& .MuiDataGrid-toolbarContainer .MuiButton-text":{
            color:`${theme.palette.secondary[200]} !important` ,
        }

      }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.id}
          rows={data || []}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default Service;
