import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useGetPacketsForRegionAndServiceMutation } from "features/packets/packetsApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { useGetOfferPMMutation } from "features/offers/offersApiSlice";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getClaimByKey } from "utils/jwtUtils";

const SingleServicePacket = ({
  details,
  user,
  addToParent,
  removeFromParent,
}) => {
  const { showNotification } = useServerResponseNotifier();

  const [monthsList, setMonthsList] = useState([]);
  const [selectedMonthDuration, setSelectedMonthDuration] = useState(null);
  const [packets, setPackets] = useState([]);
  const [selectedPacket, setSelectedPacket] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [endDateFromMonth, setEndDateFromMonth] = useState(new Date());
  const [isEndCalendarChng, setEndCalendarChng] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [offer,setOffer]=useState([]);
  const role = getClaimByKey("role");
  const rights=getClaimByKey("Rights");

  
  const isMobile = useMediaQuery('(max-width:600px)');
  const [getPacketsForRegionAndService] =
    useGetPacketsForRegionAndServiceMutation();

    const [getOfferPM]=useGetOfferPMMutation();

  const [currentUser, setCurrentUser] = useState({
    id: "",
    name: "",
    regionId: "",
  });

  const [formData, setFormData] = useState({
    packetId: details.packetId || "",
    typeIdentifier: details.typeIdentifier || "",
    status: details.status || "",
    serviceName: details.serviceName || "",
    serviceId: details.serviceId || "",
    userPacketId: details.userPacketId || "",
    endExipryDate: details.endExpiryDate || "",
    offerId:offer?.id||null,

    monthRange: "",
    monthDuration: 0,
    startDate: null,
    endDate: null,
    description: "",
    price: "",
  });


  useEffect(() => {
    setFormData({
      packetId: details.packetId || "",
      typeIdentifier: details.typeIdentifier || "",
      status: details.status || "",
      serviceName: details.serviceName || "",
      serviceId: details.serviceId || "",
      userPacketId: details.userPacketId || "",
      endExipryDate: details.endExpiryDate || null,
      offerId:offer?.id||null,
    });
  }, [details]);

  const getStartDate = async () => {
    if (formData.endExipryDate) {
      const expiry = new Date(formData.endExipryDate);
      const today = new Date();
      if (expiry < today) {
        setStartDate(today);
      } else {
        setStartDate(expiry);
        setEndDate(expiry);
      }
    }
  };

  useEffect(() => {
    const listMothns = getMothns();
    setMonthsList(listMothns);
    setCurrentUser(user);
    getStartDate();
  }, [details, user]);


  useEffect(() => {
    let totPrice = 0;
    if (selectedPacket && isEndCalendarChng && !isSameDate(endDate,endDateFromMonth)) {
      const pricePerDay = selectedPacket.price / 30;
      const durationInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
      totPrice =durationInDays * pricePerDay;
      // const pricePerDay = Math.ceil(selectedPacket.price / 30);
      // totPrice =  ((endDate - startDate) / (1000 * 60 * 60 * 24)).toFixed(2) * pricePerDay;
    } else if (selectedMonthDuration && selectedPacket) {
      totPrice = (selectedMonthDuration * selectedPacket.price);
    }
    setTotalPrice(totPrice);
  }, [endDate, selectedPacket, startDate, selectedMonthDuration]);

  useEffect(()=>{
    if(selectedMonthDuration==0)
      setOffer(null);
  },[selectedMonthDuration])

  useEffect(() => {
    const fetchPackets = async () => {
      if (formData.serviceId && currentUser.regionId) {
        try {
          const { data: payload } = await getPacketsForRegionAndService({
            serviceId: formData.serviceId,
            regionId: currentUser.regionId,
          });

          setPackets(payload || []);
          if (payload) {
            const selPacket = payload.find((e) => e.id == formData.packetId);
            setSelectedPacket(selPacket);
          }

        } catch (error) {
          showNotification(error.data.Message, error.data.StatusCode);
        }
      }
    };

    fetchPackets();
  }, [currentUser]);


  useEffect(() => {
    //if (totalPrice != 0 && totalPrice >= 0 && endDate != (new Date())) {
      // if ( totalPrice != 0 && totalPrice >= 0 && endDate != (new Date())  ||  selectedPacket.price===0 && endDate != (new Date())  ) {
    if ( totalPrice != 0 && totalPrice >= 0 && !isSameDate(endDate,startDate) ||  selectedPacket.price===0 ) {
      addToParent({
        userPacketId: formData.userPacketId,
        packetName: selectedPacket.name,
        monthValue: selectedMonthDuration,
        packetPrice: selectedPacket.price,
        totalPrice: offer?.newPrice  ? offer.newPrice: totalPrice,
        expiryStart: startDate,
        expiryEnd: endDate,
        isUsed: true,
        offer: offer||null,
        newPacketId: selectedPacket.id != details.packetId ? selectedPacket.id : null,
        typeIdentifier: details.typeIdentifier || "",
        serviceName: details.serviceName || "",
        user:user||null,
      });
    }

    //endDate.getDate() == (new Date()).getDate()
    if (selectedMonthDuration == 0 && isSameDate(endDate,startDate)  ) {
      //deselect
      removeFromParent(formData.userPacketId);
    }

  }, [totalPrice,offer,endDate]);



  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "monthDuration") {
      setSelectedMonthDuration(value);

      if(value==0)
      {
        setEndDate(startDate);
       // setStartDate(resetDate);
        setEndDateFromMonth(startDate);
      }
      else
      {
        const newEndDate = new Date(startDate.getTime()); // Create a new Date instance
        newEndDate.setMonth(newEndDate.getMonth() + parseInt(value, 10));
  
        const expectedMonth = (startDate.getMonth() + parseInt(value, 10)) % 12;
        if (newEndDate.getMonth() !== expectedMonth) {
          // Adjust the date to the last day of the previous month
          newEndDate.setDate(0);
        }
        setEndDateFromMonth(newEndDate);
        setEndDate(newEndDate);
        await getOffer(value);
      }

      setEndCalendarChng(false);
    }

    if (name === "packetId") {
      const newPacket = packets.find((e) => e.id == value);
      setSelectedPacket(newPacket);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getMothns = () => {
    let months = [];
    // months.push({
    //   duration: 0,
    //   value: `Select Month`,
    // });
    months.push({
      duration: 1,
      value: `${1} Month`,
    });
    for (let i = 2; i <= 24; i++) {
      months.push({ duration: i, value: `${i} Months` });
    }
    return months;
  };


  const getOffer= async (month)=>{
    const {data:offerData}=await getOfferPM({
      packetId:formData.packetId,
      monthValue:month
    });

    setOffer(offerData);

  }

const isSameDate = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate());
};

  const checkDate=(e)=>{
    const t1=new Date(details.endExpiryDate)
    const t2=new Date();
  return t1>t2
  }

  const changeEndDate=(e)=>{
    setEndDate(e);
    setEndCalendarChng(true);
  }

  return (
    <Box mx={isMobile ? 1 : 3} sx={{ maxWidth: '100%' }}>
      <Typography
        variant="h6"
        sx={{ mb: 2, pt: 2, pl: 1, fontSize: isMobile ? "16px" : "20px" }}
      >
        {formData.serviceName}
      </Typography>

      <Box
        border={1}
        boxShadow={3}
        p={3}
        mb={3}
        mt={3}
        sx={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : 'repeat(5, 1fr)',
          gap: 2,
          borderRadius: 2,
        }}
      >
        {/* First Column/Row */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'space-between',
            alignItems: 'start',
          }}
        >
          <Typography>{formData.typeIdentifier}</Typography>
          <Typography>{formData.status}</Typography>
          <FormControl fullWidth variant="outlined" margin="normal" size="small">
            <InputLabel>Packets</InputLabel>
            <Select
              name="packetId"
              disabled={ checkDate()  && (  role !== "Admin" || !rights?.includes("PacketChange") )} // && 
              value={formData.packetId}
              onChange={handleChange}
            >
              {packets &&
                packets.map((packet, index) => (
                  <MenuItem key={index} value={packet.id}>
                    {packet.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        {/* Second Column/Row */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="body2" sx={{ mb: 1 }}>Add Month</Typography>
          <FormControl fullWidth variant="outlined" margin="normal" size="small">
            <Select
              name="monthDuration"
              value={formData.monthDuration}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflow: "auto",
                  },
                },
              }}
            >
              <MenuItem key={-1} value={0}>
                Select Month
              </MenuItem>
              {monthsList &&
                monthsList.map((month, index) => (
                  <MenuItem key={index} value={month.duration}>
                    {month.value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>

        {/* Third Column/Row */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ mb: 1 }}>Month</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="StartDate"
              value={startDate}
              format="dd-MM-yyyy"
              readOnly
              sx={{ mb: 1 }}
            />
            <DatePicker
              minDate={startDate}
              label="EndDate"
              value={endDate}
              format="dd-MM-yyyy"
              onChange={changeEndDate}
              readOnly={(rights?.includes("CustomDatePayCalendar") || role === "Admin")?false:true}
            />
          </LocalizationProvider>
        </Box>

        {/* Fourth Column/Row */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ mb: 1 }}>Description</Typography>
          <TextField
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            variant="outlined"
            margin="normal"
            multiline
            rows={3}
            size="small"
          />
        </Box>

        {/* Fifth Column/Row */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            align="center"
            fontSize={25}
            fontWeight={"bold"}
            sx={{ textDecoration:offer && offer.newPrice ? "line-through" : "none" }}
          >
            {totalPrice && totalPrice.toFixed(2)}
              </Typography>

              {offer?.offerType == 0 ? (
              <Typography  //discount
                display={offer?.newPrice ? "block" : "none"}
                align="center"
                fontSize={20}
                fontWeight={"bold"}
              >
                {offer?.newPrice || null}
              </Typography>
            ) : 
            offer?.offerType == 1 ?       
            (
              <Typography  //extend
                display={offer?.extensionValue ? "block" : "none"}
                align="center"
                fontSize={20}
                fontWeight={"bold"}
              >
              +{offer?.extensionValue} {offer.extensionType===0?"MONTH" : offer.extensionType===1? "WEEK" :offer.extensionType===2?"DAYS":null }
              </Typography>
            ):
            null
          }
        </Box>
      </Box>
    </Box>
  );
};
export default SingleServicePacket;