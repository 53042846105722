import React, { useState, useEffect } from "react";
import { useGetPacketsForRegionAndServiceMutation } from "features/packets/packetsApiSlice";
import { useGetServiceListQuery } from "features/services/servicesApiSlice";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useGetAutoCompleteUsersMutation } from "features/users/usersApiSlice";
import { useCreateUserPacketMutation } from "features/userPackets/userPacketsApiSlice";
import CreateRadiusForm from "./addServicesForms/CreateRadiusForm";
import CreateStalkerForm from "./addServicesForms/CreateStalkerForm";
import { selectCurrentUser } from "features/auth/authSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CreateExtreamForm from "./addServicesForms/CreateExtreamForm";
import CreateDvbcForm from "./addServicesForms/CreateDvbcForm";

const AddServiceForm = ({ onClose,refetch }) => {
  const { data: servicesList} = useGetServiceListQuery();
  const [getPacketsForRegionAndService] =  useGetPacketsForRegionAndServiceMutation();
  
  const {showNotification}=useServerResponseNotifier();
  const [errors, setErrors] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [packets, setPackets] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [getAutoCompleteUsers] = useGetAutoCompleteUsersMutation();

  const [serviceName, setServiceName] = useState("");
  const [showDetails, setShowDetails] = useState("none");
  const [showCancel, setShowCancel] = useState(true);

  const [formData, setFormData] = useState({
    serviceId: "",
    packetId: "",
    userId: "",
  });

  const getPackets = async ({ serviceId }) => {
    if (selectedUser && serviceId) {
      try {
        const { data: payload } = await getPacketsForRegionAndService({
          regionId: selectedUser.regionId,
          serviceId: serviceId,
        });
        setPackets(payload || []);
      } catch (error) {
        showNotification(error.data.Message,error.data.StatusCode);
      }
    }
  };

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        const { data: newResults } = await getAutoCompleteUsers({
          searchString: searchTerm,
          topRecords: 10,
        });
        setResults(newResults || []);
      } else {
        setResults([]);
      }
    }, 700);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);


  // const validateFields = () => {
  //   const newErrors = {};
  //   if (!formData.serviceId) newErrors.serviceId = "Service is required";
  //   if (!formData.packetId) newErrors.packetId = "Packet is required";
  //   if (!formData.userId) newErrors.userId = "User is required";  
  //   return newErrors;
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "serviceId") {
      if(!formData.userId)
      {
        setErrors({...errors,["userId"]:"User is required."});
        return;
      }

      getPackets({ serviceId: value });
      const selectedService = servicesList.find(
        (service) => service.id === value
      );
      if (selectedService) {
        setServiceName(selectedService.name);
      }
    }

    if(name==="user")
    {
      selectCurrentUser(value);
    }

    if (name === "packetId") {
      setShowDetails("block");
      setShowCancel(false);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const updateErrorSate = (listErrs)=>{
    setErrors(listErrs);
  }
 

  return (
    <Box>
      <Box>
        <Autocomplete
          id="user-search"
          name="users"
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{ width: "100%" }}
          freeSolo
          clearOnEscape={true}
          value={selectedUser}
          onChange={(event, newValue) => {
            if(!newValue) 
              {
                setSelectedUser("");
                setFormData({
                  ...formData,
                  ["userId"]: "",
                });
                return;
              }
            setSelectedUser(newValue);
            setFormData({
              ...formData,
              ["userId"]: newValue.id,
            });

            setErrors({...errors,["userId"]:""});
          }}
          noOptionsText="No users found"
          inputValue={searchTerm} 
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search for user" variant="outlined"
            error={!!errors.userId}  helperText={errors.userId} 
         />
          )}
        />
      </Box>

      <FormControl fullWidth margin="normal">
        <InputLabel id="service-label">Service</InputLabel>
        <Select
          labelId="service-label"
          name="serviceId"
          value={formData.serviceId}
          onChange={handleChange}
          error={!!errors.serviceId}
          helperText={errors.serviceId} 
        >
          {servicesList &&
            servicesList.map((service, index) => (
              <MenuItem key={index} value={service.id}>
                {service.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel id="packet-label">Packets</InputLabel>
        <Select
          labelId="packet-label"
          name="packetId"
          value={formData.packetId}
          onChange={handleChange}
        >
          {packets &&
            packets.map((packet, index) => (
              <MenuItem key={index} value={packet.id}>
                {packet.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Box display={formData.packetId ? "block" : "none"}>
        {serviceName.toLowerCase().includes("radius".toLowerCase()) ||
        serviceName.toLowerCase().includes("internet".toLowerCase()) ? (
          <CreateRadiusForm
            onClose={onClose}
            formData={formData}
            currentUser={selectedUser}
            refetchList={refetch}
            updateErrorSate={updateErrorSate}
          />
        ) : serviceName.toLowerCase().includes("stalker".toLowerCase()) ? (
          <CreateStalkerForm
            onClose={onClose}
            formData={formData}
            currentUser={selectedUser}
            refetchList={refetch}
            updateErrorSate={updateErrorSate}
          />
        ) : serviceName.toLowerCase().includes("extream".toLowerCase()) ||
          serviceName.toLowerCase().includes("code".toLowerCase()) ? (
          <CreateExtreamForm
            onClose={onClose}
            formData={formData}
            currentUser={selectedUser}
            refetchList={refetch}
            updateErrorSate={updateErrorSate}
          />
        ) : serviceName.toLowerCase().includes("dvbc".toLowerCase()) ||
          serviceName.toLowerCase().includes("dvbc".toLowerCase()) ? (
          <CreateDvbcForm
            onClose={onClose}
            formData={formData}
            currentUser={selectedUser}
            refetchList={refetch}
            updateErrorSate={updateErrorSate}
          />
        ) : (
          <p>NOTHING</p>
        )}
      </Box>
      
      {showCancel && (
        <Box
          sx={{
            marginTop: "1em",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AddServiceForm;
