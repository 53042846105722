import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Invoice from './Invoice';
import ThermalInvoice from './ThermalInvoice';
import PrintSelectionModal from './PrintSelectionModal';

const PrintInvoicePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFormat, setSelectedFormat] = useState('');
  const [openModal, setOpenModal] = useState(true);
  const componentRef = useRef();
  
  const { invoiceData } = location.state || {};

  useEffect(() => {
    if (!invoiceData) {
      navigate("/payments");
    }
  }, [invoiceData, navigate]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Invoice',
    onAfterPrint: () => navigate("/payments", { replace: true }),
  });

  const handleCloseModal = (shouldPrint) => {
    setOpenModal(false);
    if (shouldPrint) {
      setTimeout(() => {
        handlePrint();
      }, 100);
    } else {
      navigate("/payments");
    }
  };

  const handlePrintSelection = (format) => {
    setSelectedFormat(format);
    handleCloseModal(true); // Close the modal and trigger the print
  };

  if (!invoiceData) {
    return null;
  }

  return (
    <div>
      <PrintSelectionModal
        open={openModal}
        handleClose={() => handleCloseModal(false)} // Cancel action
        handlePrint={handlePrintSelection} // Print action
      />

      {selectedFormat === 'A4' ? (
        <Invoice ref={componentRef} invoiceData={invoiceData} />
      ) : (
        <ThermalInvoice ref={componentRef} invoiceData={invoiceData} />
      )}
    </div>
  );
};

export default PrintInvoicePage;
