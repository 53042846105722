import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import React from "react";
import CloseAddFooter from "../CloseAddFooter";
import {
  useDeletePurchaseMutation,
  useDeleteSaleMutation,
} from "features/invoices/invoicesApiSlice";
import { Box, Stack, Typography } from "@mui/material";

const DeleteInvoiceForm = ({ onClose, invoice, clearTable, invoiceType }) => {
  const [deletePurchase] = useDeletePurchaseMutation();
  const [deleteSale] = useDeleteSaleMutation();
  const { showNotification } = useServerResponseNotifier();

  const handleSubmit = async () => {
    try {
      if (invoiceType === "Sale") {
        await deleteSale(invoice.id).unwrap();
        showNotification("Purchase Invoice deleted successfully!", 200);
      } else {
        await deletePurchase(invoice.id).unwrap();
        showNotification("Sale Invoice deleted successfully!", 200);
      }

      await clearTable();
    } catch (error) {
      showNotification(error.data.Message, error.data.StatusCode);
    }
    await onClose();
  };
  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack alignItems="center">
          <Typography fontSize="20px">
            Are you sure you want to delete this invoice?
          </Typography>
          <Typography fontSize="20px" fontWeight="bold" pt={"20px"}>
            Code:{invoice.invoiceCode} | Total:{invoice.totalAmount} |
            {invoiceType=="Sale"?"Customer :"+invoice.customerName:"Supplier :"+invoice.supplier}
          </Typography>
        </Stack>
      </Box>

      <CloseAddFooter
        footerLabel={"DELETE"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default DeleteInvoiceForm;
