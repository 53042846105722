import React from "react";

import {IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PaymentIcon from "@mui/icons-material/Payment";
import PaymentsIcon from "@mui/icons-material/Payments";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const UserActions =({viewClick,payClick,editClick,deleteClick,paymentsClick,servicesClick}) => {
  return (
    <>
      <IconButton
        onClick={payClick}
        sx={{ color: "#79ef46" }}
        aria-label="Create Payment"
      >
        <PaymentIcon />
      </IconButton>

      <IconButton
        onClick={paymentsClick}
        sx={{ color: "#19BC7D" }}
        aria-label="See payments"
      >
        
        <PaymentsIcon />
      </IconButton>

      <IconButton
        onClick={servicesClick}
        sx={{ color: "#0DE4E1" }}
        aria-label="See services"
      >
        <MiscellaneousServicesIcon />
      </IconButton>

      <IconButton
        onClick={editClick}
        sx={{ color: "#EAD623" }}
        aria-label="edit"
      >
        <EditIcon />
      </IconButton>

      <IconButton
        onClick={deleteClick}
        sx={{ color: "#ff0000" }}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    </>
  );
};

export default UserActions;
