import { apiSlice } from "app/api/apiSlice";

export const invoicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createPurchase: build.mutation({
      query: (request) => ({
        url: "invoice/purchase",
        method: "POST",
        body: request,
      }),
    }),

    updatePurchase: build.mutation({
      query: ({ id, request }) => ({
        url: `invoice/purchase/${id}`,
        method: "PUT",
        body: request,
      }),
    }),

    getPurchasesPagination: build.query({
      query: (args) => {
        let requestBody = {
          fromSearchAll: false,
          pageNumber: args.pageNumber,
          pageSize: args.pageSize,
          filters: [],
        };

        if (args.filters && args.filters.length > 0) {
          requestBody.filters = args.filters;
        }

        return {
          url: "invoice/get-all/purchase/list",
          method: "POST",
          body: requestBody,
        };
      },
    }),
    getPurchase: build.query({
      query: (id) => `invoice/purchase/${id}`,
    }),

    deletePurchase: build.mutation({
      query: (id) => ({
        url: `invoice/purchase/${id}`,
        method: "DELETE"
       }),
    }),

    //saleee

    createSale: build.mutation({
      query: (request) => ({
        url: "invoice/sale",
        method: "POST",
        body: request,
      }),
    }),

    updateSale: build.mutation({
      query: ({ id, request }) => ({
        url: `invoice/sale/${id}`,
        method: "PUT",
        body: request,
      }),
    }),

    getSalesPagination: build.query({
      query: (args) => {
        let requestBody = {
          fromSearchAll: false,
          pageNumber: args.pageNumber,
          pageSize: args.pageSize,
          filters: [],
        };

        if (args.filters && args.filters.length > 0) {
          requestBody.filters = args.filters;
        }

        return {
          url: "invoice/get-all/sale/list",
          method: "POST",
          body: requestBody,
        };
      },
    }),
    getSale: build.query({
      query: (id) => `invoice/sale/${id}`,
    }),

    deleteSale: build.mutation({
      query: (id) => ({
        url: `invoice/sale/${id}`,
        method: "DELETE"
       }),
    }),


  }),
});

export const {
  useCreatePurchaseMutation,
  useGetPurchasesPaginationQuery,
  useGetPurchaseQuery,
  useUpdatePurchaseMutation,
  useDeletePurchaseMutation,

  useCreateSaleMutation,
  useGetSalesPaginationQuery,
  useGetSaleQuery,
  useUpdateSaleMutation,
  useDeleteSaleMutation
} = invoicesApiSlice;
