import { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import { useServerResponseNotifier } from 'features/context/ServerResponseNotifierContext ';

export function ServerResponseNotifier() {
  const { notification, hideNotification } = useServerResponseNotifier();

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        hideNotification();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [notification, hideNotification]);

  if (!notification) return null;

  let color = 'green';
  let Icon = CheckCircleIcon;
  if (notification.statusCode >= 400 && notification.statusCode < 500) {
    color = 'orange';
    Icon = WarningIcon;
  }
  if (notification.statusCode >= 500) {
    color = 'red';
    Icon = ErrorIcon;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(notification)}
      onClose={hideNotification}
      message={
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.2em', textAlign: 'center' }}>
          <Icon style={{ marginRight: '8px' }} />
          {notification.message}
        </div>
      }
      autoHideDuration={3000}
      ContentProps={{
        style: {
          backgroundColor: color,
          opacity:0.7,
          width: '400px', // custom width
          height: '100px', // custom height
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
    />
  );
}
