import React from "react";
import { Box, Button, TextField } from "@mui/material";

const EditCancelFooter = ({ onClose, onClick }) => {
  return (
    <Box
      sx={{
        marginTop: "1em",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Button variant="outlined" color="secondary" onClick={onClose}>
        Cancel
      </Button>

      <Button variant="contained" color="primary" onClick={onClick}>
        Edit
      </Button>
    </Box>
  );
};

export default EditCancelFooter;
