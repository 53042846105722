import React, { useEffect, useState } from "react";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { useGetPacketsForRegionAndServiceMutation } from "features/packets/packetsApiSlice";
import { useGetServiceListQuery } from "features/services/servicesApiSlice";
import { useGetUserPacketsDetailsQuery } from "features/userPackets/userPacketsApiSlice";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import EditRadiusForm from "./EditRadiusForm";
import EditStalkerForm from "./EditStalkerForm";
import EditExtreamForm from "./EditExtreamForm";
import EditDvbcForm from "./EditDvbcForm";

const EditAddService = ({ userPacketId, userPacket, onClose, refetchList }) => {
  const [packets, setPackets] = useState([]);
  const [username, setUsername] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [radiusData, setRadiusData] = useState([]);
  const [stalkerData, setStalkerData] = useState([]);
  const [extreamData, setExtreamData] = useState([]);
  const [dvbcData, setDvbcData] = useState([]);

  const {
    data: dataForUserPacket,
    isLoading,
    refetch: refetchDetails,
  } = useGetUserPacketsDetailsQuery(userPacketId);

  const [getPacketsForRegionAndService] =
    useGetPacketsForRegionAndServiceMutation();
  const { showNotification } = useServerResponseNotifier();

  const [formData, setFormData] = useState({
    serviceId: "",
    packetId: "",
    userId: "",
    regionId: "",
    description:"",
  });

  useEffect(() => {

    if (!isLoading) {
      setUsername(dataForUserPacket.clientName);
      setServiceName(dataForUserPacket.serviceName);
      setFormData({
        ...formData,
        serviceId: dataForUserPacket.serviceId || "",
        packetId: dataForUserPacket.packetId || "",
        userId: dataForUserPacket.userId || "",
        regionId: dataForUserPacket.regionId || "",
        description: dataForUserPacket.description || "",
      });

      if (dataForUserPacket.serviceId && dataForUserPacket.regionId) {
        (async () => {
          try {
            const { data: payload } = await getPacketsForRegionAndService({
              serviceId: dataForUserPacket.serviceId,
              regionId: dataForUserPacket.regionId,
            });
            setPackets(payload || []);
          } catch (error) {
            showNotification(error.data.Message, error.data.StatusCode);
          }
        })();
      }

      setRadiusData(dataForUserPacket.radiusData || "");
      setStalkerData(dataForUserPacket.stalkerData || "");
      setExtreamData(dataForUserPacket.extreamData || "");
      setDvbcData(dataForUserPacket.dvbcData || "");
    }
  }, [dataForUserPacket, isLoading, userPacketId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

  };

  return (
    <Box>
      <TextField
        fullWidth
        disabled={true}
        label="Username"
        margin="normal"
        name="clientname"
        value={username}
      />

      <TextField
        fullWidth
        disabled={true}
        label="Service"
        margin="normal"
        name="serviceName"
        value={serviceName}
      />

      <FormControl fullWidth margin="normal">
        <InputLabel id="packet-label">Packets</InputLabel>
        <Select
          labelId="packet-label"
          name="packetId"
          value={formData.packetId}
          onChange={handleChange}
          clientname={formData.clientName}
        >
          {packets &&
            packets.map((packet, index) => (
              <MenuItem key={index} value={packet.id}>
                {packet.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Box>
        {(!isLoading &&
          serviceName.toLowerCase().includes("radius".toLowerCase())) ||
        serviceName.toLowerCase().includes("internet".toLowerCase()) ? (
          <EditRadiusForm
            userPacketId={userPacketId}
            onClose={onClose}
            formData={formData}
            refetchList={refetchList}
            refetchDetails={refetchDetails}
            radiusData={radiusData}
          />
        ) : serviceName.toLowerCase().includes("stalker".toLowerCase()) ? (
          <EditStalkerForm
            userPacketId={userPacketId}
            onClose={onClose}
            formData={formData}
            refetchList={refetchList}
            refetchDetails={refetchDetails}
            stalkerData={stalkerData}
          />
        ) : serviceName.toLowerCase().includes("code".toLowerCase()) ||
          serviceName.toLowerCase().includes("extream".toLowerCase()) ? (
          <EditExtreamForm
            userPacketId={userPacketId}
            onClose={onClose}
            formData={formData}
            refetchList={refetchList}
            refetchDetails={refetchDetails}
            extreamData={extreamData}
          />
        ) : serviceName.toLowerCase().includes("dvbc".toLowerCase()) ||
          serviceName.toLowerCase().includes("dvbc".toLowerCase()) ? (
          <EditDvbcForm
            userPacketId={userPacketId}
            onClose={onClose}
            formData={formData}
            refetchList={refetchList}
            refetchDetails={refetchDetails}
            dvbcData={dvbcData}
          />
        ) : (
          <p>NOTHING</p>
        )}
      </Box>
    </Box>
  );
};

export default EditAddService;
