import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { useGetUnpaidPaymentsQuery } from "features/payments/paymentsApiSlice";
import { useNavigate } from "react-router-dom";
import { getClaimByKey, getUserFromClaims } from "utils/jwtUtils";
import { id } from "date-fns/locale";

const UnpaidPayments = ({ userId,onClose ,services, addToParent,removeFromParent, addToBasket, removeFromBasket}) => {

  const {data:unpaidList}=useGetUnpaidPaymentsQuery(userId);
  const [isPaid, setIsPaid] = useState(true);
  const role = getClaimByKey("role");

  const { showNotification } = useServerResponseNotifier();
  const navigate = useNavigate();

const [selectedIds, setSelectedIds] = useState([]);
const handleSelectionChange = (newSelection) => {    
  //     
    //const selectedData=unpaidList.find( e=>e.id ===newSelection);
  setSelectedIds(newSelection);
//  console.log(selectedIds);

};

const previousSelectedIdsRef = useRef([]);

useEffect(() => {
  const removedIds = previousSelectedIdsRef.current.filter(
    (id) => !selectedIds.includes(id)
  );

  // For each removed ID, call the removeFromParent function
  removedIds.forEach((id) => {
    removeFromParent(id);
  });

  // Update the previousSelectedIds ref with the current selectedIds
  previousSelectedIdsRef.current = selectedIds;

  // Your existing logic for added IDs
  selectedIds.forEach((id) => {
    let payment = unpaidList.find((e) => e.id === id);
    
    addToParent({
      ...payment,
    });
  });
}, [selectedIds]);

  const columns = [
    { field: "id", headerName: "Id", width: 10 ,flex:1 },
    { field: "packetName", headerName: "Packet", width: 100 },
    { field: "month", headerName: "Month", width: 50 },
    { field: "total", headerName: "Total", width: 80 },
    { field: "period", headerName: "Period", width: 200 },
  ];

  return (
    <Box>
      <Box textAlign="center">
        <Typography
          variant="h6"
          sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
        >
          Unpaid
        </Typography>
      </Box>
      <DataGrid
        rows={unpaidList || []}
        columns={columns}
        onRowSelectionModelChange={handleSelectionChange}
        checkboxSelection
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        columnVisibilityModel={{
          id: false,
        }}
      />
    </Box>
  );
};

export default UnpaidPayments;
