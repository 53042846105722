import { useCreateItemMutation, useGetItemQuery, useUpdateItemMutation } from "features/items/itemsApiSlice";
import React, { useEffect, useState } from "react";
import CloseAddFooter from "../CloseAddFooter";
import {
    TextField,
    Box
  } from "@mui/material";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import FlexBetween from "components/FlexBetween";

const EditItemForm = ({ onClose, refetch, itemId }) => {
  const { showNotification } = useServerResponseNotifier();
  const [errors, setErrors] = useState({});
  const [createItem, { isLoading, isError, isSuccess }] =useCreateItemMutation();

  const [currentItem, setCurrentItem] = useState(null);
  const [updateItem] = useUpdateItemMutation();

  const [formData, setFormData] = useState({
    name: "",
    sellPrice: "",
    buyPrice: "",
    description: "",
  });

  const {
    data: itemDetails,
    isLoading: isItemLoading,
    refetch: refetchesItem,
  } = useGetItemQuery(itemId);


  useEffect(() => {
    if (!isItemLoading) {
      setCurrentItem(itemDetails);
      setFormData({
        ...formData,
        name: itemDetails.name || "",
        sellPrice: itemDetails.sellPrice || 0,
        buyPrice: itemDetails.buyPrice || 0,
        description: itemDetails.description || "",
      });
    }
  }, [itemDetails, isItemLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
        await updateItem({ id: currentItem.id, request: formData }).unwrap()
        showNotification("Item updated successfully!", 200);
        onClose();
        refetch();
      } catch (error) {
        showNotification(error.data.Message, error.data.StatusCode);
      }
  };

  useEffect(() => {
    refetchesItem();
  }, [itemId]);


  const validateFields = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.sellPrice) newErrors.sellPrice = "Sell price is required";
    if (!formData.buyPrice) newErrors.buyPrice = "Buy price is required";  
    return newErrors;
  };

  return (
    <Box component="form">
      <TextField
        fullWidth
        label="Name"
        margin="normal"
        name="name"
        value={formData.name}
        onChange={handleChange}
        error={!!errors.name}
        helperText={errors.name} 
   
      />
      <FlexBetween>
        <TextField
          label="Sell Price"
          margin="normal"
          name="sellPrice"
          value={formData.sellPrice}
          onChange={handleChange}
          type="number"
          inputProps={{ min: 0 }}
          error={!!errors.sellPrice}
          helperText={errors.sellPrice}
      
        />
        <TextField
          label="Buy Price"
          margin="normal"
          name="buyPrice"
          value={formData.buyPrice}
          onChange={handleChange}
          type="number"
          inputProps={{ min: 0 }}
          error={!!errors.buyPrice}
          helperText={errors.buyPrice}
     
        />
      </FlexBetween>

      <TextField
        fullWidth
        label="Description"
        margin="normal"
        name="description"
        value={formData.description}
        onChange={handleChange}
        multiline
        minRows={4}
        sx={{
          "& .MuiInputBase-root": {
            height: "100px",
          },
        }}
      />

      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default EditItemForm;
