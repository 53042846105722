import { createSlice } from "@reduxjs/toolkit";

const authSlice=createSlice({
    name:'auth',
    initialState: {user:null , token:null,mode:"dark"},
    reducers:{
        setCredentials:(state,action)=>{
            const {user,accessToken}=action.payload;
            state.user=user
            state.token=accessToken
            localStorage.setItem('accessToken', action.payload.accessToken);
            localStorage.setItem('refreshToken', action.payload.refreshToken);
      
        },
        logOut: (state,action)=>{
            state.user=null
            state.token=null
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        },
        setMode: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light"
        }
    },

})

export const {setCredentials,logOut,setMode}=authSlice.actions;
export default authSlice.reducer

export const selectCurrentUser=(state)=>state.auth.user
export const selectCurrentToken=(state)=>state.auth.token