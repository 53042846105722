import { apiSlice } from "app/api/apiSlice";

export const packetsApiSlice = apiSlice.injectEndpoints({
  endpoints: build => ({
    getPacketList: build.query({
      query: () => "packet/list",
    }),
    createPacket: build.mutation({
      query: (request) => ({
        url: "packet",
        method: "POST",
        body: request,
      }),
    }),
    getPacketsForRegionAndService: build.mutation({
      query: (request) => ({
        url: "packet/list-for-region",
        method: "POST",
        body: request,
      }),
    }),
    getPacketById: build.query({
      query: (id) => `packet/${id}`,
    }),
    getExtreamPacketById: build.mutation({
      query: (id) => `packet/extream/${id}`,
    }),
    updatePacket: build.mutation({
      query: ({ id, request }) => ({
        url: `packet/${id}`,
        method: "PUT",
        body: request,
      }),
    }),

    getPacketsPagination: build.query({
      query: (args) => {
        let requestBody = {
          fromSearchAll: false,
          pageNumber: args.pageNumber,
          pageSize: args.pageSize,
          filters: []
        };

        if (args.filters && args.filters.length > 0) {
          requestBody.filters = args.filters;
        }

        return {
          url: 'packet/get-all/list',
          method: 'POST',
          body: requestBody
        };
      },
    }),

    getActiveUsersForPacketId: build.query({
      query: (id) => `packet/active-users/${id}`,
    }),

    getPacketsForServiceId: build.query({
      query: (id) => `packet/service/${id}`,
    }),


    deletePacket: build.mutation({
      query: (request) => ({
        url: `packet/delete`,
        method: "POST",
        body: request
      }),
    }),
  })
});

export const {
  useGetPacketListQuery,
  useCreatePacketMutation,
  useGetPacketsForRegionAndServiceMutation,
  useGetPacketByIdQuery,
  useGetExtreamPacketByIdMutation,
  useUpdatePacketMutation,
  useGetPacketsPaginationQuery,
  useGetActiveUsersForPacketIdQuery,
  useGetPacketsForServiceIdQuery,
  useDeletePacketMutation,
} = packetsApiSlice;