import { apiSlice } from "app/api/apiSlice";

export const customersApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCustomer: build.query({
      query: (id) => `customer/${id}`,
    }),

    createCustomer: build.mutation({
      query: (request) => ({
        url: "customer",
        method: "POST",
        body: request,
      }),
    }),

    getAutoCompleteCustomers: build.mutation({
      query: (request) => ({
        url: "customer/autocomplete",
        method: "POST",
        body: request,
      }),
    }),

    updateCustomer: build.mutation({
      query: ({ id, request }) => ({
        url: `customer/${id}`,
        method: "PUT",
        body: request,
      }),
    }),

    getCustomersPagination: build.query({
      query: (args) => {
        let requestBody = {
          fromSearchAll: false,
          pageNumber: args.pageNumber,
          pageSize: args.pageSize,
          filters: [],
        };

        if (args.filters && args.filters.length > 0) {
          requestBody.filters = args.filters;
        }

        return {
          url: "customer/get-all/list",
          method: "POST",
          body: requestBody,
        };
      },
    }),

    deleteCustomer: build.mutation({
      query: (id) => ({
        url: `customer/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCustomerQuery,
  useCreateCustomerMutation,
  useGetAutoCompleteCustomersMutation,
  useUpdateCustomerMutation,
  useGetCustomersPaginationQuery,
  useDeleteCustomerMutation,
} = customersApiSlice;
