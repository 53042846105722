import React, { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CloseAddFooter from "../CloseAddFooter";
import { useUpdateUPExtreamMutation } from "features/userPackets/userPacketsApiSlice";


const EditExtreamForm = ({
    onClose,
    formData,
    refetchList,
    extreamData,
    userPacketId,
    refetchDetails,
  }) => {

  const [updateExtreamUser] = useUpdateUPExtreamMutation();
  const { showNotification } = useServerResponseNotifier();
  const [errors, setErrors] = useState({});

  const [extream, setExtream] = useState({
    username: extreamData?.username || "",
    password: extreamData?.password|| "1234",
    macAddress: extreamData?.macAddress || "",
    description: formData?.description || "",
  });

  useEffect(() => {
    setExtream({
      username: extreamData?.username || "",
      macAddress: extreamData?.macAddress || "",
      password: extreamData?.password|| "1234",
      description: formData?.description || "",
    });
  }, [extreamData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if(name==="macAddress")
    {
      let newValue=value;
      newValue=newValue.toUpperCase();
      newValue = newValue.replace(/:/g, '');
      newValue = newValue.slice(0, 12);
      newValue = newValue.match(/.{1,2}/g)?.join(':') || '';
      setExtream((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
      return;
    }
    
    setExtream((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const validateFields = () => {
    const newErrors = {};
    if (!extream.username) newErrors.username = "Username is required";
    if (!extream.macAddress) {
      newErrors.macAddress = "MAC Address is required";
    } else {
      const macAddressRegex = /^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/;
      if (!macAddressRegex.test(extream.macAddress)) {
        newErrors.macAddress = "Invalid MAC Address format. Use format XX:XX:XX:XX:XX:XX";
      }
    }
    return newErrors;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const updatedData = {
      ...formData,
      ...extream,
    };
    console.log(updatedData);
    await updateExtreamUser({
      id: userPacketId,
      request: updatedData,
    }).unwrap()
      .then(() => {
        showNotification("Service for user updated successfully!", 200);
        refetchDetails();
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetchList();
  };


  return (
    <Box paddingTop="20px">
    <TextField
      fullWidth
      value={extream.username}
      label="Username"
      margin="normal"
      name="username"
      onChange={handleChange}
      error={!!errors.username}
      helperText={errors.username}
    />

<TextField
        fullWidth
        value={extream.password}
        defaultChecked="1234"
        label="Password"
        margin="normal"
        name="password"
        variant="outlined" 
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
      />
    <TextField
      fullWidth
      label="MAC"
      margin="normal"
      name="macAddress"
      value={extream.macAddress}
      onChange={handleChange}
      InputLabelProps={{ shrink: true }}
      error={!!errors.macAddress}
      helperText={errors.macAddress}
    />
    <TextField
      fullWidth
      label="Description"
      margin="normal"
      name="Description"
      value={extream.description}
      onChange={handleChange}
      InputLabelProps={{ shrink: true }}
    />

    <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
  </Box>
  )
}

export default EditExtreamForm