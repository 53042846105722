import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
} from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { useDeleteCustomerMutation } from "features/customers/customersApiSlice";

const DeleteCustomerForm = ({ onClose,customer, refetch }) => {
  const [deleteCustomer]=useDeleteCustomerMutation();
  const { showNotification } = useServerResponseNotifier();

  const handleSubmit=async ()=>{
    
    try {
        await deleteCustomer(customer.id).unwrap();
        showNotification("Customer deleted successfully!", 200);
        await onClose();
        await refetch();
      } catch (error) {
        showNotification(error.data.Message, error.data.StatusCode);
      }
  };
  

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack alignItems="center">
          <Typography fontSize="20px">
            Are you sure you want to delete this customer?
          </Typography>
          <Typography fontSize="20px" fontWeight="bold" pt={"20px"}>
            {customer.name}
          </Typography>
        </Stack>
      </Box>

      <CloseAddFooter
        footerLabel={"DELETE"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default DeleteCustomerForm;
