import React from 'react'
import { Box,Button,TextField } from '@mui/material';

const CloseAddFooter = ({onClose,onClick , footerLabel}) => {
  return (
    <Box
    sx={{
      marginTop: "1em",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <Button variant="contained" color="secondary" onClick={()=>{
      onClose();
    }}>
      Cancel
    </Button>

    <Button
      variant="contained"
      onClick={onClick}
      color="secondary"
    >
      {footerLabel ? `${footerLabel}` : 'ADD'}
    </Button>
  </Box>
  )
}

export default CloseAddFooter