import React, { useState } from "react";
import {
  TextField,
  Box,
} from "@mui/material";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CloseAddFooter from "./CloseAddFooter";
import FlexBetween from "components/FlexBetween";
import { useCreateItemMutation } from "features/items/itemsApiSlice";

const ItemForm = ({ onClose, refetch }) => {
    const { showNotification } = useServerResponseNotifier();

    const [formData, setFormData] = useState({
      name: "",
      sellPrice: "",
      buyPrice: "",
      description: "",
    });
  
    const [errors, setErrors] = useState({});
    const [createItem] =useCreateItemMutation();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });

      setErrors({
        ...errors,
        [name]: "",
      });
    };

    const validateFields = () => {
      const newErrors = {};
      if (!formData.name) newErrors.name = "Name is required";
      if (!formData.sellPrice) newErrors.sellPrice = "Sell price is required";
      if (!formData.buyPrice) newErrors.buyPrice = "Buy price is required";  
      return newErrors;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const newErrors = validateFields();

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
      
      try {
        await createItem(formData).unwrap();
        showNotification("Item created successfully!", 200);
        onClose();
        refetch();
      } catch (error) {
        showNotification(error.data.Message, error.data.StatusCode);
      }
    };
  
  return (
    <Box component="form">
      <TextField
        fullWidth
        label="Name"
        margin="normal"
        name="name"
        value={formData.name}
        onChange={handleChange}
        error={!!errors.name}
        helperText={errors.name} 
   
      />
      <FlexBetween>
        <TextField
          label="Sell Price"
          margin="normal"
          name="sellPrice"
          value={formData.sellPrice}
          onChange={handleChange}
          type="number"
          inputProps={{ min: 0 }}
          error={!!errors.sellPrice}
          helperText={errors.sellPrice}
      
        />
        <TextField
          label="Buy Price"
          margin="normal"
          name="buyPrice"
          value={formData.buyPrice}
          onChange={handleChange}
          type="number"
          inputProps={{ min: 0 }}
          error={!!errors.buyPrice}
          helperText={errors.buyPrice}
     
        />
      </FlexBetween>

      <TextField
        fullWidth
        label="Description"
        margin="normal"
        name="description"
        value={formData.description}
        onChange={handleChange}
        multiline
        minRows={4}
        sx={{
          "& .MuiInputBase-root": {
            height: "100px",
          },
        }}
      />

      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default ItemForm;
