import React, { useEffect, useState } from "react";
import DashboardLayout from "components/dashboard/DashboarLayout";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PublicIcon from "@mui/icons-material/Public";
import PeopleIcon from "@mui/icons-material/People";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { Grid, MenuItem, Select } from "@mui/material";
import { useGetDashboardQuery } from "features/operators/operatorsApiSlice";
import { useGetAutocompleteOperatorsListQuery } from "features/autocomplete/autocompleteApiSlice";

const OperatorDashboard = () => {

  const {data:dashboardResult, isLoading: isDashLoading}=useGetDashboardQuery();

  const [data,setData]=useState({
    balance:0,
    userCount:0,
    regionCount:0,
    packetCount:0,
    servicesCount:0
  });

  useEffect(()=>{
    if(!isDashLoading)
    {
      setData({
        ...data,
      balance:dashboardResult?.balance||0,
      userCount:dashboardResult.userCount||0,
      regionCount:dashboardResult.regionCount||0,
      packetCount:dashboardResult.packetCount||0,
      servicesCount:dashboardResult.servicesCount||0
    })
    }
  },[dashboardResult])

  return (
    <Grid
      container
      spacing={0.5}
      paddingLeft={5}
      pt={0}
      paddingRight={5}
      borderRadius={"15px"}
      fontSize={"1.7rem"}
      minWidth={"200px"}
    >
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <DashboardLayout
            title={"Balance"}
            value={ (  
              new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2, // Adjust as needed
          }).format(data.balance).toString()+" ALL")
      }
            iconComponent={
              <AccountBalanceWalletIcon sx={{ fontSize: "2.5rem" }} />
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <DashboardLayout
          title={"User"}
          value={data.userCount}
          iconComponent={<PeopleIcon sx={{ fontSize: "2.5rem" }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <DashboardLayout
          title={"Regions"}
          value={data.regionCount}
          iconComponent={<PublicIcon sx={{ fontSize: "2.5rem" }} />}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <DashboardLayout
          title={"Services"}
          value={data.servicesCount}
          iconComponent={
            <MiscellaneousServicesIcon sx={{ fontSize: "2.5rem" }} />
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <DashboardLayout
          title={"Packets"}
          value={data.packetCount}
          iconComponent={<MailOutlineIcon sx={{ fontSize: "2.5rem" }} />}
        />
      </Grid>
    </Grid>
  );
};

export default OperatorDashboard;
