import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import TableComp from "components/TableComp";
import { useGetPaymentsForAdminQuery, useGetPrintDetailsQuery } from "features/payments/paymentsApiSlice";
import PaginationTable from "components/PaginationTable";
import PaymentHeader from "./PaymentHeader";
import { useLocation, useNavigate } from "react-router-dom";
import OverlayForm from "components/OrverlayForm";
import DeletePaymentForm from "components/forms/deleteForms/DeletePaymentForm";
import { getClaimByKey } from "utils/jwtUtils";
import PaymentsActions from "components/forms/actions/PaymentsActions";


const Payments = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const [payment,setPayment]=useState(null);
  const [deleteForm, setDeleteForm] = useState(false);
  const toggleDeleteForm=()=>setDeleteForm(!deleteForm);

  const navigate = useNavigate();

  const location=useLocation();
  const role = getClaimByKey("role");
  const rights = getClaimByKey("Rights");
  const [paymentIdForPrint, setPaymentIdForPrint] = useState(null); // Track the payment ID to print

  const { data: printDetails, error, isLoadingPrint } = useGetPrintDetailsQuery(paymentIdForPrint, { skip: !paymentIdForPrint,// Skip the query if no payment ID is selected
  });

  useEffect(() => {
    if (location.state?.filterUser) {
      let newFilters = [];
      const fil = location.state?.filterUser;
  
      newFilters.push({
        columnName: fil.columnName,
        value: fil.value,
        operation: 0,
      });
  
      setFilters(newFilters);
    }
  }, [location.state?.filterUser]); 
  

  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useGetPaymentsForAdminQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    refetchData({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize, filters]);


  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.5,
    },
    {
      field: "datePaid",
      headerName: "Date Paid",
      flex: 0.5,
    },
    {
      field: "clientName",
      headerName: "Client",
      flex: 0.5,
    },
    {
      field: "typeIdentifier",
      headerName: "Type Identifier",
      flex: 0.5,
    },
    {
      field: "monthsPaid",
      headerName: "Months",
      flex: 0.2,
    },
    {
      field: "periodPaid",
      headerName: "Period Paid",
      flex: 0.9,
    },
    {
      field: "packetName",
      headerName: "Packet",
      flex: 0.5,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 0.3,
    },
    {
      field: "isPaid",
      headerName: "Paid",
      flex: 0.2,
    },
    {
      field: "operator",
      headerName: "Operator",
      flex: 0.3,
    },
    {
      sortable: false,
      width: 100,
      align: "right",
  
      renderCell: (params) => {
        const deleteClick = () => {
          toggleDeleteForm();
          setPayment(params.row);
        };

        const printClick = () => {
          setPaymentIdForPrint(params.row.id); // Set the payment ID to fetch print details
        };
  
        return (
          <PaymentsActions role={role} rights={rights} deleteClick={deleteClick} printClick={printClick}  />
        );
    }
  }
  ];

  useEffect(() => {
    if (printDetails && !isLoadingPrint) {
      const invoiceData = {
        name: printDetails.clientName,
        address: printDetails.address || "N/A",
        phone: printDetails.phone || "N/A",
        paymentDate: printDetails.paymentDate,
        totalPayment: printDetails.totalAmount,
        operatorName: printDetails.operatorName,
        items: printDetails.payments,
        note: printDetails.note || "",
      };

      navigate("/print", { state: { invoiceData } });
    }
  }, [printDetails, isLoading, navigate]);

  
  return (
    <Box m="1.5rem 2.5rem">
      <PaymentHeader
        refetch={refetchData}
        pageNumber={pageNumber}
        pageSize={pageSize} 
        setFilters={setFilters}
        totalAmount={tableData.totalAmount}
        selectedUserFromState={location.state?.filterUser.value}
      />
      <PaginationTable
        title={"Payments"}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize} 
        fixedMinWith={"1300px"}
      />



{deleteForm && (
        <OverlayForm
          formComponent={
            <DeletePaymentForm
              payment={payment}
              onClose={toggleDeleteForm}
              refetch={refetchData}
            />
          }
          formTitle={"DELETE PAYMENT"}
        />
      )}
    </Box>
  );
};

export default Payments;
