import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  Switch,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import {
  useGetOperatorDetailsQuery,
  useUpdateOperatorCredentialsMutation,
  useUpdateOperatorMutation,
} from "features/operators/operatorsApiSlice";
import { useGetRegionListQuery } from "features/regions/regionsApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { useGetAllRightsQuery } from "features/operatorRights/operatorRightsApiSlice";
import CustomMenuItem from "components/styled/CustomMenuItem";

const EditOperatorForm = ({ operatorId, onClose, refetch }) => {
  const {
    data,
    isLoading,
    refetch: refetchCurrentOperator,
  } = useGetOperatorDetailsQuery(operatorId);

  const { data: regionList } = useGetRegionListQuery();
  const { data: rightsList } = useGetAllRightsQuery();

  const [errors, setErrors] = useState({});
  const [updateOperator] = useUpdateOperatorMutation();
  const [updateOperatorCredentials] = useUpdateOperatorCredentialsMutation();

  const { showNotification } = useServerResponseNotifier();
  const [editRegion, setEditRegion] = useState(false);
  const [editCredentials, setEditCredentials] = useState(false);
  const handleRegionToggle = () => {
    setEditRegion(!editRegion);
  };

  const handleCredentialsToggle = () => {
    setEditCredentials(!editCredentials);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    regionIds: [],
    address: "",
    email: "",
    password: "",
    confirmPassword: "",
    rightsIds: [],
  });

  const [authFormData, setAuthFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (!isLoading) {
      setFormData({
        ...formData,
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        mobile: data.mobile || "",
        regionIds: data.regionIds || [],
        email: data.email || "",
        address: data.address || "",
        password: data.password || "",
        confirmPassword: data.password || "",
        rightsIds: data.rightsIds || [],
      });

      setAuthFormData({
        email: data.email || "",
        password: data.password || "",
        confirmPassword: data.password || "",
      });
    }
  }, [data, isLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    await updateOperator({ id: operatorId, request: formData })
      .unwrap()
      .then((payload) => {
        showNotification("Updated successfully!", 200);
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    if (editCredentials) {
      await updateAuthOpeartor();
    }

    await refetch();
    onClose();
  };

  const updateAuthOpeartor = async () => {
    if (editCredentials) {
      setAuthFormData({
        email: formData?.email || "",
        password: formData?.password || "",
        confirmPassword: formData?.password || "",
      });

      await updateOperatorCredentials({ id: operatorId, request: authFormData })
        .unwrap()
        .then((payload) => {
          console.log("AUTH Operator updated:", payload);
          showNotification("Operator updated succesfully", 200);
        })
        .catch((error) => {
          console.error("Failed to update auth operator:", error);
          showNotification(error.data.Message, error.data.StatusCode);
        });
    }
  };

  const validateFields = () => {
    const newErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!authFormData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(authFormData.email)) {
      newErrors.email = "Invalid email format";
    }

    const passwordRegex = /^(?=.*[0-9])(?=.{8,})/;
    if (!authFormData.password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(authFormData.password)) {
      newErrors.password =
        "Password must be at least 8 characters, contain at least one digit";
    }

    if (!authFormData.confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
    } else if (authFormData.password !== authFormData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.regionIds || formData.regionIds.length < 1)
      newErrors.regionIds = "At least one selected zone";

    return newErrors;
  };

  const handleAuthChange = (e) => {
    const { name, value } = e.target;
    setAuthFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    let error = "";

    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      error = emailRegex.test(value) ? "" : "Invalid email format";
    }

    if (name === "password") {
      const passwordRegex = /^(?=.*[0-9])(?=.{8,})/;
      error = passwordRegex.test(value)
        ? ""
        : "Password must be at least 8 characters, contain at least one digit and one special character";
    }

    if (name === "confirmPassword") {
      error = authFormData.password === value ? "" : "Passwords do not match";
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "rightsCheckBox") {
      console.log(rightsList);
      console.log(value);
      const right = rightsList.find((r) => r.id == value);
      if (right) handleRightsChange(right.id, checked);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleRightsChange = (rightId, isChecked) => {
    setFormData((prevFormData) => {
      const newRightsIds = isChecked
        ? [...prevFormData.rightsIds, rightId]
        : prevFormData.rightsIds.filter((id) => id !== rightId);

      return { ...prevFormData, rightsIds: newRightsIds };
    });
  };
  useEffect(() => {
    refetchCurrentOperator();
  }, [operatorId]);

  if (!isLoading)
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ width: "30%", margin: "0.5%" }}>
            <Typography sx={{ fontSize: 18, fontWeight: "bold", pb: "10px" }}>
              Opereator Information
            </Typography>
            <TextField
              fullWidth
              label="First Name"
              margin="normal"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
            <TextField
              fullWidth
              label="Last Name"
              margin="normal"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
            <Box>
              <FormControl fullWidth margin="normal">
                <InputLabel id="region-label">
                  Region/s
                  <Switch checked={editRegion} onChange={handleRegionToggle} />
                </InputLabel>
                <Select
                  labelId="region-label"
                  id="region"
                  multiple
                  value={formData.regionIds || []}
                  onChange={handleChange}
                  name="regionIds"
                  disabled={!editRegion}
                  error={!!errors.regionIds}
                  helperText={errors.regionIds}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "400px",
                        overflow: "auto",
                      },
                    },
                  }}
                >
                  {regionList &&
                    regionList.map((region, index) => (
                      <CustomMenuItem key={index} value={region.id}>
                        {region.name}
                      </CustomMenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <TextField
              fullWidth
              label="Address"
              margin="normal"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              label="Mobile"
              margin="normal"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
            />
          </Box>

          <Box sx={{ width: "30%", margin: "0.5%" }}>
            <Typography sx={{ fontSize: 18, fontWeight: "bold", pb: "10px" }}>
              Opereator Credentitals
            </Typography>

            <Switch
              checked={editCredentials}
              onChange={handleCredentialsToggle}
            />
            <TextField
              fullWidth
              label="Email"
              margin="normal"
              name="email"
              required
              value={authFormData.email}
              onChange={handleAuthChange}
              disabled={!editCredentials}
              error={!!errors.email}
              helperText={errors.email}
            />

            <TextField
              fullWidth
              required
              label="Password"
              margin="normal"
              name="password"
              type="password"
              value={authFormData.password}
              onChange={handleAuthChange}
              disabled={!editCredentials}
              error={!!errors.password}
              helperText={errors.password}
            />

            <TextField
              fullWidth
              required
              label="Confirm Password"
              margin="normal"
              name="confirmPassword"
              type="password"
              value={authFormData.confirmPassword}
              onChange={handleAuthChange}
              disabled={!editCredentials}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
            />
          </Box>

          {/* Rights */}
          <Box sx={{ width: "30%", margin: "0.5%" }}>
            <Typography sx={{ fontSize: 18, fontWeight: "bold", pb: "10px" }}>
              Opereator Rights
            </Typography>
            <FormControl component="fieldset" variant="standard">
              <FormGroup>
                {rightsList &&
                  rightsList.map((right) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formData.rightsIds.includes(right.id)}
                          onChange={handleChange}
                          name="rightsCheckBox"
                          value={right.id}
                          sx={{
                            color: "lightgray",
                            "&.Mui-checked": {
                              color: "limegreen",
                            },
                          }}
                        />
                      }
                      label={right.description}
                      key={right.id}
                      //value={right.id}
                      sx={{ my: 1 }}
                    />
                  ))}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
        <CloseAddFooter
          onClose={onClose}
          onClick={handleSubmit}
          footerLabel={"EDIT"}
        />
      </Box>
    );
};

export default EditOperatorForm;
