import React, { useEffect, useState } from "react";
import { Box, Stack, Typography,FormControl,Select,MenuItem, InputLabel} from "@mui/material";

import CloseAddFooter from "../CloseAddFooter";
import { useDeletePacketMutation, useGetActiveUsersForPacketIdQuery, useGetPacketsForServiceIdQuery } from "features/packets/packetsApiSlice";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";


const DeletePacketForm = ({ onClose, packet, refetch }) => {
  const [formData, setFormData] = useState({
    currentPacketId: packet.id,
    newPacketId: null,
  });

  const { data: packets, isLoading } = useGetPacketsForServiceIdQuery(packet.serviceId);
  const { showNotification } = useServerResponseNotifier();
  const [deletePacket]=useDeletePacketMutation();


  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!await isValid()) return;
    await deletePacket({
      currentPacketId: formData.currentPacketId,
      newPacketId: formData.newPacketId,
    })
      .unwrap()
      .then(() => {
        showNotification("Packet deleted successfully", 200);
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetch();
  };

  const isValid= async(e)=>{
    if((formData.newPacketId === null || formData.newPacketId===0 ||
      formData.currentPacketId===null || formData.currentPacketId===0) && packet.numberOfUsers>0
    )
    {
      showNotification("PACKET cannot be empty !", 500);
      return false;
    }
      return true;
  }


  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack alignItems="center">
          <Typography fontSize="20px">
            Are you sure you want to delete this packet?
          </Typography>
          <Typography fontSize="20px" fontWeight="bold" pt={"20px"}>
            {packet.name}
          </Typography>

          <Box display={packet.numberOfUsers>0 ? "block" : "none"} pt={"15px"}>
            <Typography>This packet has {packet.numberOfUsers} users</Typography>
            <Box
          display={packet.numberOfUsers > 0 ? "flex" : "none"}
          alignItems="center"
          justifyContent="space-between"
          pt={"20px"}
        >
         <Typography sx={{ fontSize: "20px", pt: "40px" }}>
          Select a packet you want to transfer the users.
        </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
              textDecoration: "line-through",
            }}
          >
            {packet.name}
          </Typography>

          <TrendingFlatIcon sx={{ fontSize: "60px" }} />

          <FormControl sx={{ m: 0, minWidth: 150, fontSize: "20px" }}>
            <InputLabel id="packet-label">Packet</InputLabel>
            <Select
              labelId="packet-label"
              name="newPacketId"
              onChange={handleChange}
              sx={{ fontSize: "20px" }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "300px",
                    overflow: "auto",
                  },
                },
              }}
            >
              {packets &&
                packets
                  .filter((reg) => reg.id !== packet.id)
                  .map((reg, index) => (
                    <MenuItem key={index} value={reg.id}>
                      {reg.name}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
      </Box>
          </Box>
        </Stack>
      </Box>

      <CloseAddFooter
        footerLabel={"DELETE"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default DeletePacketForm;
