import CustomerAction from 'components/forms/actions/CustomerAction';
import CustomersForm from 'components/forms/CustomerForm';
import OverlayForm from 'components/OrverlayForm';
import PaginationTable from 'components/PaginationTable';
import { useGetCustomersPaginationQuery } from 'features/customers/customersApiSlice';
import React, { useEffect, useState } from 'react'
import {
  TextField,
  Box,
} from "@mui/material";
import EditCustomerForm from 'components/forms/editForms/EditCustomerForm';
import DeleteCustomerForm from 'components/forms/deleteForms/DeleteCustomerForm';
import AddNewHeader from 'components/forms/headers/AddNewHeader';
const Customer = () => {
    const [showForm, setShowForm] = useState(false);
    const [deleteForm, setDeleteForm] = useState(false);
    const toggleDeleteForm = () => setDeleteForm(!deleteForm);
  
    const [currentCustomer, setCurrentCustomer] = useState("");
    const [editForm, setEditForm] = useState(false);
    const openEditForm = () => setEditForm(true);
    const closeEditForm = () => setEditForm(false);
  
    const handleOpenForm = () => setShowForm(true);
    const handleCloseForm = () => setShowForm(false);
  
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [tableData, setTableData] = useState([]);
    const [filters, setFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const {
      data,
      isLoading,
      refetch: refetchData,
    } = useGetCustomersPaginationQuery({
      pageNumber: pageNumber + 1,
      pageSize,
      filters: filters,
    });
  
    useEffect(() => {
      if (data) {
        setTableData(data);
      }
    }, [data]);
  
    useEffect(() => {
      refetchData({ pageNumber, pageSize, filters });
    }, [pageNumber, pageSize, filters]);
  
    const handlePaginationModelChange = (model) => {
      setPageNumber(model.page);
      setPageSize(model.pageSize);
    };
  
    const columns = [
      {
        field: "id",
        headerName: "ID",
        flex: 0.5,
      },
      {
        field: "name",
        headerName: "Name",
        flex: 0.5,
      },
      {
        field: "address",
        headerName: "Address",
        flex: 0.5,
      },
      {
        field: "phoneNumber",
        headerName: "Phone Number",
        flex: 0.5,
      },
  
      {
        field: "email",
        headerName: "EMAIL",
        flex: 0.5,
      },
      {
        field: "dateCreated",
        headerName: "Date Created",
        flex: 0.5,
      },
      {
        field: "actions",
        headerName: "ACTIONS",
        sortable: false,
        width: 200,
        align: "right",
        headerAlign: "center",
        renderCell: (params) => {
          const editClick = () => {
            setCurrentCustomer(params);
            openEditForm();
          };
  
          const deleteClick = () => {
            toggleDeleteForm();
            setCurrentCustomer(params.row);
          };
  
          return (
            <CustomerAction
              editClick={editClick}
              deleteClick={deleteClick}
            />
          );
        },
      },
    ];

    useEffect(() => {
      const timerId = setTimeout(async () => {
        if (searchTerm) {
          let newFilters = [];
          newFilters.push({
            columnName: "Name",
            value: `${searchTerm}`,
            operation: 0,
          });
          setFilters(newFilters);
        } else {
          setFilters([]);
        }
      }, 800);
      return () => {
        clearTimeout(timerId);
      };
    }, [searchTerm]);
  
    return (
      <Box m="1.5rem 2.5rem">
        <AddNewHeader openForm={handleOpenForm} title={"Customers"} />
        <TextField
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          label="Search Customer"
          variant="outlined"
          size="small"
          name="name"
        />
        <PaginationTable
          handleOpenForm={handleOpenForm}
          data={tableData.data}
          isLoading={isLoading}
          columns={columns}
          onPageChange={handlePaginationModelChange}
          totalCount={tableData.totalCount}
          currentPage={pageNumber}
          pageSize={pageSize}
          fixedMinWith={"700px"}
        />

        {showForm && (
          <OverlayForm
            onClose={handleCloseForm}
            formComponent={
              <CustomersForm onClose={handleCloseForm} refetch={refetchData} />
            }
          />
        )}

        {editForm && (
          <OverlayForm
            formComponent={
              <EditCustomerForm
                customerId={currentCustomer.id}
                onClose={closeEditForm}
                refetch={refetchData}
              />
            }
            formTitle={"Edit customer"}
          />
        )}

        {deleteForm && (
          <OverlayForm
            formComponent={
              <DeleteCustomerForm
                customer={currentCustomer}
                onClose={toggleDeleteForm}
                refetch={refetchData}
              />
            }
            formTitle={"DELETE PACKET"}
          />
        )}
      </Box>
    );
  };

export default Customer