import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: 'rgba(0, 123, 255, 0.5)', // Change this color to your preferred selection color
    '&:hover': {
      backgroundColor: 'rgba(0, 123, 255, 0.7)', // Change this color for hover effect on selected item
    },
  },
}));

export default CustomMenuItem;
