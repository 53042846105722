import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FlexBetween from "components/FlexBetween";

const InvoiceBtnHeaders = ({
  printClick,
  searchClick,
  clearClick,
  deleteClick,
  processClick,
  invoiceType,
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid #ccc"
    >
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        p={2}
        //borderBottom="1px solid #ccc"
      >
        <Box>
          <Button
            variant="contained"
            color="inherit"
            startIcon={<PrintIcon />}
            style={{ marginRight: "8px", width: "100px" }}
            onClick={printClick}
          >
            PRINT
          </Button>
          <Button
            variant="contained"
            color="info"
            startIcon={<SearchIcon />}
            style={{ marginRight: "8px", width: "100px" }}
            onClick={searchClick}
          >
            SEARCH
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ClearIcon />}
            style={{ marginRight: "8px", width: "100px" }}
            onClick={clearClick}
          >
            CLEAR
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            style={{ marginRight: "8px", width: "100px" }}
            onClick={deleteClick}
          >
            DELETE
          </Button>
          <Button
            variant="contained"
            color="success"
            startIcon={<CheckCircleIcon />}
            style={{ marginRight: "8px", width: "100px" }}
            onClick={processClick}
          >
            PROCESS
          </Button>
        </Box>
      </Box>
      <Box>
        <Typography
          variant="h2"
          color={theme.palette.secondary[100]}
          fontWeight="bold"
          sx={{ pr: "20px" }}
        >
          {invoiceType}
        </Typography>
      </Box>
    </Box>
  );
};

export default InvoiceBtnHeaders;
