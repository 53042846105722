import React, { useEffect } from "react";
import { useState } from "react";
import { Box, useTheme } from "@mui/material";

import UsersForm from "components/forms/UserForm";
import OverlayForm from "components/OrverlayForm";
import { useGetUsersPaginationQuery } from "features/users/usersApiSlice";
import UserActions from "components/forms/actions/UserActions";
import EditUserForm from "components/forms/editForms/EditUserForm";
import UsersHeader from "./UsersHeader";
import PaginationTable from "components/PaginationTable";
import { useNavigate } from "react-router-dom";
import DeleteUserForm from "components/forms/deleteForms/DeleteUserForm";

const Users = () => {
  const [showForm, setShowForm] = useState(false);
  const [deleteForm, setDeleteForm] = useState(false);
  const toggleDeleteForm = () => setDeleteForm(!deleteForm);

  const [currentUser, setCurrentUser] = useState("");
  const [editForm, setEditForm] = useState(false);
  const toggleEditForm = () => setEditForm(!editForm);

  // const openEditForm = () => setEditForm(true);
  // const closeEditForm = () => setEditForm(false);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useGetUsersPaginationQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    refetchData({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize, filters]);

  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "regionName",
      headerName: "Region",
      flex: 0.5,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 0.5,
    },

    {
      field: "email",
      headerName: "EMAIL",
      flex: 0.5,
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 200,
      align: "right",
      headerAlign: "center",
      renderCell: (params) => {
        const editClick = () => {
          setCurrentUser(params);
          //openEditForm();
          toggleEditForm();
        };

        const deleteClick = () => {
          toggleDeleteForm();
          setCurrentUser(params.row);
        };

        const payClick = () => {
          navigate(`/payments/add-payment/${params.id}`);
        };

        const paymentsClick = () => {
          navigate(`/payments`, {
            state: {
              filterUser: {
                columnName: "ClientName",
                value: `${params.row.name}`,
                operation: 0,
              },
            },
          });
        };

        const servicesClick = () => {
          navigate(`/services/add-service`, {
            state: {
              filterUser: {
                columnName: "ClientId",
                value: `${params.id}`,
                userValue: `${params.row.name}`,
                operation: 3,
              },
            },
          });
        };

        return (
          <UserActions
            servicesClick={servicesClick}
            paymentsClick={paymentsClick}
            editClick={editClick}
            payClick={payClick}
            deleteClick={deleteClick}
          />
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <UsersHeader
        handleOpenForm={handleOpenForm}
        refetch={refetchData}
        pageNumber={pageNumber}
        pageSize={pageSize}
        setFilters={setFilters}
      />
      <PaginationTable
        handleOpenForm={handleOpenForm}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
        fixedMinWith={"700px"}
      />

      {showForm && (
        <OverlayForm
          onClose={handleCloseForm}
          formComponent={
            <UsersForm onClose={handleCloseForm} refetch={refetchData} />
          }
        />
      )}

      {editForm && (
        <OverlayForm
          formComponent={
            <EditUserForm
              userId={currentUser.id}
              onClose={toggleEditForm}
              refetch={refetchData}
            />
          }
          formTitle={"Edit user"}
        />
      )}

      {deleteForm && (
        <OverlayForm
          formComponent={
            <DeleteUserForm
              user={currentUser}
              onClose={toggleDeleteForm}
              refetch={refetchData}
            />
          }
          formTitle={"DELETE PACKET"}
        />
      )}
    </Box>
  );
};

export default Users;
