import React from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import CloseAddFooter from 'components/forms/CloseAddFooter';
import { useServerResponseNotifier } from 'features/context/ServerResponseNotifierContext ';
import { useCreateWithdrawalMutation } from 'features/withdrawals/withdrawalsApiSlice';

const ConfirmationWithdrawal = ({ operator, withdrawalAmount, refetchList, onClose,clearSeletected }) => {
    const { showNotification } = useServerResponseNotifier();
    const [createWithdrawal]=useCreateWithdrawalMutation();

  const currentBalance = operator?.balance ?? 0;
  const formattedWithdrawalAmount = withdrawalAmount ?? 0;
  const newBalance = currentBalance - formattedWithdrawalAmount;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(withdrawalAmount==0)
    {
      await onClose();
      return;
    }

    await createWithdrawal({operatorId:operator.id,Amount:withdrawalAmount}).unwrap()
    .then(showNotification("Withdrawal made succesfully!",200))
    .catch((error) => {
      showNotification(error.data.Message,error.data.StatusCode)
    });

    await onClose();
    await refetchList();
    await clearSeletected();
  };
  
  return (
    <Box>
      <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', fontSize: '1.5rem' }}>
          Operator: {operator?.name ?? 'N/A'}
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, fontSize: '1.25rem' }}>
          Current Balance: {operator?.balance.toLocaleString() ?? '0'} ALL
        </Typography>
        <Typography variant="body1" sx={{ mb: 1, fontSize: '1.25rem' }}>
          Withdrawal Amount: {withdrawalAmount.toLocaleString()} ALL
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
          New Balance: {newBalance.toLocaleString()} ALL
        </Typography>
      </Box>

      <CloseAddFooter
        footerLabel="PROCEED"
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default ConfirmationWithdrawal;