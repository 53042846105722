import { apiSlice } from "app/api/apiSlice";


export const offesApiSlice=apiSlice.injectEndpoints({
    endpoints: build =>({
        createDiscount: build.mutation({
            query: (request) => ({
              url: `offer/discount`,
              method: "POST",
              body: request,
            }),
          }),
          createExtend: build.mutation({
            query: (request) => ({
              url: `offer/extend`,
              method: "POST",
              body: request,
            }),
          }),
          getOfferList: build.query({
            query: () => `offer/list`,
          }),
          getOfferById: build.query({
            query: (id) => `offer/${id}`,
          }),

          updateOffer: build.mutation({
            query: ({id,request})=>({
              url: `offer/${id}`,
              method: "PUT",
              body: request,
            }),
          }),

          getOfferPM: build.mutation({
            query: (request)=>({
              url: `offer/pm`,
              method: "POST",
              body: request,
            }),
          }),


          deleteOffer: build.mutation({
            query: (id) => ({
              url: `offer/${id}`,
              method: "DELETE"
             }),
          }),
    })
})

export const {
   useCreateDiscountMutation,
   useCreateExtendMutation,
   useGetOfferListQuery,
   useGetOfferByIdQuery,
   useUpdateOfferMutation,
   useGetOfferPMMutation,
   useDeleteOfferMutation,
}=offesApiSlice;