import React, { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import AddServiceForm from "components/forms/AddServiceForm";
import OverlayForm from "components/OrverlayForm";
import AddServiceActions from "components/forms/actions/AddServiceActions";
import TableComp from "components/TableComp";
import { useGetUserPacketsForAdminQuery } from "features/userPackets/userPacketsApiSlice";
import EditAddService from "components/forms/editForms/EditAddService";
import PaginationTable from "components/PaginationTable";
import PaymentHeader from "scenes/payments/PaymentHeader";
import AddServiceHeader from "./AddServiceHeader";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteUserForm from "components/forms/deleteForms/DeleteUserForm";
import DeleteUserPacketsForm from "components/forms/deleteForms/DeleteUserPacketsForm";

const AddService = () => {

  const navigate=useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const [showForm, setShowForm] = useState(false);
  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);
  const [editForm, setEditForm] = useState(false);
  const openEditForm = () => setEditForm(true);
  const closeEditForm = () => setEditForm(false);
  const [deleteForm, setDeleteForm] = useState(false);
  const toggleDeleteForm = () => setDeleteForm(!deleteForm);

  const [userPacket, setUserPacket] = useState("");

  const location=useLocation();

  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useGetUserPacketsForAdminQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    if (location.state?.filterUser) {
      let newFilters = [];
      const fil = location.state?.filterUser;
  
      newFilters.push({
        columnName: fil.columnName,
        value: fil.value,
        operation: 3,
      });
  
      setFilters(newFilters);
    }
  }, [location.state?.filterUser]);
  

  useEffect(() => {
    refetchData({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize, filters]);

  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 0.5,
    },
    {
      field: "service",
      headerName: "Service",
      flex: 0.5,
    },
    {
      field: "client",
      headerName: "Client",
      flex: 0.5,
    },
    {
      field: "packetName",
      headerName: "Packet",
      flex: 0.5,
    },
    {
      field: "typeIdentifier",
      headerName: "Type Identifier",
      flex: 0.5,
    },
    {
      field: "expiry",
      headerName: "Expiry",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      align: "right",
      headerAlign: "right",
      renderCell: (params) => {

        const editClick = () => {
          setUserPacket(params);
          openEditForm();
        };

        const deleteClick = () => {
          toggleDeleteForm();
          setUserPacket(params.row);
        };

        const payClick=()=>
        {
          navigate(`/payments/add-payment/${params.row.clientId}`);
        }
        return (
          <AddServiceActions editClick={editClick} payClick={payClick} deleteClick={deleteClick} />
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <AddServiceHeader
        handleOpenForm={handleOpenForm}
        refetch={refetchData}
        pageNumber={pageNumber}
        pageSize={pageSize} // new prop
        setFilters={setFilters}
        searchTearm={location.state?.filterUser.userValue}
      />
      <PaginationTable
        handleOpenForm={handleOpenForm}
        title={"Payments"}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
        fixedMinWith={"1200px"}
      />
      {/* <TableComp
      handleOpenForm={handleOpenForm}
      title={"Services for users"}
      subtitle={"add service"}
      data={tableData}
      isLoading={isLoading}
      columns={columns}
      /> */}
      {showForm && (
        <OverlayForm
          onClose={handleCloseForm}
          formComponent={
            <AddServiceForm onClose={handleCloseForm} refetch={refetchData} />
          }
        />
      )}
      {editForm && (
        <OverlayForm
          formComponent={
            <EditAddService
              userPacketId={userPacket.id}
              onClose={closeEditForm}
              refetchList={refetchData}
            />
          }
          formTitle={"Edit service"}
        />
      )}

  {deleteForm && (
        <OverlayForm
          formComponent={
            <DeleteUserPacketsForm
              userPacket={userPacket}
              onClose={toggleDeleteForm}
              refetch={refetchData}
            />
          }
          formTitle={"DELETE SERVICE"}
        />
      )}
    </Box>
  );
};

export default AddService;
