import React, { useEffect, useState } from "react";
import DashboardLayout from "components/dashboard/DashboarLayout";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PublicIcon from "@mui/icons-material/Public";
import PeopleIcon from "@mui/icons-material/People";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EngineeringIcon from "@mui/icons-material/Engineering";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import { Grid, MenuItem, Select, InputLabel, FormControl } from "@mui/material";
import { useGetAutocompleteOperatorsListQuery } from "features/autocomplete/autocompleteApiSlice";
import {
  useGetDashboardQuery,
  useGetOperatorDashboardMutation,
} from "features/operators/operatorsApiSlice";

const AdminDashboard = () => {
  const [selectedOperator, setSelectedOperator] = useState("");
  const [data, setData] = useState({
    balance: 0,
    userCount: 0,
    regionCount: 0,
    packetCount: 0,
    offerCount: 0,
    operatorCount: 0,
    servicesCount: 0,
  });

  const { data: operators, isLoading: isOperatorLoading } =
    useGetAutocompleteOperatorsListQuery();
  const { data: dashboardResult, isLoading: isDashLoading } =
    useGetDashboardQuery();

  const [getOperatorDashboard] = useGetOperatorDashboardMutation();

  useEffect(() => {
    if (!isDashLoading) {
      setData({
        ...data,
        balance: dashboardResult?.balance || 0,
        userCount: dashboardResult?.userCount || 0,
        regionCount: dashboardResult?.regionCount || 0,
        packetCount: dashboardResult?.packetCount || 0,
        offerCount: dashboardResult?.offerCount || 0,
        operatorCount: dashboardResult?.operatorCount || 0,
        servicesCount: dashboardResult?.servicesCount || 0,
      });
    }
  }, [dashboardResult]);

  useEffect(() => {
    if (selectedOperator) {
      (async () => {
        const { data: resultOperator, isLoading: isResultLoading } =
          await getOperatorDashboard(selectedOperator);
        if (!isResultLoading) {
          setData({
            ...data,
            balance: resultOperator.balance || 0,
            userCount: resultOperator.userCount || 0,
            regionCount: resultOperator.regionCount || 0,
            packetCount: resultOperator.packetCount || 0,
            servicesCount: resultOperator.servicesCount || 0,
          });
        }
      })();
    }
  }, [selectedOperator]);

  const handleOperatorChange = (event) => {
    setSelectedOperator(event.target.value);
  };

  return (
    <Grid
      container
      spacing={5}
      paddingLeft={5}
      pt={0}
      paddingRight={5}
      borderRadius={"15px"}
      fontSize={"1.7rem"}
      minWidth={"200px"}
    >
      <Grid item xs={12} container justifyContent="flex-end">
        <FormControl>
          <InputLabel id="sel-op">Select Operator</InputLabel>
          <Select
            value={selectedOperator}
            onChange={handleOperatorChange}
            labelId="sel-op"
            style={{ width: "150px", height: "30px", marginTop: "10px" }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "400px",
                  overflow: "auto",
                },
              },
            }}
          >
            {operators &&
              operators.map((operator, index) => (
                <MenuItem key={index} value={operator.id}>
                  {operator.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <DashboardLayout
            title={"Balance"}
            value={ (  
              new Intl.NumberFormat('en-US', {
              style: 'decimal',
              minimumFractionDigits: 2, // Adjust as needed
          }).format(data.balance).toString()+" ALL")
      }
            iconComponent={
              <AccountBalanceWalletIcon sx={{ fontSize: "2.5rem" }} />
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <DashboardLayout
          title={"User"}
          value={data.userCount}
          iconComponent={<PeopleIcon sx={{ fontSize: "2.5rem" }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DashboardLayout
          title={"Regions"}
          value={data.regionCount}
          iconComponent={<PublicIcon sx={{ fontSize: "2.5rem" }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DashboardLayout
          title={"Services"}
          value={data.servicesCount}
          iconComponent={
            <MiscellaneousServicesIcon sx={{ fontSize: "2.5rem" }} />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DashboardLayout
          title={"Offers"}
          value={data.offerCount}
          iconComponent={<LocalOfferIcon sx={{ fontSize: "2.5rem" }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DashboardLayout
          title={"Operators"}
          value={data.operatorCount}
          iconComponent={<EngineeringIcon sx={{ fontSize: "2.5rem" }} />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DashboardLayout
          title={"Packets"}
          value={data.packetCount}
          iconComponent={<MailOutlineIcon sx={{ fontSize: "2.5rem" }} />}
        />
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
