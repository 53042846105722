import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useCreateDvbcUserMutation } from "features/userPackets/userPacketsApiSlice";

const CreateDvbcForm = ({ onClose, formData, currentUser, refetchList,updateErrorSate }) => {
  const { showNotification } = useServerResponseNotifier();
  const [createDvbcUser] = useCreateDvbcUserMutation();
  const [formatSerial,setFormatSerial]=useState(null);
  const [dvbcData, setDvbcData] = useState({
    serialNumber: "",
  });

  const [errors, setErrors] = useState({});
  const [dataForUpdate,setDataUpdate]=useState(null)

  const formatSerialNumber = (value) => {
    let newValue = value.replace(/\D/g, "");
    newValue = newValue.slice(0, 10);

    if (newValue.length > 3) newValue = newValue.slice(0, 3) + "-" + newValue.slice(3);
    if (newValue.length > 7) newValue = newValue.slice(0, 7) + "-" + newValue.slice(7);
    if (newValue.length > 11) newValue = newValue.slice(0, 11) + "-" + newValue.slice(11);

    return newValue;
  };

  const handleChange = async (e) => {
  const { name, value } = e.target;
   const formattedValue = formatSerialNumber(value);
   setFormatSerial(formattedValue);
    setDvbcData({
      ...dvbcData,
      [name]:  formattedValue.replace(/\D/g, ""),
    });
    setDataUpdate({
      ...dataForUpdate,
      [name]:value,
    });
  };

  useEffect(() => {
    setDataUpdate({
      ...formData,
      ...dvbcData,
    });
  }, [formData,currentUser]);

  const validateFields = () => {
    const newErrors = {};
    if(!validateParentFields()) newErrors.parent = "From ParentComp"
    if (!dataForUpdate.serialNumber) newErrors.serialNumber = "Serial Number is required";
    return newErrors;
  };

  const validateParentFields=()=>{
    const newErrors = {};
    if (!dataForUpdate.serviceId) newErrors.serviceId = "Service is required";
    if (!dataForUpdate.packetId) newErrors.packetId = "Packet is required";
    if (!dataForUpdate.userId) newErrors.userId = "User is required";
    
    if (Object.keys(newErrors).length > 0) {
      updateErrorSate(newErrors);
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    await createDvbcUser(dataForUpdate)
      .unwrap()
      .then(showNotification("Service for user created succesfully!", 200))
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetchList();
  };

  return (
    <Box paddingTop="20px">
      <TextField
        fullWidth
        label="Serial Number"
        margin="normal"
        name="serialNumber"
        value={formatSerial}
        onChange={handleChange}
        error={!!errors.serialNumber}
        helperText={errors.serialNumber} 
      />
      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};


export default CreateDvbcForm;
