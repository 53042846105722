import React from 'react';
import './thermal-print.css'; // You will create specific styles for the thermal printer here
import profileImage from "assets/init100.png";

const ThermalInvoice = React.forwardRef((props, ref) => {
  const { invoiceData } = props;

  return (
    <div ref={ref} className="thermal-invoice-container">
      <div className="thermal-invoice-header">
        <div className="thermal-invoice-top">
        <img src={profileImage} alt="not"></img>
          <h2>SOLVENDA</h2>
        </div>
        <div className="thermal-invoice-details">
          <p><strong>Emri:</strong> {invoiceData.name}</p>
          <p><strong>Adresa:</strong> {invoiceData.address}</p>
          <p><strong>Telefoni:</strong> {invoiceData.phone || "___________"}</p>
          <p><strong>Data pageses:</strong> {invoiceData.paymentDate}</p>
        </div>
      </div>

      <table className="thermal-invoice-table">
        <thead>
          <tr>
            <th>Nga</th>
            <th>Deri</th>
            <th>Përshkrimi</th>
            <th>Vlera</th>
          </tr>
        </thead>
        <tbody>
          {invoiceData.items && invoiceData.items.map((item, index) => (
            <tr key={index}>
              <td>{item.dateStart}</td>
              <td>{item.dateEnd}</td>
              <td>{item.description}</td>
              <td>{item.totalAmount.toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td colSpan="3" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total:</td>
            <td style={{ fontWeight: 'bold' }}>{invoiceData.totalPayment}</td>
          </tr>
        </tbody>
      </table>

      <div className="thermal-invoice-footer">
        <p><strong>Dorezoi:</strong> {invoiceData.operatorName}</p>
        <p><strong>SHENIM:</strong> {invoiceData.note}</p>
        <p><strong>SOLVENDA | solvenda.org</strong></p>
      </div>
    </div>
  );
});

export default ThermalInvoice;
