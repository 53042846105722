import React from "react";
import Header from "components/Header";
import { Box, useTheme, IconButton, TextField } from "@mui/material";
import OverlayForm from "components/OrverlayForm";
import { useState, useEffect } from "react";
import RegionForm from "components/forms/RegionForm";
import AddNewHeader from "components/forms/headers/AddNewHeader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableComp from "components/TableComp";
import EditOverlay from "components/overlays/EditOverlay";
import EditRegionForm from "components/forms/editForms/EditRegionForm";
import PaginationTable from "components/PaginationTable";
import { useGetRegionsPaginationQuery } from "features/regions/regionsApiSlice";
import DeleteRegionForm from "components/forms/deleteForms/DeleteRegionForm";

const Regions = () => {
  const [region, setRegion] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [editForm, setEditForm] = useState(false);
  const [deleteForm, setDeleteForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const openEditForm = () => setEditForm(true);
  const closeEditForm = () => setEditForm(false);
  const toggleDeleteForm=()=>setDeleteForm(!deleteForm);
  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  const {
    data,
    isLoading,
    refetch: refetchList,
  } = useGetRegionsPaginationQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    refetchList({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize]);

  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  const handleFilter = async (e) => {
    const { name, value } = e.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        let newFilters = [];
        newFilters.push({
          columnName: "Name",
          value: `${searchTerm}`,
          operation: 0,
        });
        setFilters(newFilters);
      } else {
        setFilters([]);
      }
    }, 700);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "numberOfUsers",
      headerName: "Number of Users",
      flex: 0.3,
    },
    {
      field: "dateCreated",
      headerName: "DateCreated",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      align: "right",
      // headerAlign: "right",
      renderCell: (params) => {
        const editClick = () => {
          openEditForm();
          setRegion(params);
        };

        const deleteClick = () => {
          toggleDeleteForm();
          setRegion(params);
        };

        return (
          <>
            <IconButton
              onClick={editClick}
              sx={{ color: "#EAD623" }}
              aria-label="edit"
            >
              <EditIcon />
            </IconButton>

            <IconButton
              onClick={deleteClick}
              sx={{ color: "#ff0000" }}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <AddNewHeader openForm={handleOpenForm} title={"Regions"} />

      <TextField
        sx={{
          height: "32px",
          margin: "0",
          ".MuiOutlinedInput-input": { padding: "8px" },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={handleFilter}
        label="Search Region"
        margin="normal"
        name="name"
      />

      <PaginationTable
        handleOpenForm={handleOpenForm}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
        fixedMinWith={"500px"}
      />

      {showForm && (
        <OverlayForm
          onClose={handleCloseForm}
          formComponent={
            <RegionForm
              onClose={handleCloseForm}
              refetchRegions={refetchList}
            />
          }
        />
      )}

      {editForm && (
        <OverlayForm
          formComponent={
            <EditRegionForm
              region={region}
              onClose={closeEditForm}
              refetch={refetchList}
            />
          }
          formTitle={"Edit region"}
        />
      )}


   {deleteForm && (
        <OverlayForm
          formComponent={
            <DeleteRegionForm
              region={region}
              onClose={toggleDeleteForm}
              refetch={refetchList}
            />
          }
          formTitle={"DELETE REGION"}
        />
      )}
    </Box>
  );
};

export default Regions;
