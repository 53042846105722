import { apiSlice } from "app/api/apiSlice";


export const operatorRightsApiSlice=apiSlice.injectEndpoints({
        endpoints: build =>({
            getRightsForOperatorId: build.query({
                query: (operatorId) => `operatorrights/rights/${operatorId}`,
              }),

              getAllRights: build.query({
                query: () => `operatorrights/rights`,
              }),
        })
    });

export const{useGetRightsForOperatorIdQuery,useGetAllRightsQuery}= operatorRightsApiSlice