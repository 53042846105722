import { apiSlice } from "app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `user/${id}`,
    }),
    getUserList: build.query({
      query: () => "user/list",
    }),
    createUser: build.mutation({
      query: (request) => ({
        url: "user",
        method: "POST",
        body: request,
      }),
    }),
    getAutoCompleteUsers: build.mutation({
      query: (request) => ({
        url: "user/autocomplete",
        method: "POST",
        body: request,
      }),
    }),
    // getAutoCompleteUsersAdmin: build.mutation({
    //   query: (request) => ({
    //     url: "user/autocomplete",
    //     method: "POST",
    //     body: request,
    //   }),
    // }),
    updateUser: build.mutation({
      query: ({ id, request }) => ({
        url: `user/${id}`,
        method: "PUT",
        body: request,
      }),
    }),

    getUsersPagination: build.query({
      query: (args) => {
          let requestBody = {
              fromSearchAll: false,
              pageNumber: args.pageNumber,
              pageSize: args.pageSize,
              filters: []
          };

          if (args.filters && args.filters.length >0) {
              requestBody.filters = args.filters;
          }
  
          return {
              url: 'user/get-all/list',
              method: 'POST',
              body: requestBody
          };
      },
  }),

  getUserPartial: build.query({
    query: (id) => `user/partial-details/${id}`,
  }),

  deleteUser: build.mutation({
    query: (id) => ({
      url: `user/${id}`,
      method: "DELETE"
     }),
  }),

  }),
});

export const {
  useGetUserQuery,
  useGetUserListQuery,
  useCreateUserMutation,
  useGetAutoCompleteUsersMutation,
  useUpdateUserMutation,
  useGetUsersPaginationQuery,
  //  useGetAutoCompleteUsersAdminMutation,
  useGetUserPartialQuery,
  useDeleteUserMutation,
} = usersApiSlice;
