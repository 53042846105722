import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import CloseAddFooter from "components/forms/CloseAddFooter";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import {
  useCreatePaymentMutation,
  useLiquidatePaymentMutation,
} from "features/payments/paymentsApiSlice";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getClaimByKey, getUserFromClaims } from "utils/jwtUtils";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { formatISO } from "date-fns";
import { useGetUserQuery } from "features/users/usersApiSlice";
import PrintSelectionModal from "components/printer/PrintSelectionModal";

const PaymentForm = ({
  services,
  onClose,
  user,
  unpaidServices,
  hasUnpaidPayments,
  setShowUnpaidForm,
}) => {
  const [createPayment] = useCreatePaymentMutation();
  const [liquidatePayment] = useLiquidatePaymentMutation();
  const [isPaid, setIsPaid] = useState(true);
  const [isZero, setIsZero] = useState(false);
  const togglePaid = () => setIsPaid(!isPaid);
  const toggleZero = () => setIsZero(!isZero);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState("A4");
  const [loading, setLoading] = useState(false);

  const role = getClaimByKey("role");
  const rights = getClaimByKey("Rights");

  const { data: userDetails, isLoading: isUserLoading } = useGetUserQuery(
    user.id
  );
  const { showNotification } = useServerResponseNotifier();
  const navigate = useNavigate();

  const currentOperator = getUserFromClaims();

  const subtotalForUnpaid = unpaidServices.reduce(
    (sum, unpaidS) => sum + unpaidS.total,
    0
  );

  const subtotal =
    services.reduce(
      (sum, service) => sum + service.packetPrice * service.monthValue,
      0
    ) + subtotalForUnpaid;

  const subtotalWithOffer = services.reduce(
    (sum, service) => sum + service.totalPrice,
    0
  );

  let total = subtotalWithOffer + subtotalForUnpaid;

  const formatDate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }

    return dd + "/" + mm + "/" + yyyy;
  };

  const getDescriptionForPrint = (serviceName) => {
    if (serviceName.toLowerCase().includes("radius".toLowerCase()))
      return "Parapagim per internet";
    return "Parapagim per TV";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return; 
    setLoading(true);

    const paymentDetails = [];
    const createPaymentList = [];

    services.forEach(async (data) => {
      const paymentDetail = {
        dateStart: formatDate(data.expiryStart),
        dateEnd: formatDate(data.expiryEnd),
        description: getDescriptionForPrint(data.serviceName) || "",
        totalAmount: isZero ? 0 : data.totalPrice,
        typeIdentifier: data.typeIdentifier || "",
        isPaid: isPaid,
      };
      paymentDetails.push(paymentDetail);

      const payment = {
        userPacketsId: data.userPacketId,
        numberOfMonths: data.monthValue,
        amount: isZero ? 0 : data.totalPrice, //paguaj zero
        startExpiryDate: formatISO(data.expiryStart),
        endExpiryDate: formatISO(data.expiryEnd),
        isPaid: isPaid,
        offerId: data.offer?.id || null,
        newPacketId: data.newPacketId || null,
      };
      createPaymentList.push(payment);
    });

    try {
      if (createPaymentList.length > 0) {
        await createPayment(createPaymentList).unwrap();
        showNotification("Payment created!", 200);
        onClose();
      }
  
      if (unpaidServices) {
        for (const payment of unpaidServices) {
          await liquidatePayment(payment.id).unwrap();
          showNotification("Payment liquidated!", 200);
        }
        onClose();
      }
  
      await printPayment(paymentDetails);
    } catch (err) {
      showNotification(err.data.Message, err.data.StatusCode);
    } finally {
      setLoading(false); //reset loading to false after submission
    }
  };

  const printPayment = async (paymentDetails) => {
    if (paymentDetails.length === 0) {
      navigate("/payments");
      return;
    }

    if (isUserLoading) return;

    if (role !== "Admin" && !rights?.includes("PrintPayments")) {
      navigate("/payments");
      return;
    }

    const invoiceData = {
      name: userDetails.firstName +" "+userDetails.lastName,
      address: userDetails.address || "N/A",
      phone: userDetails.phoneNumber || "N/A",
      paymentDate: formatDate(new Date()),
      totalPayment: isZero ? 0 : total.toFixed(2),
      operatorName: `${currentOperator.FirstName} ${currentOperator.LastName}`,
      items: paymentDetails,
      note: "",
      //  note: `Prishjet dhe pengesat ne internet duhet te lajmerohen ne telefonin 067 xxxxxx ose 06xxxxx ose te paraqiten ne zyren tone. Prishjet dhe pengesat e palajmeruara nuk do te merren parasysh ne zbritje eventuale. Me respekt ${process.env.REACT_APP_BASE_OWNER.replace(".","")}`,
    };
    navigate("/print", { state: { invoiceData } });
  };

  const handlePrintSelection = (format) => {
    setSelectedFormat(format);
    printPayment(format);
  };

  const convertToDate = (str) => {
    const [day, month, year] = str.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const handleClose = () => {
    setOpenModal(false);
    navigate("/payments");
  };
  return (
    <Box>
      <Box textAlign="center" marginBottom={2}>
        <Typography
          variant="h6"
          sx={{ fontSize: "1.2rem", fontWeight: "bold" }}
        >
          PAYMENT
        </Typography>
      </Box>

      <Box
        display={"flex"}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box textAlign="left" marginBottom={2}>
          <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
            Operator:{" "}
            {currentOperator.FirstName + " " + currentOperator.LastName}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
            Client: {user.name}
          </Typography>
        </Box>
        <Box
          textAlign="right"
          marginBottom={2}
          display={hasUnpaidPayments ? "block" : "none"}
        >
          <Typography
            display="flex"
            alignItems="center"
            style={{ cursor: "pointer", color: "red" }}
            onClick={setShowUnpaidForm}
          >
            SHOW UNPAID <ArrowForwardIosIcon />
          </Typography>
        </Box>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              Packet Name
            </TableCell>
            <TableCell sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              Month
            </TableCell>
            <TableCell sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              Period
            </TableCell>
            <TableCell sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              Price
            </TableCell>
            <TableCell sx={{ fontSize: "1.1rem", fontWeight: "bold" }}>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => (
            <TableRow key={service.packetName}>
              <TableCell sx={{ borderBottom: "none", fontSize: "1.0rem" }}>
                {service.packetName}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", fontSize: "1.0rem" }}>
                {service.monthValue}
              </TableCell>
              <TableCell sx={{ borderBottom: "none", fontSize: "0.5rem" }}>
                <Typography display={"flex"}>
                  {formatDate(service.expiryStart)}
                </Typography>
                <Typography>
                  {/* {service.expiryEnd.split('T')[0]} */}
                  {formatDate(service.expiryEnd)}
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "none", fontSize: "1.0rem" }}>
                {service.packetPrice}L
              </TableCell>
              <TableCell sx={{ borderBottom: "none", fontSize: "1.0rem" }}>
                {service.totalPrice.toFixed(2)}L
              </TableCell>
            </TableRow>
          ))}

          {unpaidServices.map((srv) => (
            <TableRow key={srv.packetName}>
              <TableCell
                sx={{
                  borderBottom: "none",
                  fontSize: "1.0rem",
                  color: "red",
                  fontStyle: "italic",
                }}
              >
                {srv.packetName}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  fontSize: "1.0rem",
                  color: "red",
                  fontStyle: "italic",
                }}
              >
                {srv.month}
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  fontSize: "0.5rem",
                  color: "red",
                  fontStyle: "italic",
                }}
              >
                <Typography display={"flex"}>
                  {formatDate(convertToDate(srv.period.split(/\s+/)[0]))}
                </Typography>
                <Typography>
                  {/* {service.expiryEnd.split('T')[0]} */}
                  {formatDate(convertToDate(srv.period.split(/\s+/)[1]))}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  fontSize: "1.0rem",
                  color: "red",
                  fontStyle: "italic",
                }}
              >
                {srv.month === 0 ? srv.packetPrice : srv.total / srv.month}L
              </TableCell>
              <TableCell
                sx={{
                  borderBottom: "none",
                  fontSize: "1.0rem",
                  color: "red",
                  fontStyle: "italic",
                }}
              >
                {srv.total}L
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Grid container justifyContent="space-between" marginTop={2}>
        <Grid item xs={6}>
          <Typography variant="body1">Subtotal</Typography>
          {services &&
            services.map((service) => {
              if (service.offer)
                return <Typography variant="body1">Offer</Typography>;
            })}
          <Typography
            sx={{ fontSize: "1.05rem", fontWeight: "bold" }}
            variant="body1"
          >
            Total
          </Typography>
        </Grid>
        <Grid item xs={3} textAlign="right" paddingRight={5}>
          <Typography variant="body1">{subtotal.toFixed(2)} L</Typography>
          {services &&
            services.map((service) => {
              if (service.offer)
                return service?.offer.offerType === 0 ? (
                  <Typography variant="body1">
                    -
                    {(
                      service.packetPrice * service.monthValue -
                      service.offer?.newPrice
                    ).toFixed(2)}
                    L
                  </Typography>
                ) : service?.offer.offerType === 1 ? (
                  <Typography
                    variant="body1"
                    sx={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    +{service.offer?.extensionValue}{" "}
                    {service.offer.extensionType === 0
                      ? "MONTH "
                      : service.offer.extensionType === 1
                      ? "WEEK "
                      : service.offer.extensionType === 2
                      ? "DAYS "
                      : null}
                    {service.packetName.substr(0, 8)}
                  </Typography>
                ) : null;
            })}

          <Typography
            sx={{ fontSize: "1.05rem", fontWeight: "bold" }}
            variant="body1"
          >
            {isZero ? 0 : total.toFixed(2)}L
          </Typography>
        </Grid>
      </Grid>

      {/* {role === "Admin" && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked={isPaid} />}
              label="Paid?"
              onChange={togglePaid}
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "1.3rem" }, "& .MuiCheckbox-root": {
                color: "red", // Color when not checked
              },
              "& .MuiCheckbox-colorPrimary.Mui-checked": {
                color: "green", // Color when checked
              }, }} // Increase the font size
         
            />
          </FormGroup>
        </Box>
      )}  */}
      {(rights?.includes("UnpaidPayment") || role === "Admin") && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked={isPaid} />}
              label="Paid?"
              onChange={togglePaid}
              sx={{
                "& .MuiFormControlLabel-label": { fontSize: "1.3rem" },
                "& .MuiCheckbox-root": {
                  color: "red", // Color when not checked
                },
                "& .MuiCheckbox-colorPrimary.Mui-checked": {
                  color: "green", // Color when checked
                },
              }}
            />
          </FormGroup>
        </Box>
      )}

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {(rights?.includes("ZeroPayment") || role === "Admin") && (
          <FormGroup sx={{ pl: "30px" }}>
            <FormControlLabel
              control={<Checkbox defaultChecked={isZero} />}
              label="ZERO PAYMENT"
              onChange={toggleZero}
              sx={{
                "& .MuiFormControlLabel-label": { fontSize: "1.3rem" },
                "& .MuiCheckbox-root": {
                  color: "red",
                },
                "& .MuiCheckbox-colorPrimary.Mui-checked": {
                  color: "green",
                },
              }}
            />
          </FormGroup>
        )}
        {/* {role === "Admin" && (
          <FormGroup sx={{ pl: "30px" }}>
            <FormControlLabel
              control={<Checkbox defaultChecked={isZero} />}
              label="ZERO PAYMENT"
              onChange={toggleZero}
              sx={{
                "& .MuiFormControlLabel-label": { fontSize: "1.3rem" },
                "& .MuiCheckbox-root": {
                  color: "red",
                },
                "& .MuiCheckbox-colorPrimary.Mui-checked": {
                  color: "green",
                },
              }} // Increase the font size
            />
          </FormGroup>
        )} */}
      </Box>

      <CloseAddFooter
        footerLabel={"CREATE PAYMENT"}
        onClose={onClose}
        onClick={handleSubmit}
        disabled={loading}
      />

      {/* Print Selection Modal */}
      <PrintSelectionModal
        open={openModal}
        handleClose={handleClose}
        handlePrint={handlePrintSelection}
      />
    </Box>
  );
};
export default PaymentForm;
