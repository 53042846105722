import { useServerResponseNotifier } from 'features/context/ServerResponseNotifierContext ';
import { useGetUnpaidPaymentsCountMutation } from 'features/payments/paymentsApiSlice';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Stack,
    Typography,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
  } from "@mui/material";
import CloseAddFooter from '../CloseAddFooter';
import { useDeleteUserPacketMutation } from 'features/userPackets/userPacketsApiSlice';
import { now } from 'moment';

const DeleteUserPacketsForm = ({ onClose, userPacket, refetch }) => {
    const [getUnpaidPaymentsCount] = useGetUnpaidPaymentsCountMutation();
    const [deleteUserPacket] = useDeleteUserPacketMutation();

    const [unpaidCount, setUnpaidCount] = useState("");
    const { showNotification } = useServerResponseNotifier();
    const navigate = useNavigate();
  
    useEffect(() => {
      (async () => {
        const { data: unPaidNr } = await getUnpaidPaymentsCount(userPacket.clientId);
        setUnpaidCount(unPaidNr.count);
      })();
    }, [userPacket]);
  
  
    const handleSubmit = async () => {
      await deleteUserPacket({ id: userPacket.id })
        .unwrap()
        .then(() => {
          showNotification("Service deleted successfully", 200);
        })
        .catch((error) => {
          showNotification(error.data.Message, error.data.StatusCode);
        });

      await onClose();
      await refetch();
    };
  
    return (
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Stack alignItems="center">
            <Typography fontSize="20px">
              Are you sure you want to delete the service for this user?
            </Typography>
            <Typography fontSize="20px" fontWeight="bold" pt={"20px"}>
            {userPacket.service}  |  {userPacket.packetName} | {userPacket.client}
            </Typography>
  
            <Box display={unpaidCount > 0 ? "block" : "none"} pt={"15px"}>
              <Typography
                       onClick={(e) => {
                        navigate(`/payments/add-payment/${userPacket.clientId}`);
                      }}
                fontSize={"20px"}
                color={"red"}
                fontWeight={"bold"}
                sx={{ cursor: "pointer" }}
              >
                This user has {unpaidCount} unpaid invoices!
              </Typography>
              
            </Box>

            <Box display={ new Date(userPacket.expiry).getTime() > new Date().getTime()  ? "block" : "none"} pt={"15px"}>
              <Typography
                fontSize={"20px"}
                color={"red"}
                fontWeight={"bold"}
              >
                This user service is active | Expiry={new Date(userPacket.expiry).toISOString().split('T')[0]}! 
              </Typography>
              
            </Box>
          </Stack>
        </Box>
  
        <CloseAddFooter
          footerLabel={"DELETE"}
          onClose={onClose}
          onClick={handleSubmit}
        />
      </Box>
    );
  };
export default DeleteUserPacketsForm