import React from "react";
import {
  Box,
  Stack,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import CloseAddFooter from "../CloseAddFooter";
import { useDeleteOfferMutation } from "features/offers/offersApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";

const DeleteOfferForm = ({ onClose, offer, refetch }) => {
  const [deleteOffer] = useDeleteOfferMutation();
  const { showNotification } = useServerResponseNotifier();

  const handleSubmit = async () => {
    await deleteOffer(offer.id)
      .unwrap()
      .then(() => {
        showNotification("Offer deleted successfully", 200);
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetch();
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack alignItems="center">
          <Typography fontSize="20px">
            Are you sure you want to delete this offer?
          </Typography>
          <Typography fontSize="20px" fontWeight="bold" pt={"20px"}>
            {offer.name}
          </Typography>
        </Stack>
      </Box>

      <CloseAddFooter
        footerLabel={"DELETE"}
        onClose={onClose}
        onClick={handleSubmit}
      />
    </Box>
  );
};

export default DeleteOfferForm;
