import React, { useEffect, useState } from "react";
import Header from "components/Header";
import { Box, IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AddNewHeader from "components/forms/headers/AddNewHeader";

const TableComp = ({
  handleOpenForm,
  title,
  subtitle,
  data,
  isLoading,
  columns,
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
}) => {
  const theme = useTheme();

  const [rowCountState, setRowCountState] = useState(totalCount);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  return (
    <Box>
      <AddNewHeader
        openForm={handleOpenForm}
        title={title}
        subtitle={subtitle}
      />

      <Box
        mt="40px"
        height="75vh"
        overflow="auto"
        minWidth={"700px"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerConainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row.id}
          rows={data || []}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
        />
      </Box>
    </Box>
  );
};

export default TableComp;
