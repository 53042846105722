import { apiSlice } from "app/api/apiSlice";

export const itemsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getItem: build.query({
      query: (id) => `item/${id}`,
    }),
    getItemList: build.query({
      query: () => "item/list",
    }),
    createItem: build.mutation({
      query: (request) => ({
        url: "item",
        method: "POST",
        body: request,
      }),
    }),
    getAutoCompleteItems: build.mutation({
      query: (request) => ({
        url: "item/autocomplete",
        method: "POST",
        body: request,
      }),
    }),
    // getAutoCompleteItemsAdmin: build.mutation({
    //   query: (request) => ({
    //     url: "item/autocomplete",
    //     method: "POST",
    //     body: request,
    //   }),
    // }),
    updateItem: build.mutation({
      query: ({ id, request }) => ({
        url: `item/${id}`,
        method: "PUT",
        body: request,
      }),
    }),

    getItemsPagination: build.query({
      query: (args) => {
          let requestBody = {
              fromSearchAll: false,
              pageNumber: args.pageNumber,
              pageSize: args.pageSize,
              filters: []
          };

          if (args.filters && args.filters.length >0) {
              requestBody.filters = args.filters;
          }
  
          return {
              url: 'item/get-all/list',
              method: 'POST',
              body: requestBody
          };
      },
  }),

  deleteItem: build.mutation({
    query: (id) => ({
      url: `item/${id}`,
      method: "DELETE"
     }),
  }),

  }),
});

export const {
  useGetItemQuery,
  useGetItemListQuery,
  useCreateItemMutation,
  useGetAutoCompleteItemsMutation,
  useUpdateItemMutation,
  useGetItemsPaginationQuery,
  useDeleteItemMutation,
} = itemsApiSlice;
