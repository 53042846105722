import React, { useEffect, useState } from 'react';
import AdminDashboard from './AdminDashboard';
import { getClaimByKey } from 'utils/jwtUtils';
import OperatorDashboard from './OperatorDashboard';

const Dashboard = () => {
  const role = getClaimByKey("role");
  return (
    (role==="Admin"?<AdminDashboard/>:<OperatorDashboard/>)
  );
};

export default Dashboard;
