import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for "X" button
import { useGetItemListQuery } from "features/items/itemsApiSlice";
import InvoiceHeader from "components/forms/invoice/InvoiceHeader";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import {
  useCreateSaleMutation,
  useGetSaleQuery,
  useUpdateSaleMutation,
} from "features/invoices/invoicesApiSlice";
import OverlayForm from "components/OrverlayForm";
import SearchPurchaceInvoice from "components/forms/invoice/SearchPurchaceInvoice";
import DeleteInvoiceForm from "components/forms/deleteForms/DeleteInvoiceForm";
import ReactToPrint from "react-to-print";
import PurchaseInvoicePrint from "components/printer/PurchaseSaleInvoicePrint";
import { getUserFromClaims } from "utils/jwtUtils";
import InvoiceBtnHeaders from "components/InvoiceBtnHeaders";
import SaleInvoiceHeader from "components/forms/invoice/SaleInvoiceHeader";
import SearchSaleInvoice from "components/forms/invoice/SearchSaleInvoice";

const SaleInvoice = () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Initialize with an empty string
  
  const printRef = useRef();
  const printComponentRef = useRef();
  const currentOperator = getUserFromClaims();

  const {
    data: items,
    isLoading,
    refetch: refetchList,
  } = useGetItemListQuery();

  const [createSale] = useCreateSaleMutation();
  const [updateSale] = useUpdateSaleMutation();

  const { showNotification } = useServerResponseNotifier();

  const [showForm, setShowForm] = useState(false);
  const [deleteForm, setDeleteForm] = useState(false);
  const toggleFormDisplay = () => setShowForm(!showForm);
  const toggleDeleteForm = () => setDeleteForm(!deleteForm);

  const [selectedSale, setSelectedSale] = useState(null);

  const [formData, setFormData] = useState({
    customerId:1,
    customerName:"Kliente Te Ndryshem",
    invoiceCode: "",
    registrationDate: new Date(),
    description: "",
    items: [],
  });

  const handleSelectItem = (item) => {
    const existingItemIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    if (existingItemIndex !== -1) {
      const updatedItems = [...selectedItems];
      updatedItems[existingItemIndex].quantity += 1;
      updatedItems[existingItemIndex].finalPrice =
        (updatedItems[existingItemIndex].sellPrice -
          (updatedItems[existingItemIndex].sellPrice *
            updatedItems[existingItemIndex].discount) /
            100) *
        updatedItems[existingItemIndex].quantity;
      setSelectedItems(updatedItems);
    } else {
      setSelectedItems((prev) => [
        ...prev,
        { ...item, quantity: 1, discount: 0, finalPrice: item.sellPrice },
      ]);
    }
  };

  const handleInputChange = (index, field, value) => {
    const updatedItems = [...selectedItems];
    updatedItems[index][field] = value;

    updatedItems[index].finalPrice =
      (updatedItems[index].sellPrice -
        (updatedItems[index].sellPrice * updatedItems[index].discount) / 100) *
      updatedItems[index].quantity;

    setSelectedItems(updatedItems);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...selectedItems];
    updatedItems.splice(index, 1);
    setSelectedItems(updatedItems);
  };

  const filteredItems = items?.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const clearTable = async (e) => {
    setSelectedItems([]);
    setFormData({
      ...formData,
      customerId: 1,
      customerName:"Kliente Te Ndryshem",
      invoiceCode: "",
      registrationDate: new Date(),
      description: "",
      items: [],
    });
    setSearchTerm("");
    setSelectedSale(null);
    await refetchList();
  };

  const handleSubmit = async () => {
    if (selectedItems.length < 1) {
      showNotification("Add items for the invoice!", 404);
      return;
    }

    const data = {
      customerId: formData.customerId,
      description: formData.description,
      registrationDate: formData.registrationDate,
      items: selectedItems.map((item) => ({
        itemId: item.id,
        quantity: item.quantity,
        price: item.sellPrice,
        discount: item.discount,
      })),
    };

    try {
      await createSale(data).unwrap();
      showNotification("Sale Invoice created successfully!", 200);
      await clearTable();
    } catch (error) {
      showNotification(error.data.Message, error.data.StatusCode);
    }
  };

  const handleUpdate = async () => {
    const data = {
      customerId: formData.customerId,
      description: formData.description,
      registrationDate: formData.registrationDate,
      items: selectedItems.map((item) => ({
        itemId: item.id,
        quantity: item.quantity,
        price: item.sellPrice,
        discount: item.discount,
      })),
    };

    try {
      await updateSale({ id: selectedSale, request: data }).unwrap();
      showNotification("Sale Invoice updated successfully!", 200);
      await clearTable();
    } catch (error) {
      showNotification(error.data.Message, error.data.StatusCode);
    }
  };

  const handleProcessButton = async () => {
    if (selectedSale) await handleUpdate();
    else await handleSubmit();
  };

  const handleDelete = async () => {
    if (!selectedSale) {
      showNotification("Select an purchase invoice to delete!", 404);
      return;
    }
    toggleDeleteForm();
  };

  const calculateTotal = () => {
    return selectedItems.reduce((acc, item) => acc + item.finalPrice, 0);
  };

  const {
    data: invoiceData,
    refetch: refetchDetail,
    isLoadingInvoice,
  } = useGetSaleQuery(selectedSale, { skip: !selectedSale });

  useEffect(() => {
    if (!invoiceData && !isLoadingInvoice) return;

    if (!selectedSale) return;
    refetchDetail();

    const itemsWithNames = invoiceData.items.map((item) => {
      const matchedItem = items?.find((i) => i.id === item.itemId);
      return {
        id: item.itemId,
        name: matchedItem ? matchedItem.name : "Unknown",
        quantity: item.quantity,
        sellPrice: item.price,
        discount: item.discount,
        finalPrice:
          (item.price - (item.price * item.discount) / 100) * item.quantity,
      };
    });

    setFormData({
      customerId: invoiceData.customerId,
      customerName: invoiceData.customerName,
      invoiceCode: invoiceData.invoiceCode,
      registrationDate: new Date(invoiceData.registrationDate),
      description: invoiceData.description,
      items: itemsWithNames,
    });

    setSelectedItems(itemsWithNames);
  }, [invoiceData, isLoadingInvoice, selectedSale]);


  return (
    <Box>
      <InvoiceBtnHeaders
        printClick={() => printRef.current && printRef.current.click()}
        searchClick={toggleFormDisplay}
        clearClick={clearTable}
        deleteClick={handleDelete}
        processClick={handleProcessButton}
        invoiceType={"Sale Invoice"}
      />

      <Box display="flex" position="relative">
        <Box width="70%" p={2}>
          <SaleInvoiceHeader data={formData} setData={setFormData} />

          <TableContainer component={Paper} sx={{ maxHeight: "65vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "50px" }}>#</TableCell>
                  <TableCell sx={{ width: "170px" }}>Name</TableCell>
                  <TableCell sx={{ width: "180px" }}>Sell Price</TableCell>
                  <TableCell sx={{ width: "120px" }}>Discount (%)</TableCell>
                  <TableCell sx={{ width: "150px" }}>Quantity</TableCell>
                  <TableCell sx={{ width: "180px" }}>Final Price</TableCell>
                  <TableCell sx={{ width: "10px" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedItems.map((item, index) => (
                  <TableRow key={item.id} sx={{ backgroundColor: "#05ffa02e" }}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell sx={{ width: "170px" }}>{item.name}</TableCell>
                    <TableCell sx={{ width: "180px" }}>
                      <TextField
                        type="number"
                        value={item.sellPrice}
                        onChange={(e) =>
                          handleInputChange(index, "sellPrice", e.target.value)
                        }
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ width: "120px" }}>
                      <TextField
                        type="number"
                        value={item.discount}
                        onChange={(e) =>
                          handleInputChange(index, "discount", e.target.value)
                        }
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ width: "150px" }}>
                      <TextField
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          handleInputChange(index, "quantity", e.target.value)
                        }
                        variant="outlined"
                        size="small"
                      />
                    </TableCell>
                    <TableCell sx={{ width: "180px" }}>
                      {item.finalPrice.toFixed(2)}
                    </TableCell>
                    <TableCell sx={{ width: "10px" }}>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveItem(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box display="flex" justifyContent="flex-end">
            <Typography
              fontWeight="bold"
              sx={{ mt: "10px", flexGrow: 0, fontSize: "18px" }}
              boxShadow={2}
            >
              Total:{" "}
              {new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 2,
              })
                .format(calculateTotal() ?? 0)
                .toString()}
            </Typography>
          </Box>
        </Box>

        {/* Right aligned list */}
        <Box
          width="30%"
          p={2}
          position="absolute"
          top={0}
          right={0}
          borderLeft="1px solid #ccc"
        >
          <TextField
            fullWidth
            label="Search Items"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          {isLoading ? (
            <CircularProgress />
          ) : (
            <List sx={{ maxHeight: "65vh", overflowY: "auto" }}>
              {filteredItems &&
                filteredItems.map((item) => (
                  <ListItem
                    key={item.id}
                    onClick={() => handleSelectItem(item)}
                    divider
                  >
                    <ListItemText
                      primary={item.name}
                      secondary={
                        `Sell Price: ${item.sellPrice}` +
                        "   |  " +
                        `Quantity: ${item.totalQuantity}`
                      }
                    />
                  </ListItem>
                ))}
            </List>
          )}
        </Box>
      </Box>

      {showForm && (
        <OverlayForm
          onClose={toggleFormDisplay}
          formComponent={
            <SearchSaleInvoice
              setSelectedSale={setSelectedSale}
              onClose={toggleFormDisplay}
            />
          }
          maxWidth={"800px"}
        />
      )}

      {deleteForm && (
        <OverlayForm
          formComponent={
            <DeleteInvoiceForm
              invoice={invoiceData}
              invoiceType={"Sale"}
              onClose={toggleDeleteForm}
              clearTable={clearTable}
            />
          }
          formTitle={"DELETE SALE INVOICE"}
        />
      )}

      <ReactToPrint
        trigger={() => <button ref={printRef} style={{ display: "none" }} />}
        content={() => printComponentRef.current}
      />
      <div style={{ display: "none" }}>
        {invoiceData && selectedSale && (
          <PurchaseInvoicePrint
            ref={printComponentRef}
            invoiceData={{
              ...invoiceData,
              items: selectedItems,
              invoiceType:"Sale",
              operatorName: `${currentOperator.FirstName} ${currentOperator.LastName}`,
            }}
          />
        )}
      </div>
    </Box>
  );
};

export default SaleInvoice;
