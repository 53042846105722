import { apiSlice } from "app/api/apiSlice";

export const userPacketsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createUserPacket: build.mutation({
      query: (request) => ({
        url: "userpackets",
        method: "POST",
        body: request,
      }),
    }),
    createRadiusUser: build.mutation({
      query: (request) => ({
        url: "userpackets/radius",
        method: "POST",
        body: request,
      }),
    }),
    updateRadiusUser: build.mutation({
      query: (id,request) => ({
        url: `userpackets/radius/${id}`,
        method: "PUT",
        body: request,
      }),
    }),
    createStalkerUser: build.mutation({
      query: (request) => ({
        url: "userpackets/stalker",
        method: "POST",
        body: request,
      }),
    }),
    createExtreamUser: build.mutation({
      query: (request) => ({
        url: "userpackets/extream",
        method: "POST",
        body: request,
      }),
    }),
    // getUserPacketsList: build.query({
    //   query: () => ({
    //     url: "userpackets/list",
    //     method: "GET",
    //     //      body: request,
    //   }),
    // }),
    getUserPacketsDetails: build.query({
      query: (id) => ({
        url: `userpackets/${id}`,
      }),
    }),
    updateUPRadius: build.mutation({
      query: ({id,request})=>({
        url: `userpackets/radius/${id}`,
        method: "PUT",
        body: request,
      }),
    }),
    updateUPStalker: build.mutation({
      query: ({id,request})=>({
        url: `userpackets/stalker/${id}`,
        method: "PUT",
        body: request,
      }),
    }),
    updateUPExtream: build.mutation({
      query: ({id,request})=>({
        url: `userpackets/extream/${id}`,
        method: "PUT",
        body: request,
      }),
    }),
    updateUPDvbc: build.mutation({
      query: ({id,request})=>({
        url: `userpackets/dvbc/${id}`,
        method: "PUT",
        body: request,
      }),
    }),
    getUserServicesForUserId: build.mutation({
      query: (id)=>({
        url: `userpackets/services/${id}`,
        method:"GET"
      })
    }),
    getUserPacketsForAdmin: build.query({
      query: (args) => {
          let requestBody = {
              fromSearchAll: false,
              pageNumber: args.pageNumber,
              pageSize: args.pageSize,
              filters: []
          };

          if (args.filters && args.filters.length >0) {
              requestBody.filters = args.filters;
          }
  
          return {
              url: 'userpackets/get-all/list',
              method: 'POST',
              body: requestBody
          };
      },
  }),

  deleteUserPacket: build.mutation({
    query: ({id}) => ({
      url: `userpackets/delete/${id}`,
      method: "POST",
    }),
  }),
  createDvbcUser: build.mutation({
    query: (request) => ({
      url: "userpackets/dvbc",
      method: "POST",
      body: request,
    }),
  }),
  }),
});
export const {
  useCreateUserPacketMutation,
  useCreateRadiusUserMutation,
  useCreateExtreamUserMutation,
  useUpdateRadiusUserMutation,
  useCreateStalkerUserMutation,
  useCreateDvbcUserMutation,
  //useGetUserPacketsListQuery,
  useGetUserPacketsDetailsQuery,
  useUpdateUPRadiusMutation,
  useUpdateUPStalkerMutation,
  useUpdateUPExtreamMutation,
  useUpdateUPDvbcMutation,
  useGetUserServicesForUserIdMutation,
  useGetUserPacketsForAdminQuery,
  useDeleteUserPacketMutation,
} = userPacketsApiSlice;
