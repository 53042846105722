import { apiSlice } from "app/api/apiSlice";

export const regionsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRegionList: build.query({
      query: () => "region/list",
    }),
    createRegion: build.mutation({
      query: (request) => ({
        url: "region",
        method: "POST",
        body: request,
      }),
    }),
    updateRegion: build.mutation({
      query: ({ id, name }) => ({
        url: `region/${id}`,
        method: "PUT",
        body: {
          name,
        },
      }),
    }),
    getRegionsPagination: build.query({
      query: (args) => {
          let requestBody = {
              fromSearchAll: false,
              pageNumber: args.pageNumber,
              pageSize: args.pageSize,
              filters: []
          };

          if (args.filters && args.filters.length >0) {
              requestBody.filters = args.filters;
          }
  
          return {
              url: 'region/get-all/list',
              method: 'POST',
              body: requestBody
          };
      },
  }),

  deleteRegion: build.mutation({
    query: (request) => ({
      url: `region/delete`,
      method: "POST",
      body: request
    }),
  }),

  }),
});

export const { useGetRegionListQuery,
   useCreateRegionMutation,
   useUpdateRegionMutation,
   useGetRegionsPaginationQuery,
   useDeleteRegionMutation,
  } =
  regionsApiSlice;
