import { useServerResponseNotifier } from 'features/context/ServerResponseNotifierContext ';
import { useDeleteItemMutation } from 'features/items/itemsApiSlice';
import React from 'react'
import CloseAddFooter from '../CloseAddFooter';
import {
    Box,
    Stack,
    Typography,
  } from "@mui/material";

const DeleteItemForm = ({ onClose, item, refetch }) => {
    const [deleteItem] = useDeleteItemMutation();
    const { showNotification } = useServerResponseNotifier();
  
  const handleSubmit = async () => {
    await deleteItem(item.id)
      .unwrap()
      .then(() => {
        showNotification("Item deleted successfully", 200);
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetch();
  };
    return (
        <Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack alignItems="center">
              <Typography fontSize="20px">
                Are you sure you want to delete this item?
              </Typography>
              <Typography fontSize="20px" fontWeight="bold" pt={"20px"}>
                {item.row.name}
              </Typography>
            </Stack>
          </Box>
    
          <CloseAddFooter
            footerLabel={"DELETE"}
            onClose={onClose}
            onClick={handleSubmit}
          />
        </Box>
      );
    };
export default DeleteItemForm