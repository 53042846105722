import { useState } from 'react';
import { ServerResponseNotifierContext } from './ServerResponseNotifierContext ';



export function ServerResponseNotifierProvider({ children }) {
  const [notification, setNotification] = useState(null);

  const showNotification = (message, statusCode) => {
    setNotification({ message, statusCode });
  };

  const hideNotification = () => {
    setNotification(null);
  };

  return (
    <ServerResponseNotifierContext.Provider value={{ notification, showNotification, hideNotification }}>
      {children}
    </ServerResponseNotifierContext.Provider>
  );
}
