import { apiSlice } from "app/api/apiSlice";

export const withdrawalsApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOperators: build.query({
        query: () => "withdrawals/operators-list",
      }),
      getLastWithdrawals: build.query({
        query: () => "withdrawals/list",
      }),
      createWithdrawal: build.mutation({
        query: (request) => ({
          url: "withdrawals",
          method: "POST",
          body: request,
        }),
      }),
  }),
});

export const {
    useGetOperatorsQuery,
    useGetLastWithdrawalsQuery,
    useCreateWithdrawalMutation,
} = withdrawalsApiSlice;
