import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const InvoiceHeader = ({ data,setData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
          });
      };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <TextField
        label="Invoice Code"
        variant="outlined"
        size="small"
        name="invoiceCode"
        disabled={true}
        InputLabelProps={{ shrink: true }}
        value={data.invoiceCode}
        sx={{ width: "130px" }}
      />

      <TextField
        name="supplier"
        label="Supplier"
        variant="outlined"
        size="small"
        sx={{ width: "180px" }}
        onChange={handleChange}
        value={data.supplier}
      />

      <Box
        sx={{
          width: "180px",
          display: "flex",
          alignItems: "center",
          "& .MuiInputBase-root": { height: "37px" },
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Invoice Date"
            name="registrationDate"
            inputFormat="dd-MM-yyyy"
            value={data.registrationDate}
            onChange={(e)=>{
                handleChange({target:{name:"registrationDate",value:e}});
            }}
          />
        </LocalizationProvider>
      </Box>

      <TextField
        label="Description"
        variant="outlined"
        size="small"
        name="description"
        value={data.description}
        onChange={handleChange}
      />
    </Box>
  );
};

export default InvoiceHeader;
