import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CloseAddFooter from "../CloseAddFooter";
import { useCreateStalkerUserMutation } from "features/userPackets/userPacketsApiSlice";

const CreateStalkerForm = ({ onClose, formData, currentUser,refetchList,updateErrorSate }) => {
  const { showNotification } = useServerResponseNotifier();
  const [createStalkerUser]=useCreateStalkerUserMutation();
  const [errors, setErrors] = useState({});

  const [stalkerData, setStalkerData] = useState({
    username: "",
    description: "",
    macAddress:"",
  });

  const [dataForUpdate, setDataUpdate] = useState({
    username: "",
    macAddress: "",
    description: "",
    serviceId: formData?.serviceId || "",
    packetId: formData?.packetId || "",
    userId: formData?.userId || "",
  });

  useEffect(() => {
    setDataUpdate((prev) => ({
      ...prev,
      ...formData,
      username: generateData(),
    }));
  }, [formData, currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "macAddress") {
      let newValue = value.toUpperCase().replace(/[^A-F0-9]/g, ""); 
      newValue = newValue.slice(0, 12).match(/.{1,2}/g)?.join(":") || "";
      setStalkerData((prev) => ({ ...prev, [name]: newValue }));
      setDataUpdate((prev) => ({ ...prev, [name]: newValue }));
    } else {
      setStalkerData((prev) => ({ ...prev, [name]: value }));
      setDataUpdate((prev) => ({ ...prev, [name]: value }));
    }
  };



  const validateFields = () => {
    const newErrors = {};
    if (!dataForUpdate.username) newErrors.username = "Username is required.";
    if (!dataForUpdate.macAddress) {
      newErrors.macAddress = "MAC Address is required.";
    } else if (!/^([0-9A-F]{2}:){5}[0-9A-F]{2}$/.test(dataForUpdate.macAddress)) {
      newErrors.macAddress = "Invalid MAC Address format. Use XX:XX:XX:XX:XX:XX.";
    }
    if (!validateParentFields()) {
      newErrors.parent = "Parent fields validation failed.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateParentFields=()=>{
    const newErrors = {};
    if (!dataForUpdate.serviceId) newErrors.serviceId = "Service is required";
    if (!dataForUpdate.packetId) newErrors.packetId = "Packet is required";
    if (!dataForUpdate.userId) newErrors.userId = "User is required";
    
    if (Object.keys(newErrors).length > 0) {
      updateErrorSate(newErrors);
      return false;
    }
    return true;
  }


  const generateData = () => {
    if(!currentUser) return;
    let generatedUsername = currentUser.name.replaceAll(" ", "");

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
    const weekNumber = Math.ceil(days / 7);

    generatedUsername = generatedUsername.concat(weekNumber,currentDate.getHours());
    return generatedUsername;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    await createStalkerUser(dataForUpdate).unwrap()
    .then(showNotification("Service for user created succesfully!",200))
    .catch((error) => {
      showNotification(error.data.Message,error.data.StatusCode)
    });

    await onClose();
    await refetchList();
  };
  return (
    <Box paddingTop="20px">
      <TextField
        fullWidth
        defaultValue={generateData()}
        label="Username"
        margin="normal"
        name="username"
        onChange={handleChange}
        error={!!errors.username}
        helperText={errors.username} 
      />
      <TextField
        fullWidth
        label="MAC"
        margin="normal"
        name="macAddress"
        placeholder="CC:46:D6:12:34:56"
        value={stalkerData.macAddress}
        onChange={handleChange}
        error={!!errors.macAddress}
        helperText={errors.macAddress} 
      />
      <TextField
        fullWidth
        label="Description"
        margin="normal"
        name="Description"
        onChange={handleChange}
      />

      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default CreateStalkerForm;
