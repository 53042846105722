import React, { useRef } from "react";
import { useGetRegionListQuery } from "features/regions/regionsApiSlice";
import {
  TextField,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useState } from "react";
import CloseAddFooter from "./CloseAddFooter";
import { useCreateOperatorMutation } from "features/operators/operatorsApiSlice";
import { useGetAllRightsQuery } from "features/operatorRights/operatorRightsApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CustomMenuItem from "components/styled/CustomMenuItem";

const OperatorForm = ({ onClose, refetchList }) => {
  const { data: regionList } = useGetRegionListQuery();
  const [createOperator] = useCreateOperatorMutation();
  const { data: rightsList } = useGetAllRightsQuery();
  const { showNotification } = useServerResponseNotifier();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    regionIds: [],
    email: "",
    address: "",
    password: "",
    confirmPassword: "",
    rightsIds: [],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    await createOperator(formData)
      .unwrap()
      .then((payload) => {
        showNotification("Operator created succesfully", 200);
      })
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });
    await refetchList();
    onClose();
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    if (name === "rightsCheckBox") {
      const right = rightsList.find((r) => r.id == value);
      if (right) handleRightsChange(right.id, checked);
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

      let error = "";

      if (name === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        error = emailRegex.test(value) ? "" : "Invalid email format";
      }

      if (name === "password") {
        const passwordRegex = /^(?=.*[0-9])(?=.{8,})/;
        error = passwordRegex.test(value)
          ? ""
          : "Password must be at least 8 characters, contain at least one digit";
      }

      if (name === "confirmPassword") {
        error = formData.password === value ? "" : "Passwords do not match";
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error,
      }));
    }
  };

  const handleRightsChange = (rightId, isChecked) => {
    setFormData((prevFormData) => {
      const newRightsIds = isChecked
        ? [...prevFormData.rightsIds, rightId]
        : prevFormData.rightsIds.filter((id) => id !== rightId);

      return { ...prevFormData, rightsIds: newRightsIds };
    });
  };

  const validateFields = () => {
    const newErrors = {};

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email format";
    }

    const passwordRegex = /^(?=.*[0-9])(?=.{8,})/;
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (!passwordRegex.test(formData.password)) {
      newErrors.password =
        "Password must be at least 8 characters, contain at least one digit";
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (!formData.firstName) newErrors.firstName = "First name is required";
    if (!formData.lastName) newErrors.lastName = "Last name is required";
    if (!formData.regionIds || formData.regionIds.length < 1)
      newErrors.regionIds = "At least one selected zone";

    return newErrors;
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ width: "30%", margin: "0.5%" }}>
          <Typography sx={{ fontSize: 18, fontWeight: "bold", pb: "10px" }}>
            Opereator Information
          </Typography>
          <TextField
            fullWidth
            label="First Name"
            margin="normal"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            required
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            fullWidth
            label="Last Name"
            margin="normal"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            required
            error={!!errors.lastName}
            helperText={errors.lastName}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="region-label">Region</InputLabel>
            <Select
              labelId="region-label"
              id="region"
              multiple
              value={formData.regionIds}
              onChange={handleChange}
              name="regionIds"
              required
              error={!!errors.regionIds}
              helperText={errors.regionIds}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "400px",
                    overflow: "auto",
                  },
                },
              }}
            >
              {regionList &&
                regionList.map((region, index) => (
                  <CustomMenuItem key={index} value={region.id}>
                    {region.name}
                  </CustomMenuItem>
                ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Address"
            margin="normal"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            label="Mobile"
            margin="normal"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
          />
        </Box>

        <Box sx={{ width: "30%", margin: "0.5%" }}>
          <Typography sx={{ fontSize: 18, fontWeight: "bold", pb: "10px" }}>
            Opereator Credentitals
          </Typography>
          <TextField
            fullWidth
            label="Email"
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            error={!!errors.email}
            helperText={errors.email}
          />

          <TextField
            fullWidth
            label="Password"
            margin="normal"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            required
            error={!!errors.password}
            helperText={errors.password}
          />

          <TextField
            fullWidth
            label="Confirm Password"
            margin="normal"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </Box>

        {/* Rights */}
        <Box sx={{ width: "30%", margin: "0.5%" }}>
          <Typography sx={{ fontSize: 18, fontWeight: "bold", pb: "10px" }}>
            Opereator Rights
          </Typography>
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              {rightsList &&
                rightsList.map((right) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.rightsIds.includes(right.id)}
                        onChange={handleChange}
                        name="rightsCheckBox"
                        value={right.id}
                        sx={{
                          color: "lightgray", // Default color
                          "&.Mui-checked": {
                            color: "limegreen", // Custom color when checked
                          },
                        }}
                      />
                    }
                    label={right.description}
                    key={right.id}
                    //value={right.id}
                    sx={{ my: 1 }}
                  />
                ))}
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default OperatorForm;
