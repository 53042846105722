import React, { useRef, useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSuccess } from "state/authSlice";
import {
  Button,
  TextField,
  Container,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import { setCredentials } from "features/auth/authSlice";
import { useLoginMutation } from "features/auth/authApiSlice";
import jwt_decode from "jwt-decode";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";

const LoginPage = () => {
  const userRef = useRef();
  const errRef = useRef();
  const { showNotification } = useServerResponseNotifier();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const userData = await login({ username, password }).unwrap();
      const user=jwt_decode(userData.accessToken);

      dispatch(setCredentials({ ...userData, user }));
      setUsername("");
      setPassword("");
      if(user?.role==="Admin")
      {
        navigate("/dashboard");
      }
      else{
        navigate("/payments/add-payment");
      }
    } catch (err) {
      showNotification(err.data.Message, err.data.StatusCode);
      if (!err?.response) {
        setErrMsg("No server response");
      } else if (err.response?.status === 400) {
        setErrMsg("No missing username or password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("login failed!");
      }
      errRef.current.focus();
    }
  };

  const handleUsernameInput=(e)=>setUsername(e.target.value);
  const handlePasswordInput=(e)=>setPassword(e.target.value);
  
  return (
    <Box>
      <Container component="main" maxWidth="xs">

        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} />
        <div>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              ref={userRef}
              value={username}
              onChange={handleUsernameInput}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={handlePasswordInput}
            />
            <Button type="submit" fullWidth variant="contained" color="primary">
              Sign In
            </Button>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default LoginPage;
