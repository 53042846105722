import {
  useGetOfferByIdQuery,
  useUpdateOfferMutation,
} from "features/offers/offersApiSlice";
import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useGetPacketListQuery } from "features/packets/packetsApiSlice";
import CloseAddFooter from "../CloseAddFooter";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CustomMenuItem from "components/styled/CustomMenuItem";

const EditOfferForm = ({ offerId, onClose, refetchList }) => {
  const [packet, setPacket] = useState("");
  const [isDiscount, setDiscount] = useState(false);
  const [isExtend, setExtend] = useState(false);
  const [errors, setErrors] = useState({});
  const [months, setMonths] = useState(0);

  const [formData, setFormData] = useState({
    packetId: "",
    offerName: "",
    months: "",
    offerType: "",
    extensionValue: "",
    extensionType: -1,
    newPrice: 0,
    isDiscount: false,
    isExtend: false,
  });

  const { showNotification } = useServerResponseNotifier();

  const { data: packetListData, isLoading: isPacketLoading } =
    useGetPacketListQuery();

  const [updateOffer] = useUpdateOfferMutation();

  const { data, isLoading,refetch:refetchOffer } = useGetOfferByIdQuery(offerId);

  useEffect(() => {
    if (!isLoading) {
      setFormData({
        ...formData,
        offerName: data.name,
        packetId: data.packetId,
        months: data.numberOfMonths,
        offerType: data.offerType,
        newPrice: data.newPrice,
        extensionValue: data.extensionValue??"",
        extensionType: data.extensionType??-1,
        isDiscount: data.offerType === 0 ? true : false,
        isExtend: data.offerType === 1 ? true : false,
      });

      const selectedPacket = packetListData?.find((e) => e.id === data.packetId);
      setPacket(selectedPacket);
      setDiscount(data.offerType === 0 ? true : false);
      setExtend(data.offerType === 1 ? true : false);
    }
  }, [data]);

  const validateFields = () => {
    const newErrors = {};
    if (!formData.offerName) newErrors.offerName = "Name is required";
    if (!formData.packetId) newErrors.packetId = "Packet is required";
    if (!formData.months) newErrors.months = "Month range is required";
    if (!isDiscount && !isExtend) {
      newErrors.discountType = "Select a type of discount";
    }
    if (isDiscount && !formData.newPrice) {
      newErrors.newPrice = "New price is required";
    }

    if (isDiscount && packet.price * formData.months < formData.newPrice) {
      newErrors.newPrice = `Value should not be greater than ${
        packet.price * months
      }`;
    }

    if (isExtend && !formData.extensionValue)
      newErrors.extensionValue = "Extend value is required";

    if (isExtend && ![0, 1, 2].includes(Number(formData.extensionType)))
      newErrors.type = "Type extend is required";

    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "packetId") {
      const selectedPacket = packetListData?.find((e) => e.id === value);
      setPacket(selectedPacket);
    }

    if (name === "months") {
      setMonths(value);
    }

    if (name === "isDiscount") {
      setDiscount(true);
      setExtend(false);

      setFormData({
        ...formData,
        offerType:0
      })
    }

    if (name === "IsExtend") {
      setExtend(true);
      setDiscount(false);
      setFormData({
        ...formData,
        offerType:1
      })
    }

    if (name === "newPrice") {
      if (value > packet.price * months) {
        setErrors({
          ...errors,
          ["newPrice"]: `Value should not be greater than ${
            packet.price * months
          }`,
        });
      } else {
        setErrors({
          ...errors,
          ["newPrice"]: "",
        });
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (isDiscount && isValidSubmitForDiscount()) {
      await updateOffer({
        id: offerId,
        request: {
          name: formData.offerName,
          packetId: formData.packetId,
          numberOfMonths: formData.months,
          newPrice: formData.newPrice,
          offerType:0
        },
      })
        .unwrap()
        .then(() => {
          showNotification("Offer updated succesfully", 200);
        })
        .catch((error) => {
          console.error("Failed to update offer:", error);
          showNotification(error.data.Message, error.data.StatusCode);
        });
    }

    if (isExtend && isValidSubmitForExtend()) {
      await updateOffer({
        id: offerId,
        request: {
          name: formData.offerName,
          packetId: formData.packetId,
          numberOfMonths: formData.months,
          extensionValue: formData.extensionValue,
          extensionType: formData.extensionType,
          offerType:1,
        },
      })
        .unwrap()
        .then(() => {
          showNotification("Offer updated succesfully", 200);

        })
        .catch((error) => {
          console.error("Failed to update offer:", error);
          showNotification(error.data.Message, error.data.StatusCode);
        });
        
    }
    await refetchList();
    await onClose();
  };


  useEffect(() => {
    refetchOffer();
  }, [offerId]); 


  const isValidSubmitForDiscount = () => {
    return (
      formData.offerName &&
      formData.months &&
      formData.packetId &&
      formData.newPrice
    );
  };

  const isValidSubmitForExtend = () => {
    return (
      formData.offerName &&
      formData.months &&
      formData.packetId &&
      formData.extensionValue 
      && formData.extensionType.toString()
    );
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <TextField
          label="Offer name"
          name="offerName"
          value={formData.offerName}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          error={!!errors.offerName}
          helperText={errors.offerName}
        />

        <TextField
          label="Months number to be aplied"
          type="number"
          name="months"
          value={formData.months}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          error={!!errors.months}
          helperText={errors.months}
        />

        <FormControl fullWidth>
          <InputLabel id="packet-label">Packets</InputLabel>
          <Select
            labelId="packet-label"
            name="packetId"
            value={formData.packetId}
            onChange={handleChange}
            clientname={formData.clientName}
            error={!!errors.packetId}
            helperText={errors.packetId}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            {packetListData &&
              packetListData.map((packet, index) => (
                <CustomMenuItem key={index} value={packet.id}>
                  {packet.name}
                </CustomMenuItem>
              ))}
          </Select>
        </FormControl>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {packet && formData.months ? (
            <Typography
              sx={{
                display: "flex",
                justifyContent: "center",
                pt: "5px",
                pb: "5px",
                //  textDecoration: "line-through",
                fontSize: "28px",
                fontWeight: "bold",
              }}
              variant="outlined"
            >
              {packet.price * formData.months || ""} LEKE
            </Typography>
          ) : null}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                name="isDiscount"
                control={<Radio />}
                label="Discount"
                checked={isDiscount}
                onChange={handleChange}
                sx={{ pr: "40px" }}
                // disabled={} // Disable if form is not valid
              />
              <FormControlLabel
                name="IsExtend"
                control={<Radio />}
                label="Extend expiry"
                checked={isExtend}
                onChange={handleChange}
                sx={{ pl: "40px" }}
                //disabled={formData.isExtend} // Disable if form is not valid
                //!isFormValid()
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: isDiscount ? "flex" : "none",
              justifyContent: "left",
              width: "fit-content",
              pr: "50px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                borderTop: 1,
                pt: "10px",
              }}
            >
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                {packet?.name}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: "5px",
                  pb: "5px",
                }}
              >
                x
              </Typography>
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                {formData.months + " Months"}
              </Typography>
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  pt: "5px",
                  pb: "5px",
                }}
              >
                ||
              </Typography>

              {packet && formData.months ? (
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    pt: "5px",
                    pb: "5px",
                    textDecoration: "line-through",
                  }}
                  variant="outlined"
                >
                  {packet.price * formData.months || ""} LEKE
                </Typography>
              ) : null}

              <Box>
                <TextField
                  variant="outlined"
                  label="New Price"
                  type="number"
                  name="newPrice"
                  onChange={handleChange}
                  value={formData.newPrice}
                  error={!!errors.newPrice}
                  helperText={errors.newPrice}
                  InputLabelProps={{ shrink: true }}
                  sx={{
                    width: "200px",
                    mt: 2,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent",
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent",
                      },
                      "&.Mui-focused": {
                        borderBottom: "1px solid",
                      },
                      "& input": {
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "20px",

                        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                          {
                            "-webkit-appearance": "none",
                            margin: 0,
                          },

                        "&[type='number']": {
                          "-moz-appearance": "textfield",
                        },
                      },
                    },
                    "& .MuiInputLabel-root": {
                      textAlign: "center",
                      right: "20px",
                      left: 0,
                      transformOrigin: "center",
                      fontWeight: "bold",
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* RIGHT SIDE */}
          <Box
            sx={{
              display: isExtend ? "flex" : "none",
              justifyContent: "right",
              width: "fit-content",
              borderTop: 1,
              pt: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "16px",
                  pb: "20px",
                }}
              >
                SELECT TYPE OF EXIRY:
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* 2-digit number input */}
                <TextField
                  variant="outlined"
                  type="number"
                  name="extensionValue"
                  onChange={handleChange}
                  value={formData.extensionValue}
                  error={!!errors.extensionValue}
                  helperText={errors.extensionValue}
                  inputProps={{
                    maxLength: 2,
                    min: 1,
                    max: 99,
                    style: { textAlign: "left" }, // 2 character width
                  }}
                  sx={{
                    width: "5em", // approximately width for 2 digits
                  }}
                />

                {/* Separator */}
                <Box sx={{ mx: 1 }}>|</Box>

                {/* Type dropdown */}
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={formData.extensionType}
                    onChange={handleChange}
                    error={!!errors.type}
                    helperText={errors.type}
                    label="Type"
                    name="extensionType"
                  >
                    <CustomMenuItem value={0}>MONTHS</CustomMenuItem>
                    <CustomMenuItem value={1}>WEEKS</CustomMenuItem>
                    <CustomMenuItem value={2}>DAYS</CustomMenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <CloseAddFooter
        onClose={onClose}
        onClick={handleSubmit}
        footerLabel={"Edit"}
      />
    </Box>
  );
};

export default EditOfferForm;
