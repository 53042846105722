import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useGetAutoCompleteUsersMutation } from "features/users/usersApiSlice";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const AddPayment = () => {
  const [results, setResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");


  const [getAutoCompleteUsers] = useGetAutoCompleteUsersMutation();

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        const { data: newResults } = await getAutoCompleteUsers({
          searchString: searchTerm,
          topRecords: 10,
        });
        setResults(newResults || []);
      } else {
        setResults([]);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const handleChange = (e, newValue) => {
    const { name, value } = e.target;

    if (newValue) {  
      navigate(`/payments/add-payment/${newValue.id}`);
  }

  };

  return (
    <Box>
      <Box display={"flex"} justifyContent={"center"} paddingTop={3}>
        <Autocomplete
          id="user-search"
          name="users"
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          style={{ width: "50%" }}
          clearOnEscape={true}
          value={selectedUser}
          onChange={handleChange}
          noOptionsText="No user was found"
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Search for user" variant="outlined" />
          )}
        />
      </Box>
    </Box>
  );
};

export default AddPayment;
