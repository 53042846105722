import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const OperatorCard = ({ data, onCardClick ,isSelected }) => {
    const handleClick = () => {
      if(onCardClick) {
        onCardClick(data);
      }
    };

  return (
    <Box sx={{ pt: "10px", pl: "20px" }}>
      <Card sx={{ 
          maxWidth: 275, 
          backgroundColor: isSelected ? 'primary.main' : 'background.paper', 
          color: isSelected ? 'primary.contrastText' : 'text.primary',
          '&:hover': {
            backgroundColor: isSelected ? 'primary.dark' : 'background.default',
          }
        }} 
        onClick={handleClick}>
        <CardContent >
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {data.name}
          </Typography>
          <Typography variant="h5" component="div">
            Balance: {data.balance} ALL
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OperatorCard;