import React from "react";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography } from "@mui/material";
import { getClaimByKey } from "utils/jwtUtils";

const PaymentsActions = ({ role, rights, deleteClick, printClick }) => {
  return (
    <>
      {(rights?.includes("PrintPayments") || role === "Admin") && (
        <IconButton
          onClick={printClick}
          sx={{ color: "#00fff5" }}
          aria-label="print"
        >
          <LocalPrintshopIcon />
        </IconButton>
      )}

      {role === "Admin" && (
        <IconButton
          onClick={deleteClick}
          sx={{ color: "#ff0000" }}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      )}
    </>
  );
};
export default PaymentsActions;
