import { apiSlice } from "app/api/apiSlice";

export const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getServiceList: build.query({
      query: () => "service/list",
    }),
    getMainServicesList: build.mutation({
      query: (serviceId)=> `service/${serviceId}/main-list`,
      method:"GET"
    })
  }),
});

export const { useGetServiceListQuery,useGetMainServicesListMutation } = servicesApiSlice;
