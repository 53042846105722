import React, { useEffect, useState } from "react";
import { Box, IconButton, TextField } from "@mui/material";
import OverlayForm from "components/OrverlayForm";
import ItemForm from "components/forms/ItemForm";
import { useGetItemsPaginationQuery } from "features/items/itemsApiSlice";
import PaginationTable from "components/PaginationTable";
import AddNewHeader from "components/forms/headers/AddNewHeader";
import EditItemForm from "components/forms/editForms/EditItemForm";
import ItemActions from "components/forms/actions/ItemActions";
import DeleteItemForm from "components/forms/deleteForms/DeleteItemForm";

const Items = () => {
  const [showForm, setShowForm] = useState(false);
  const [deleteForm, setDeleteForm] = useState(false);
  const toggleDeleteForm = () => setDeleteForm(!deleteForm);

  const [item, setItem] = useState("");
  const [editForm, setEditForm] = useState(false);
  const openEditForm = () => setEditForm(true);
  const closeEditForm = () => setEditForm(false);

  const handleOpenForm = () => setShowForm(true);
  const handleCloseForm = () => setShowForm(false);

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [filters, setFilters] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const {
    data,
    isLoading,
    refetch: refetchData,
  } = useGetItemsPaginationQuery({
    pageNumber: pageNumber + 1,
    pageSize,
    filters: filters,
  });

  useEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  useEffect(() => {
    refetchData({ pageNumber, pageSize, filters });
  }, [pageNumber, pageSize, filters]);

  const handlePaginationModelChange = (model) => {
    setPageNumber(model.page);
    setPageSize(model.pageSize);
  };

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        let newFilters = [];
        newFilters.push({
          columnName: "Name",
          value: `${searchTerm}`,
          operation: 0,
        });
        setFilters(newFilters);
      } else {
        setFilters([]);
      }
    }, 800);
    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.5,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "sellPrice",
      headerName: "Sell Price",
      flex: 0.5,
    },    {
      field: "buyPrice",
      headerName: "Buy Price",
      flex: 0.5,
    },
    // {
    //   field: "quantity",
    //   headerName: "Quantity",
    //   flex: 0.5,
    // },
    {
      field: "dateCreated",
      headerName: "Date Created",
      flex: 0.5,
    },

    {
      field: "description",
      headerName: "Description",
      flex: 0.5,
    },  
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      align: "right",
      renderCell: (params) => {
        const editClick = () => {
          openEditForm();
          setItem(params);
        };

        const deleteClick = () => {
          toggleDeleteForm();
          setItem(params);
        };

        return (
          <ItemActions editClick={editClick} deleteClick={deleteClick} />
          )

      },
    },
  ];

  return (
    <Box m="0.5rem 2.5rem">
      <AddNewHeader openForm={handleOpenForm} title={"Items"} />
      <TextField
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        label="Search Item"
        variant="outlined"
        size="small"
        name="name"
      />

      <PaginationTable
        handleOpenForm={handleOpenForm}
        data={tableData.data}
        isLoading={isLoading}
        columns={columns}
        onPageChange={handlePaginationModelChange}
        totalCount={tableData.totalCount}
        currentPage={pageNumber}
        pageSize={pageSize}
        fixedMinWith={"700px"}
        fixedHeight={"70vh"}
      />

      {showForm && (
        <OverlayForm
          onClose={handleCloseForm}
          formComponent={
            <ItemForm onClose={handleCloseForm} refetch={refetchData} />
          }
        />
      )}

      {editForm && (
        <OverlayForm
          formComponent={
            <EditItemForm
              itemId={item.id}
              onClose={closeEditForm}
              refetch={refetchData}
            />
          }
          formTitle={"Edit item"}
        />
      )}

      {deleteForm && (
        <OverlayForm
          formComponent={
            <DeleteItemForm
              item={item}
              onClose={toggleDeleteForm}
              refetch={refetchData}
            />
          }
          formTitle={"DELETE ITEM"}
        />
      )}
    </Box>
  );
};

export default Items;
