import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DatePicker } from "antd";
import {
  useGetAutocompleteOperatorsListQuery,
  useGetAutocompletePacketsListForOperatorQuery,useGetAutocompleteRegionsListQuery,
  useGetAutocompleteServiceListQuery,
} from "features/autocomplete/autocompleteApiSlice";
import Header from "components/Header";
import { useGetAutoCompleteUsersMutation } from "features/users/usersApiSlice";
import AddNewHeader from "components/forms/headers/AddNewHeader";
const { RangePicker } = DatePicker;

const AddServiceHeader = ({
  refetch,
  pageNumber,
  pageSize,
  setFilters,
  handleOpenForm,
  searchTearm
}) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();

  const [dates, setDates] = useState([]);

  const { data: regionListData, isLoading: isRegionLoading } =
    useGetAutocompleteRegionsListQuery();

    const { data: packetListData, isLoading: isPacketLoading } =
    useGetAutocompletePacketsListForOperatorQuery();

  const { data: serviceListData, isLoading: isServiceLoading } =
    useGetAutocompleteServiceListQuery();

  const { data: operatorList, isLoading: isOperatorLoading } =
    useGetAutocompleteOperatorsListQuery();

    const [getAutoCompleteUsers, { isLoading: isAutoCompleteLoading }] =
    useGetAutoCompleteUsersMutation();
    const [selectedUser, setSelectedUser] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

  const [regionName, setRegionName] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [packetName, setPacketName] = useState("");
  const [operator, setOperator] = useState("");
  const [status, setStatus] = useState("");

  const [formData, setFormData] = useState({
    clientId: "",
    regionid: "",
    serviceId: "",
    packetId: "",
    operatorId: "",
    status: "",
  });

  const [results, setResults] = useState([]);

  
  useEffect(()=>{
    if(searchTearm)
    {
        setSearchTerm(searchTearm);
    }
  },[searchTearm])

  useEffect(() => {
    const timerId = setTimeout(async () => {
      if (searchTerm) {
        const { data: newResults } = await getAutoCompleteUsers({
          searchString: searchTerm,
          topRecords: 5,
        });
        setResults(newResults || []);
      } else {
        setResults([]);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  function isNullOrWhitespace(input) {
    if (typeof input === "undefined" || input == null) return true;
    return input.replace(/\s/g, "").length < 1;
  }

  const handleChange = async (e) => {
    const { name, value } = e.target;

    if (name === "regionId") {
      setRegionName(value);
    }
    if (name === "serviceId") {
      setServiceName(value);
    }

    if (name === "packetId") {
      setPacketName(value);
    }

    if (name === "operatorId") {
      setOperator(value);
    }

    if (name === "status") {
        setStatus(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClick = async () => {
    const newFilters = [];

    if (selectedUser && !isNullOrWhitespace(selectedUser?.name) ) {
      newFilters.push({
        columnName: "Client",
        value: `${selectedUser.name}`,
        operation: 0,
      });
    }

    if (!isNullOrWhitespace(status)) {
      newFilters.push({
        columnName: "Status",
        value: `${status}`,
        operation: 0,
      });
    }

    if (!isNullOrWhitespace(regionName)) {
      newFilters.push({
        columnName: "RegionName",
        value: `${regionName}`,
        operation: 0,
      });
    }

    if (!isNullOrWhitespace(packetName)) {
      newFilters.push({
        columnName: "PacketName",
        value: `${packetName}`,
        operation: 0,
      });
    }

    if (!isNullOrWhitespace(serviceName)) {
      newFilters.push({
        columnName: "Service",
        value: `${serviceName}`,
        operation: 0,
      });
    }

    if (!isNullOrWhitespace(operator)) {
      newFilters.push({
        columnName: "Operator",
        value: `${operator}`,
        operation: 0,
      });
    }

    if(newFilters.length==0 && searchTerm)
    {
      newFilters.push({
        columnName: "TypeIdentifier",
        value: `${searchTerm}`,
        operation: 0,
      });
    }

    if(newFilters.length==0 && searchTerm)
    {
      newFilters.push({
        columnName: "TypeIdentifier",
        value: `${searchTerm}`,
        operation: 0,
      });
    }

    await setFilters(newFilters);
    refetch({ pageNumber, pageSize, filters: newFilters });
  };



  return (
    <Box>
      <AddNewHeader
        openForm={handleOpenForm}
        title={"Add Service"}
      />
      <Box 
        flexDirection={isNonMobile ? "row" : "column"}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: isNonMobile ? "20px" : "10px",
          padding: "20px",
          height: isNonMobile ? "32px" : "auto",
          overflowY: isNonMobile ? "visible" : "auto",
          pt:"25px",
        }}
      >
         <Autocomplete
          id="users"
          fullWidth
          options={results}
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          sx={{ height: "32px", padding: "0px", margin: "0"
          ,
          ".MuiAutocomplete-input": {
            padding: "0px !important" , // Adjusts the padding of the input field
          }, 
        
        }}
          freeSolo
          clearOnEscape={true}
          value={selectedUser}
          onChange={(event, newValue) => {
            setSelectedUser(newValue);
          }}
          noOptionsText="No users found"
          inputValue={searchTerm}
          onInputChange={(event, newInputValue) => {
            setSearchTerm(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
            {...params}
            label="Search for user"
            variant="outlined"
            sx={{
              height: "100%",
              padding: "0px",
              margin: "0",
              ".MuiOutlinedInput-root": {
                height: "100%",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          )}
        />

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel id="region-label" shrink>
            Region
          </InputLabel>
          <Select
            labelId="region-label"
            id="region"
            sx={{ height: "100%" }}
            value={formData.regionId}
            onChange={handleChange}
            name="regionId"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            <MenuItem value={null}>None</MenuItem>
            {regionListData &&
              regionListData.map((region, index) => (
                <MenuItem key={index} value={region.value}>
                  {region.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel shrink>Service</InputLabel>
          <Select
            name="serviceId"
            sx={{ height: "100%" }}
            value={formData.serviceId}
            onChange={handleChange}
            required
          >
            <MenuItem value={null}>None</MenuItem>
            {serviceListData &&
              serviceListData.map((service, index) => (
                <MenuItem key={index} value={service.value}>
                  {service.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel shrink>Packets</InputLabel>
          <Select
            required
            name="packetId"
            sx={{ height: "100%" }}
            onChange={handleChange}
            value={formData.packetId}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px",
                  overflow: "auto",
                },
              },
            }}
          >
            <MenuItem value={null}>None</MenuItem>
            {packetListData &&
              packetListData.map((packet, index) => (
                <MenuItem key={index} value={packet.value}>
                  {packet.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>


        <FormControl fullWidth sx={{ height: "32px", margin: "0" }}>
          <InputLabel shrink>Status</InputLabel>
          <Select
            name="status"
            sx={{ height: "100%" }}
            value={formData.status}
            onChange={handleChange}
            required
          >
            <MenuItem value={null}>None</MenuItem>
            <MenuItem value={"Not Active"}>Not Active</MenuItem>
            <MenuItem value={"Active"}>Active</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          color="primary"
          sx={{ mb: "5px", flexGrow: 1 }}
          onClick={handleClick}
        >
          Search
        </Button>
      </Box>
    </Box>
  );
};

export default AddServiceHeader;
