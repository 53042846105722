import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import axios from "axios";
import { useCreateUserMutation } from "features/users/usersApiSlice";
import { useSelector } from "react-redux";
import { getClaimByKey } from "utils/jwtUtils";
import { useGetRegionListQuery } from "features/regions/regionsApiSlice";
import { useGetAutocompleteRegionsListQuery } from "features/autocomplete/autocompleteApiSlice";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import CustomMenuItem from "components/styled/CustomMenuItem";
import CloseAddFooter from "./CloseAddFooter";


const UsersForm = ({ onClose, refetch }) => {
  const { showNotification } = useServerResponseNotifier();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    regionId: 0,
    operatorId: 0,
    email: "",
    address: "",
    city: "",
    phoneNumber: "",
  });
  const [createUser] = useCreateUserMutation();
    
  const {
    data: regions,
    refetch: refetchRegions,
  } = useGetAutocompleteRegionsListQuery();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (formData.regionId==0) newErrors.regionId = "Region is required";  
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    formData.operatorId = getClaimByKey("Id");

    await createUser(formData)
      .unwrap()
      .then(showNotification("User created succesfully!", 200))
      .catch((error) => {
        showNotification(error.data.Message, error.data.StatusCode);
      });

    await onClose();
    await refetch();
  };

  useEffect(() => {
    refetchRegions();
  }, []);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="First Name"
        margin="normal"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        error={!!errors.firstName}
        helperText={errors.firstName} 
      />
      <TextField
        fullWidth
        label="Last Name"
        margin="normal"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        error={!!errors.lastName}
        helperText={errors.lastName} 
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="region-label">Region</InputLabel>
        <Select
          labelId="region-label"
          name="regionId"
          value={formData.regionId}
          onChange={handleChange}
          error={!!errors.regionId}
          helperText={errors.regionId} 
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "300px",
                overflow: "auto",
              },
            },
          }}
        >
          {regions &&
            regions.map((region, index) => (
              <CustomMenuItem key={index} value={region.id}>
                {region.value}
              </CustomMenuItem>
            ))}
        </Select>
      </FormControl>

      <TextField
        fullWidth
        label="Email"
        margin="normal"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Address"
        margin="normal"
        name="address"
        value={formData.address}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="City"
        margin="normal"
        name="city"
        value={formData.city}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Phone"
        margin="normal"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
      />

      <CloseAddFooter onClose={onClose} onClick={handleSubmit} />
    </Box>
  );
};

export default UsersForm;
