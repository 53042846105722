import { Box } from "@mui/material";
import { useGetRegionListQuery } from "features/regions/regionsApiSlice";
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "features/users/usersApiSlice";
import React, { useEffect, useState } from "react";
import CloseAddFooter from "../CloseAddFooter";
import { useServerResponseNotifier } from "features/context/ServerResponseNotifierContext ";
import { TextField,FormControl,InputLabel,Select,MenuItem } from "@mui/material";
import CustomMenuItem from "components/styled/CustomMenuItem";

const EditUserForm = ({ onClose, refetch, userId }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    regionId: 0,
    email: "",
    address: "",
    city: "",
    phoneNumber: "",
  });

  const {
    data: userDetails,
    isLoading: isUserLoading,
    refetch: refetchCurrentUser,
  } = useGetUserQuery(userId);

  const {
    data: regionListData,
    refetch: refetchRegions,
  } = useGetRegionListQuery();

  const [errors, setErrors] = useState({});
  const [currentUser, setCurrentUser] = useState("");
  const [updateUser] = useUpdateUserMutation();
  const {showNotification}=useServerResponseNotifier();

  const validateFields = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (formData.regionId==0) newErrors.regionId = "Region is required";  
    return newErrors;
  };

  useEffect(() => {
    if (!isUserLoading) {
      setCurrentUser(userDetails);
      setFormData({
        ...formData,
        firstName: userDetails.firstName || "",
        lastName: userDetails.lastName || "",
        regionId: userDetails.regionId || [],
        email: userDetails.email || "",
        address: userDetails.address || "",
        city: userDetails.city || "",
        phoneNumber: userDetails.phoneNumber || "",
      });
    }
  }, [userDetails, isUserLoading]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    await updateUser({ id: currentUser.id, request: formData })
      .unwrap()
      .then(() => {
        showNotification("User updated succesfully!", 200);
      })
      .catch((err) => {
        showNotification(err.data.Message, err.data.StatusCode);
      });

    await onClose();
    await refetch();
  };

  useEffect(() => {
    refetchCurrentUser();
  }, [userId]);

  return (
    <Box>
      <TextField
        fullWidth
        label="First Name"
        margin="normal"
        name="firstName"
        value={formData.firstName}
        onChange={handleChange}
        error={!!errors.firstName}
        helperText={errors.firstName} 
      />
      <TextField
        fullWidth
        label="Last Name"
        margin="normal"
        name="lastName"
        value={formData.lastName}
        onChange={handleChange}
        error={!!errors.lastName}
        helperText={errors.lastName} 
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="region-label">Region</InputLabel>
        <Select
          labelId="region-label"
          name="regionId"
          value={formData.regionId}
          onChange={handleChange}
          error={!!errors.regionId}
          helperText={errors.regionId} 
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '350px',  
                overflow: 'auto',    
              },
            },
          }}
        >
          {regionListData &&
            regionListData.map((region, index) => (
              <CustomMenuItem key={index} value={region.id}>
                {region.name}
              </CustomMenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Email"
        margin="normal"
        name="email"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="Address"
        margin="normal"
        name="address"
        value={formData.address}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="City"
        margin="normal"
        name="city"
        value={formData.city}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        label="phoneNumber"
        margin="normal"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
      />

      <CloseAddFooter 
      footerLabel={"Edit"} 
      onClose={onClose}
       onClick={handleSubmit} />
    </Box>
  );
};

export default EditUserForm;
