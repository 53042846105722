import React from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import UnpaidPayments from "scenes/addPayment/UnpaidPayments";

const DualOverlay = ({ onClose, formComponent,secondComponent, onSubmit, formTitle }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap:"1rem",
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.alt,
          padding: "2em",
          borderRadius: "8px",
          width: "80%",
          maxWidth: "550px",
          minHeight:"600px"
        }}
      >
        {formTitle && (
          <Typography
            variant="h4"
            gutterBottom
            sx={{ display: "flex", justifyContent: "center" }}
          >
            {formTitle}
          </Typography>
        )}
        {formComponent}
      </Box>

      <Box
        sx={{
          display: (secondComponent==null?"none":"block"),
          backgroundColor: theme.palette.background.alt,
          padding: "1em",
          paddingLeft:"5px",
          borderRadius: "8px",
          width: "100%",
          maxWidth: "510px",
          maxHeight:"500px"
        }}
      >
        {secondComponent}
      </Box>
    </Box>
  );
};

export default DualOverlay;
